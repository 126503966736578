<template>
	<Application class="ContractorsView" :loading="loading" v-model:error-title="errorTitle" :error-detail="errorDetail">
		<template #navbar>
			<div class="navbar">
				<div class="navbar__filter">
					<ContractorFilterCombo 
						v-model="filterComboModel" 
						:disabled-filters="disabledFilters"
						:key="'contractor-filter'"
					/>
				</div>
				<div class="navbar__magnifier">
					<v-icon :color="'#8F8F8F'">mdi-magnify</v-icon>
				</div>
				<!-- <button class="navbar__search-btn">{{ $t('text.search') }}</button> -->
				<ButtonList
					:buttonText="$t('text.contractsBulkActionText')"
					:disabled="!selectedContractors?.length"
					:options="[
						{ key: 'assignContract', icon: 'mdi-gavel', disabled: assignContractDisabled, tooltipText: assignContractDisabled ? $t('text.contractorsDoNotNeedContractsAreSelected') : '' },
						{ key: 'noContractNeeded', icon: 'mdi-folder-off', disabled: noContractNeededDisabled, tooltipText: noContractNeededDisabled ? $t('text.terminateActiveContractsBeforehand') : '' },
						{ key: 'terminateContract', icon: 'mdi-close-circle', disabled: terminateContractsDisabled, tooltipText: terminationBulkTooltipText },
						{ key: 'sendReminder', icon: 'mdi-bell', disabled: sendReminderBulkDisabled, tooltipText: sendReminderBulkDisabled ? $t('text.someContractsHaveAlreadyBeenTerminated') : '' },
						{ key: 'contractorExcelExport', icon: 'mdi-download', disabled: !selectedContractors?.length },
					]"
					:itemsI18n="true"
					prependIcon="mdi-animation"
					@option-click="onBulkClick"
				/>
			</div>
		</template>
		<ContractorsList class="contractors" :contractors="contractorsModelFiltered" :sequenceFilterSet="contractTemplateFilterSet" v-model:offset="offset" v-model:selected="selectedContractors" />
		<Dialog ref="assignContractDialog"
			data-cy="assignContractDialog"
			:confirmLabel="$t('text.confirm')"
			:cancelLabel="$t('text.cancel')"
			:confirm-handler="checkAssignContractStep"
			:close-handler="onCloseDialog"
			:title="$t('text.contractsAssignDialogTitle')"
			:isValid="!!selectedContractTemplate?.de"
			height="fit-content"
			width="540px"
			class="ContractorsView assign-dialog"
		>
			<template #content>
				<FieldSet v-if="assignContractDialogStep === 'chooseContract'">
					<v-label>{{ $t('text.contractsAssignDialogHelpTitle') }}</v-label>
					<p>{{ $t('text.contractsAssignDialogHelpSubTitle') }}</p>
					<Field :fieldOverride="{ id: 'contractTemplate', name: 'contract', type: 'Symbol', control: { widgetId: 'dropdown' }, localized: false, required: true }" :options="contractTemplatesForField" v-model="selectedContractTemplate" :title="$t('text.contractsAssignDialogContractFieldTitle')"></Field>
				</FieldSet>
				<div v-else class="assign-dialog__confirm">
					<div class="assign-dialog__confirm__icon"><v-icon :color="'#00AEEF'" :size="'96px'">mdi-gavel</v-icon></div>
					<div>
						<v-label>{{ $t('text.contractsAssignDialogTitle') }}</v-label>
						<p>{{ $t('text.contractsAssignDialogConfirmText', { contractTemplate: contractTemplatesForField.find(ct => ct.id === selectedContractTemplate.de)?.label, contractorCount: selectedContractors.length }) }}</p>
					</div>
				</div>
			</template>
		</Dialog>
		<SendReminderDialog ref="sendReminderDialog" :modelValue="ignoreRecentlySent" :contractors="selectedContractors" @update:modelValue="ignoreRecentlySent = $event" @sendReminder="sendReminder()" />
		<NoContractNeededDialog ref="noContractNeededDialog" :contractors="selectedContractors" @confirm="bulkSetNoContractNeeded()" />
		<TerminateContractsDialog ref="terminateContractsDialog" :contract-template="filterContractTemplate" :contractors="selectedContractors" :contracts="contractsForTerminate" @terminateContracts="terminateContracts($event)" />
	</Application>
</template>

<script lang="ts">
import Application from '../Application.vue'
import ContractorsList from '../../../components/contract/ContractorsList.vue'
import ContractExecutive from '../../../../../api2/src/modules/contract/ContractExecutive'
import ContractorFilterCombo from '../../../components/searchFilter/ContractorFilterCombo.vue'
import Toast from '../../../mixins/Toast.vue'
import { isEqual } from 'lodash'
import Dialog from '../../../components/common/Dialog.vue'
import Field from '../../../components/fields/Field.vue'
import FieldSet from '../packageDesigner/FieldSet.vue'
import type { ContractorFilterItem } from '../../../../../api2/src/modules/contract/ContractTechnical'
import ButtonList from '@/components/common/ButtonList.vue'
import SendReminderDialog from '../../../components/contract/SendReminderDialog.vue'
import NoContractNeededDialog from '../../../components/contract/NoContractNeededDialog.vue'
import TerminateContractsDialog from '../../../components/contract/TerminateContractsDialog.vue'
import moment from 'moment'
import Loading from '../../../mixins/Loading.vue'
import { cloneDeep } from 'lodash'

export default {
	components: { ContractorsList, Application, ContractorFilterCombo, Dialog, FieldSet, Field, ButtonList, SendReminderDialog, NoContractNeededDialog, TerminateContractsDialog },
	mixins: [ Toast, Loading ],
	data: () => ({
		contractors: [] as any[],
		contractorsModel: [] as any[],
		contractorsModelFiltered: [] as any[],
		loading: false,
		errorTitle: '',
		errorDetail: '',
		filterComboModel: { filters: [] as any[], search: '' },
		searchTimeout: null as any,
		contractTemplates: [] as any,
		selectedContractTemplate: { de: null },
		selectedContractors: [] as any,
		assignContractDialogStep: 'chooseContract',
		ignoreRecentlySent: false,
		offset: 0,
		lastRequestFilters: [] as ContractorFilterItem[],
		savedFiltersOnRouteLeave: null,
	}),
	computed: {
		terminationBulkTooltipText(): string {
			return !this.contractTemplateFilterSet ? this.$t('text.contractTemplateNeedsToBeSetInFilter') : this.terminateContractsDisabled ? this.$t('text.someContractsHaveAlreadyBeenTerminated') : ''
		},
		assignContractDisabled(): boolean {
			const contractors = this.contractors.filter(x => this.selectedContractors.includes(x.id))
			return contractors.some(x => !x.needsContract)
		},
		noContractNeededDisabled(): boolean {
			const contractors = this.contractors.filter(x => this.selectedContractors.includes(x.id))
			return contractors.some(x => x.contracts && x.contracts.some(c => !c.endDate || moment(c.endDate).isSameOrAfter(moment())))
		},
		terminateContractsDisabled(): boolean {
			const contractors = this.contractors.filter(x => this.selectedContractors.includes(x.id))
			const hasTerminatedContracts = contractors.some(x => x.contracts?.some(c => c.endDate && moment(c.endDate).isBefore(moment())))
			return !this.contractTemplateFilterSet || !this.contractsForTerminate.length || hasTerminatedContracts
		},
		sendReminderBulkDisabled(): boolean {
			return !this.selectedContractors?.length || 
				this.filterComboModel?.filters?.some(f => f && f.field === 'noContracts' && f.value === true) ||
				this.contractors.filter(x => this.selectedContractors.includes(x.id)).some(x => x.contracts?.some(c => c.endDate && moment(c.endDate).isBefore(moment())))
		},
		contractTemplateFilterSet(): boolean {
			return !!this.filterComboModel?.filters?.some(f => f && f.field === 'contractTemplate' && f.value !== 'all')
		},
		disabledFilters(): string[] {
			return this.filterComboModel.filters.map(f => f.field)
		},
		contractTemplatesForField() {
			return this.contractTemplates.map(ct => ({ id: ct.id, label: ct.name}))
		},
		filtersForRequest(): ContractorFilterItem[] {
			return this.filterComboModel?.filters?.filter(f => f).map(f => ({
				field: f.field,
				mode: f.mode,
				value: f.value,
			})).filter(f => !!f.value && f.value !== 'all') || []
		},
		filterContractTemplate (): any {
			const filterTemplate = this.filterComboModel?.filters?.filter(f => f).find(f => f.field === 'contractTemplate' && f.value !== 'all')?.value
			if (filterTemplate) {
				const contractTemplate = this.contractTemplates?.find(x => x.id === filterTemplate)
				return contractTemplate
			}
			return null
		},
		contractsForTerminate () {
			if (!this.contractors || !this.selectedContractors || !this.filterContractTemplate) {
				return []
			}
			const contractors = this.contractors.filter(x => this.selectedContractors.includes(x.id))
			const contractTemplateId = this.filterContractTemplate?.id
			return contractors.flatMap(x => {
				if (!x.contracts) return []
				return x.contracts.filter(c => 
					c.contractTemplate && 
					c.contractTemplate.id === contractTemplateId && 
					(!c.endDate || moment(c.endDate).isSameOrAfter(moment()))
				)
			})
		},
	},
	watch: {
		'filterComboModel.filters': {
			deep: true,
			async handler(n) {
				if (!this.filterComboModel?.filters) return
				
				const filters = JSON.stringify(this.filtersForRequest)
				if (JSON.stringify(this.lastRequestFilters) === filters) return

				this.lastRequestFilters = JSON.parse(filters)
				this.loadContractors()
				
				await this.validateAndCommitFilters()
			},
		},
		'filterComboModel.search'(n) {
			if (!this.filterComboModel) return
			
			if (this.searchTimeout) clearTimeout(this.searchTimeout)
			if (!n?.length) {
				this.contractorsModelFiltered = this.contractorsModel
				return
			}
			this.searchTimeout = window.setTimeout(() => {
				this.contractorsModelFiltered = this.contractorsModel.filter(c => c.name.toLowerCase().includes(n.toLowerCase()))
				
				this.validateAndCommitFilters()
			}, 200)
		},
	},
	methods: {
		resetFilters() {
			console.log('Resetting filters locally in ContractorsView')
			this.filterComboModel = {
				filters: [],
				search: ''
			}
			// Force the FilterCombo component to re-render
			this.$nextTick(() => {
				// This will trigger the ContractorFilterCombo to reset with default filters
				this.useFiltersFromStore()
			})
		},
		async validateAndCommitFilters() {
			const validFilters = this.filterComboModel?.filters?.filter(f => 
				f && typeof f === 'object' && f.field && typeof f.field === 'string' && f.id
			) || []
			
			if (validFilters.length !== this.filterComboModel.filters.length) {
				console.log('Skipping filter update due to invalid filters')
				return
			}

			// Save filters to store when they change - deep clone to ensure serializable data
			this.$store.commit('setContractorsViewFilters', {
				filters: cloneDeep(validFilters),
				search: this.filterComboModel?.search || ''
			})
		},
		async terminateContracts({endDate, shouldReceiveEmail, contractIds}) {
			this.loading = true
			try {
				let contractExecutive = new ContractExecutive(this)
				const { terminatedCount, totalCount } = await contractExecutive.bulkSetTerminationDateToContract(endDate, shouldReceiveEmail, contractIds)
				this.showSuccessToast(this.$t('text.terminateContractsSuccess', { terminatedCount, totalCount }), true)
				this.loadContractors()
			}
			catch (error) {
				this.showErrorToast(this.$t('text.terminateContractsError'), true)
			}
			finally {
				this.loading = false
			}
		},
		async loadContractors() {
			try {
				this.loading = true
				this.offset = 0
				this.selectedContractors = []
				let contractExecutive = new ContractExecutive(this)
				this.contractors = await contractExecutive.getContractorsForList(this.$store.state.selectedClient.sys.id, this.filtersForRequest)
				this.contractorsModel = this.mapContractorResults(this.contractors)
				
				const searchTerm = this.filterComboModel?.search?.toLowerCase() || ''
				this.contractorsModelFiltered = searchTerm 
					? this.contractorsModel.filter(c => c.name.toLowerCase().includes(searchTerm))
					: this.contractorsModel
			}
			catch (error) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			}
			finally {
				this.loading = false
			}
		},
		async loadContractTemplates() {
			try {
				let contractExecutive = new ContractExecutive(this)
				this.contractTemplates = await contractExecutive.getContractTemplates(this.$store.state.selectedClient.sys.id)
			}
			catch (error) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			}
		},
		mapContractorResults(contractors) {
			return contractors.map(r => {
				const contractorStatus = r.hasAtLeastOneContract ? 'assigned' : 'missing'
				return {
					id: r.id,
					name: r.name,
					email: r.email,
					noEmail: !r.email?.length,
					needsContract: r.needsContract,
					productKinds: r.productKinds,
					salesChannels: r.salesChannelObjects.map(sc => sc?.title?.de),
					contractorStatus,
					contractStatus: 'TODO',
					contracts: r.contracts || [],
					lastReminderDate: r.lastReminderDate,
					peakId: r.peakId,
					serviceProviderId: r.serviceProviderId,
					streetAddress: r.streetAddress,
					zipCode: r.zipCode,
					city: r.city,
				}
			})
		},
		checkAssignContractStep() {
			if (this.assignContractDialogStep === 'chooseContract') {
				this.assignContractDialogStep = 'confirm'
				return false
			}
			else {
				this.confirmAssignContract()
			}
		},
		async confirmAssignContract() {
			this.loading = true
			try {
				let contractExecutive = new ContractExecutive(this)
				const result = await contractExecutive.assignContract(this.$store.state.selectedClient.sys.id, this.selectedContractTemplate.de, this.selectedContractors)
				// TODO show number of contracts that have been assigned
				await this.loadContractors()
				
				this.showSuccessToast(this.$t('text.assignContractSuccess', { assignedCount: result.assignedCount, totalCount: result.totalCount }), true)
				this.resetAssignContractDialog()
			}
			catch (error) {
				console.log(error)
				this.showErrorToast(this.$t('text.assignContractError'), true)
			}
			finally {
				this.loading = false
			}
		},
		async sendReminder() {
			this.loading = true
			try {
				let contractExecutive = new ContractExecutive(this)
				const { sentCount, totalCount } = await contractExecutive.sendReminder(this.$store.state.selectedClient.sys.id, this.selectedContractors, this.ignoreRecentlySent)
				await this.loadContractors()
				
				this.resetSendReminderDialog()
				this.showSuccessToast(this.$t('text.sendReminderSuccess', { sentCount, totalCount }), true)
			}
			catch (error) {
				console.log(error)
				this.showErrorToast(this.$t('text.sendReminderError'), true)
			}
			finally {
				this.loading = false
			}
		},
		async bulkSetNoContractNeeded() {
			this.loading = true
			try {
				let contractExecutive = new ContractExecutive(this)
				const { updatedCount, totalCount } = await contractExecutive.bulkSetNeedsContract(this.selectedContractors)
				await this.loadContractors()
				
				this.showSuccessToast(this.$t('text.setNoContractNeededSuccess', { updatedCount, totalCount }), true)
			}
			catch (error) {
				console.log(error)
				this.showErrorToast(this.$t('text.setNoContractNeededError'), true)
			}
			finally {
				this.loading = false
			}
		},
		async exportContractors() {
			await this.loadingOverlay(async () => {
				try {
					const clientId = this.$store.state.selectedClient.sys.id
					let contractExecutive = new ContractExecutive(this)
					const response = await contractExecutive.exportContractors(clientId, this.selectedContractors)
					console.log(response)

					const fileName = this.generateExcelFileName()
					// Note: saveAs is imported globally on the index.js file
					saveAs(new Blob([response]), `${fileName}.xlsx`)
					this.showSuccessToast(this.$t('text.exportContractorsSuccess'), true)
				}
				catch (error) {
					console.log(error)
					this.showErrorToast(this.$t('text.exportContractorsError'), true)
				}
			}, 'exportContractors')
		},
		generateExcelFileName() {
			let fileName = 'Vertragspartner Informationen'
			// TODO: this does not work nicely, we have the I18N concerns, the values being id-s etc.
			// if (this.filterComboModel.filters.length > 0) {
			// 	fileName = this.filterComboModel.filters.map(filter => {
			// 		const field = filter.field
			// 		const value = filter.value
			// 		const mode = filter.mode
			// 		const modeText = mode === 'eq' ? 'is' : mode
			// 		return field + ' ' + value
			// 	}).join(' ')
			// }
			return fileName
		},
		onCloseDialog() {
			this.assignContractDialogStep = 'chooseContract'
			this.selectedContractTemplate = { de: null }
		},
		resetAssignContractDialog() {
			this.onCloseDialog()
			this.selectedContractors = []
			this.$refs['assignContractDialog'].show = false
		},
		resetSendReminderDialog() {
			this.ignoreRecentlySent = false
			this.$refs['sendReminderDialog'].close()
		},
		async onBulkClick(optionKey) {
			if (optionKey === 'assignContract') {
				this.$refs['assignContractDialog'].show = true
			}
			else if (optionKey === 'noContractNeeded') {
				this.$refs['noContractNeededDialog'].open()
			}
			else if (optionKey === 'terminateContract') {
				this.$refs['terminateContractsDialog'].open()
			}
			else if (optionKey === 'sendReminder') {
				this.$refs['sendReminderDialog'].open()
			}
			else if (optionKey === 'contractorExcelExport') {
				await this.exportContractors()
			}
		},
		useFiltersFromStore() {
			// Load saved filters from store if they exist
			try {
				const savedFilters = this.$store.state.contractorsViewFilters
				
				// Only use saved filters if they contain properly structured objects
				if (savedFilters && savedFilters.filters) {
					// Make sure filters have the required structure
					const validFilters = Array.isArray(savedFilters.filters) 
						? savedFilters.filters.filter(f => 
							f && typeof f === 'object' && f.field && typeof f.field === 'string' && f.id
						)
						: []
					
					
					// Only use the saved filters if we have valid ones
					if (validFilters.length > 0) {
						console.log('Using saved filters from store')
						this.filterComboModel = {
							filters: validFilters,
							search: typeof savedFilters.search === 'string' ? savedFilters.search : ''
						}
					} else {
						console.log('No valid filters found in store')
					}
				}
			} catch (error) {
				console.error('Error loading saved filters:', error)
			}
		}
	},
	async created() {
		// Initialize filterComboModel first to ensure it always exists
		this.filterComboModel = {
			filters: [],
			search: ''
		}
		
		this.useFiltersFromStore()
		
		await this.loadContractors()
		await this.loadContractTemplates()
	},
}
</script>

<style scoped lang="scss">
.ContractorsView {
	.navbar {
		display: flex;
		gap: 24px;
		align-items: center;
		flex-grow: 1;
		height: 100%;
		padding: 12px 0 12px 24px;

		&__magnifier {
			position: absolute;
			left: 24px;
			top: 32px;
			transform: translateY(-50%);
			display: flex;
			align-items: center;
			margin: 0 10px 0 12px;
		}

		&__filter {
			box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
			flex: 1;
		}

		&__search-btn {
			background: var(--color-blue);
			padding: 8px 16px;
			color: white;
			border-radius: 4px;
		}
	}

	.contractors {
		margin-top: 64px;
	}
}
</style>
<style lang="scss">
.ContractorsView {
	.assign-dialog {
		&__confirm {
			display: flex;
			align-items: center;

			&__icon {
				margin-right: 48px;
			}
		}
	}
}
</style>