<template>
	<div class="contractor-form">
		<FieldSet v-if="step" mdi-icon="lifebuoy" :label="$t('text.helpNeeded')" :info-text="$t('text.moreInfoAlturosAcademy')" />
		<FieldSet v-else :label="$t('text.yourData')" :info-text="$t('text.yourDataInfoText')" />

		<template v-if="!step || step === 'businessInformation'">
			<FieldSet class="mt-6" :label="$t('text.companyDetails')">
				<Field class="mt-4" typeName="BusinessProfileData" fieldName="businessName"
					v-model="contractor.businessProfileData.businessName" dataCy="businessName"
					:info-text="$t('text.contractBusinessNameInfoText')" />
				<Field class="mt-4" typeName="BusinessProfileData" fieldName="companyEmail"
					v-model="contractor.businessProfileData.companyEmail" dataCy="companyEmail" />
					<FieldSet :label="`${$t('text.uidNumber')} (${$t('text.required')})`" style="padding-left: 0;" :left-border="false" class="no-border">
						<div class="needs-uid-number">
								<div>
									<span>{{ $t('text.dontHaveUidNumber') }}</span>
									<span>{{ $t('text.dontHaveUidNumberInfoText') }}</span>
								</div>
								<v-spacer />
								<v-switch v-model="contractor.businessProfileData.dontHaveUidNumber" class="mys-switch contractorSwitch" :class="{ selected: !!contractor.businessProfileData.dontHaveUidNumber }" inset hide-details/>
							</div>
						<Field class="mt-4" typeName="BusinessProfileData" fieldName="uidNumber"
							v-model="contractor.businessProfileData.uidNumber" dataCy="uidNumber" :required="!contractor.businessProfileData.dontHaveUidNumber" :watch-required="true" />
						<Field class="mt-4" typeName="BusinessProfileData" fieldName="taxId"
							v-model="contractor.businessProfileData.taxId" dataCy="taxId" />
					</FieldSet>
				<FieldSet :label="$t('text.personalInformationBusinessOwner')" :info-text="$t('text.personalInformationBusinessOwnerInfoText')" style="padding-left: 0;" :left-border="false">
					<div class="business-owner-info">
						<Field class="mt-4" typeName="BusinessProfileData" fieldName="businessOwnerDateOfBirth"
						v-model="contractor.businessProfileData.businessOwnerDateOfBirth" dataCy="businessOwnerDateOfBirth" />
						<Field class="mt-4" typeName="BusinessProfileData" fieldName="businessOwnerPlaceOfBirth"
							v-model="contractor.businessProfileData.businessOwnerPlaceOfBirth" dataCy="businessOwnerPlaceOfBirth" />
					</div>
				</FieldSet>
			</FieldSet>
			
			<FieldSet class="mt-6" :label="$t('text.companyAddress')">
				<Field class="mt-4" typeName="BusinessProfileData" fieldName="streetAddress"
					v-model="contractor.businessProfileData.streetAddress" dataCy="streetAddress" />
				<div class="d-flex" style="gap: 10px;">
					<Field style="width: 100%;" class="mt-4" typeName="BusinessProfileData" fieldName="zipCode"
						v-model="contractor.businessProfileData.zipCode" dataCy="zipCode" />
					<Field style="width: 100%;" class="mt-4" typeName="BusinessProfileData" fieldName="city"
						v-model="contractor.businessProfileData.city" dataCy="city" />
				</div>
				<Field class="mt-4" typeName="BusinessProfileData" fieldName="country"
					v-model="contractor.businessProfileData.country" dataCy="country" />
			</FieldSet>
		</template>
		<template v-if="!step || step === 'contractingInformation'">
			<FieldSet class="mt-6" :label="$t('text.communicationSetting')"
				:info-text="$t('text.communicationSettingInfoText')">
				<Field class="mt-4" typeName="BusinessProfileData" fieldName="invoiceRecipientName"
					v-model="contractor.businessProfileData.invoiceRecipientName" dataCy="invoiceRecipientName" />
				<Field class="mt-4" typeName="Contractor" fieldName="email" v-model="contractor.email" dataCy="email" />
			</FieldSet>

			<FieldSet class="mt-6" :label="$t('text.bankingDetails')" :info-text="$t('text.bankingDetailsInfoText')">
				<Field class="mt-4" typeName="BusinessProfileData" fieldName="accountHolder"
					v-model="contractor.businessProfileData.accountHolder" dataCy="accountHolder" />
				<Field class="mt-4" typeName="BusinessProfileData" fieldName="iban"
					v-model="contractor.businessProfileData.iban" dataCy="iban" />
				<Field class="mt-4" typeName="BusinessProfileData" fieldName="bic"
					v-model="contractor.businessProfileData.bic" dataCy="bic" />
				<Field class="mt-4" typeName="BusinessProfileData" fieldName="creditInstitution"
					v-model="contractor.businessProfileData.creditInstitution" dataCy="creditInstitution" />
			</FieldSet>
		</template>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Field from '@/components/fields/Field.vue'
import FieldSet from '@/views/applications/packageDesigner/FieldSet.vue'
import ChildErrorReceiver from '../../views/applications/packageDesigner/ChildErrorReceiver.vue'

export default {
	name: 'ContractorForm',
	components: { Field, FieldSet },
	mixins: [Common, ChildErrorReceiver],
	props: {
		modelValue: Object,
		step: String,
	},
	data() {
		return {
			contractor: {
				businessProfileData: {}
			}
		}
	},
	watch: {
		childErrorMessage() {
			if (this.childErrorMessage) {
				this.$emit('update:hasFormError', true)
			}
			else {
				this.$emit('update:hasFormError', false)
			}
		},
		contractor: {
			deep: true,
			handler(n) {
				this.$emit('update:modelValue', n)
			},
		},
	},
	mounted() {
		this.contractor = { ...this.modelValue }
	}
}
</script>

<style lang="scss">
.contractor-form {
	.business-owner-info {
		display: flex;
		gap: 10px;
		.Field {
			flex-grow: 1;
			margin-bottom: 0;
			.DateField input {
				max-width: none !important;
			}
		}
	}
	.needs-uid-number {
		display: flex;
		padding-top: 12px;
		.v-selection-control {
			min-height: auto;
		}
		> div {
				display: flex;
				flex-direction: column;
				justify-content: center;
			> span:first-child {
				font-size: 15px;
				font-weight: 400;
				line-height: 20px;
				letter-spacing: -0.037px;
			}
			> span:last-child {
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: 16px;
				letter-spacing: 0.001px;
				color: #8F8F8F;
			}
			.contractorSwitch.v-switch {
				.v-switch__track {
					border: 2px solid var(--status-color-info, #8F8F8F) !important;
					background: var(--primary-color-transparent, rgba(255, 255, 255, 0.00)) !important;
					opacity: 1 !important;
				}
				.v-switch__thumb {
					background-color: var(--status-color-info, #8F8F8F) !important;
					width: 24px !important;
					height: 24px !important;
					box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
				}
				&.selected {
					.v-switch__thumb {
						background-color: white !important;
					}
					.v-switch__track {
						background-color: #00aeef !important;
						border-color: #00aeef !important;
					}
				}
			}
		}
	}
	.FieldSet {
		&.no-border {
			border: none !important;
		}
		.infoText,
		>.helpText {
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
			font-style: normal;
			letter-spacing: 0.001px;
			color: black;
		}

		.FieldSet-label {
			font-size: 17px;
			font-weight: 500;
			line-height: 22px;
			letter-spacing: -0.071px;
			color: black;
		}

		.Field label.title {
			font-size: 15px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: -0.037px;
			color: black;
		}
	}
}
</style>