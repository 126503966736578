<template>
	<v-row align="center" :justify="showButtons ? 'space-between' : 'center'" class="StepBar">
		<v-btn v-if="showButtons && !hideGoBack" id="backBtn" class="ml-1 text-capitalize" variant="outlined" @click="goBack" >
			<v-icon class="mr-1">mdi-arrow-left</v-icon>
			{{ $t('text.back') }}
		</v-btn>
		<ul v-if="steps?.length" class="steps">
			<li class="step" v-for="(step, index) of steps" :key="step.id"
				@click="show(step)"
				:data-cy="`step-bar-${step.id}`"
				:class="{
					complete: step.status == 'complete',
					error: step.status == 'error',
					current: step.id == currentStepId,
					disabled: step.disabled,
				}"
			>
				<span class="divider" v-if="showDividers === true && index < steps.length-1"></span>
				<span class="icon">
					<v-icon v-if="step.icon" size="14" color="white">{{ step.icon }}</v-icon>
				</span>
				<span class="label">{{ $t('text.' + step.id) }}</span>
			</li>
		</ul>
		<v-btn v-if="showButtons && !hideGoNext" id="nextBtn" class="mr-7 text-capitalize next-btn" variant=outlined @click="goNext" >
			{{ $t('text.next') }}
			<v-icon class="ml-1">mdi-arrow-right</v-icon>
		</v-btn>
	</v-row>
</template>

<script>
import eventBus from '@/utils/eventBus.js'

export default {
	props: {
		showDividers: { type: Boolean, default: true },
		showButtons: { type: Boolean, default: false },
		group: { type: String, default: 'app' },
		order: { type: Array, default: () => [] },
	},
	data: () => ({
		stepsByGroup: {},
		currentStepId: null,
		// TODO: on change of modelValue, call show?
		modelValue: null,
	}),
	computed: {
		steps() {
			return this.stepsByGroup[this.group] ?? []
		},
		hideGoBack() {
			return this.steps.findIndex(s => s.id == this.currentStepId) <= 0
		},
		hideGoNext() {
			return this.steps.findIndex(s => s.id == this.currentStepId) >= (this.steps.length - 1)
		},
	},
	methods: {
		register(step, group) {
			if (group != this.group) return
			if (!this.stepsByGroup[group]) this.stepsByGroup[group] = []
			const steps = this.stepsByGroup[group]

			const index = steps.findIndex(s => s.id == step.id)
			// on update, replace the step
			if (index >= 0) {
				steps.splice(index, 1, step)
			}
			// on add, insert the step
			else {
				// if the step is not explicitely ordered, add at the end
				if (!this.order || !this.order.includes(step.id)) {
					steps.push(step)
				}
				else {
					const prios = {}
					for (let s = 0; s < this.order.length; s++) prios[this.order[s]] = s
					const newStepPrio = prios[step.id]
					let found = false
					for (let s = 0; s < steps.length; s++) {
						const prio = prios[steps[s].id] ?? 999
						// look for the first place with a higher prio than the new step
						if (prio > newStepPrio) {
							// insert before that one
							steps.splice(s, 0, step)
							found = true
							break
						}
					}
					// if we did not find a place, add at the end
					if (!found) steps.push(step)
				}

				// show the first step
				if (steps.length == 1) this.show(step)
			}
			this.$emit('steps', steps)
		},
		// TODO: if the removed one is currently shown -> show the next one?
		deregister(step, group) {
			if (group != this.group) return
			if (!this.stepsByGroup[group]) this.stepsByGroup[group] = []
			const steps = this.stepsByGroup[group]

			const index = steps.findIndex(s => s.id == step.id)
			if (index < 0) return
			steps.splice(index, 1)
			this.$emit('steps', steps)
		},
		show(stepOrId) {
			const step = typeof stepOrId == 'string' ? this.steps.find(s => s.id == stepOrId) : stepOrId
			if (this.currentStepId == step.id) return
			this.$emit('update:modelValue', step.id)
			this.$emit('step', step.id)
			eventBus.$emit('step-show', step.id, this.group)
			this.currentStepId = step.id
		},
		goBack() {
			const index = this.steps.findIndex(s => s.id == this.currentStepId)

			if (index <= 0) return

			const step = this.steps[index - 1]
			this.$emit('update:modelValue', step.id)
			this.$emit('step', step.id)
			eventBus.$emit('step-show', step.id, this.group)
			this.currentStepId = step.id
		},
		goNext() {
			const index = this.steps.findIndex(s => s.id == this.currentStepId)

			if (index >= (this.steps.length - 1)) return

			const step = this.steps[index + 1]
			this.$emit('update:modelValue', step.id)
			this.$emit('step', step.id)
			eventBus.$emit('step-show', step.id, this.group)
			this.currentStepId = step.id
		},
	},
	mounted() {
		eventBus.$on('step-register', this.register)
		eventBus.$on('step-deregister', this.deregister)
		eventBus.$emit('step-cfr', this, this.group)
	},
	unmounted() {
		eventBus.$off('step-register', this.register)
		eventBus.$off('step-deregister', this.deregister)
	},
}
</script>

<style scoped>
.StepBar { 
	height: 60px; 
	width: 100%; 
	padding-left: 320px; 
	border-bottom: 1px solid #ddd; 
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

@media screen and (max-width: 950px) { 
	.StepBar { 
		padding-left: 0; 
	} 
}

@media screen and (max-width: 1040px) { 
	.StepBar { 
		justify-content: center !important; 
	} 
	#backBtn,#nextBtn { 
		display: none; 
	} 
}

.steps { 
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 1 auto;
	margin: 0 20px;
	max-width: 1000px;
	position: relative;
	width: 100%;
}

ul { 
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	margin: 0;
	padding: 0;
	width: 100%;
	position: relative;
	max-width: 1000px;
}

li { 
	position: relative; 
	text-align: center; 
	cursor: default; 
	display: flex; 
	flex-direction: column; 
	gap: 5px;
	flex: 1;
}

.step { 
	--col: #737373; 
	color: var(--col); 
	position: relative; 
	cursor: pointer;
	min-width: 100px;
	padding: 0 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}

.step .icon { 
	background: var(--col); 
	width: 20px; 
	height: 20px; 
	border-radius: 10px; 
	display: flex; 
	justify-content: center; 
	align-items: center;
	position: relative;
	z-index: 2;
}

.step .label { 
	font-size: 12px; 
	white-space: nowrap; 
	line-height: 1.2; 
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100%;
	padding: 0 5px;
}

.divider { 
	border-top: 3px solid var(--col); 
	position: absolute; 
	top: 9px; 
	width: calc(100% + 20px);
	left: calc(50% + 10px);
	z-index: 1;
}

.current { font-weight: bold; }
.current .icon { outline: 2px solid var(--col); outline-offset: 1px; }
.complete { --col: #7BC043; }
.error { --col: #ff0022; }
.disabled { pointer-events: none; opacity: 0.5; }
.v-icon { position: relative; }

#backBtn {
	position: absolute;
	left: 340px;
}

.next-btn { 
	position: absolute;
	right: 20px;
}

@media screen and (max-width: 768px) {
	.step {
		min-width: 80px;
		padding: 0 5px;
	}
	
	.step .label {
		font-size: 11px;
	}

	.steps {
		margin: 0 10px;
	}

	ul {
		gap: 10px;
	}

	#backBtn {
		left: 20px;
	}
}

/* Styles for when StepBar2 is used in StepperDialog */
:deep(.DataDialog__title) .StepBar {
	padding-left: 0;
	border: 0;
}

/* Styles for when StepBar2 is used in PackageDesigner */
.step-bar.StepBar {
	padding-left: 0;
	width: 100%;
	display: flex;
	justify-content: center;
}

.step-bar .steps {
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
	padding: 0 20px;
}
</style>