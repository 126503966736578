<template>
	<Application class="ContractorDetailView" :errorDetail="errorDetail" v-model:errorTitle="errorTitle" :successDetail="successDetail"
		v-model:successTitle="successTitle" :loading="loading || spLoading"
		:style="{ 'padding-left': isSideBarFixed && !isMobile ? '315px' : '15px' }"
	>
		<!-- TODO: we should not use bootstrap classes -->
		<template #navbar>
			<v-btn id="btnBack" elevation="0" style="background-color: transparent; min-width: 40px;" @click="goback()">
				<v-icon>mdi-chevron-left</v-icon>
			</v-btn>
			<span style="font-size: 17px; font-weight: 600; line-height: 22px;">{{ contractor?.name }}</span>
		</template>
		<SideBar class="contractor-sidebar" :actions="sidebarActions" :navigation="sections" :show-action-title="true"
			:preselectedActiveLink="'contractorData'" @action="handleAction($event)" :class="{ mobileSidebar: isMobile }"
		/>
		<div style="margin-top: 60px;" v-if="contractor">
			<Disclosure :title="$t('text.contractorData')" expanded ref="SECTION_contractorData" class="contractor-data-disclosure"
				:error="!!childErrorMessage" :message="childErrorMessage"
			>
				<ContractorForm v-model="contractor" @update:modelValue="contractor = $event" />
			</Disclosure>
			<Disclosure :title="$t('text.contracts')" expanded ref="SECTION_contracts" class="contracts-disclosure">
				<FieldSet :label="`${$t('text.activeContracts')} (${$t('text.required')})`" :info-text="$t('text.activeContractsInfoText')">
					<div class="needs-contract-info">
						<div>
							<span>{{ $t('text.serviceProviderDoesNotNeedContract') }}</span>
							<span>{{ $t('text.serviceProviderDoesNotNeedContractInfoText') }}</span>
						</div>
						<v-spacer />
						<v-tooltip location="bottom">
							<template #activator="{ props }"><div v-bind="props"><v-switch v-model="doesNotNeedContract" class="mys-switch contractorSwitch" :class="{ selected: !!doesNotNeedContract }" inset hide-details :disabled="!!contracts.length" /></div></template>
							{{ $t('text.terminateActiveContractsBeforehand') }}
						</v-tooltip>
					</div>
					<ContractsList v-if="contracts.length" :contracts="contracts" :contractors="[contractor]" :client-names="clientNames" @openTerminateContractDialog="openTerminateContractDialog($event)" />
				</FieldSet>
				<FieldSet v-if="terminatedContracts.length" :label="$t('text.terminatedContracts')" :info-text="$t('text.terminatedContractsInfoText')" style="padding-top: 32px">
					<ContractsList :contracts="terminatedContracts" :contractors="[contractor]" :terminated="true" :client-names="clientNames" />
				</FieldSet>
			</Disclosure>
			<Disclosure :title="$t('text.assignedServiceProvider')" expanded ref="SECTION_assignedServiceProvider" class="assigned-service-disclosure">
				<FieldSet :label="$t('text.assignedServiceProvider')" :info-text="$t('text.assignedServiceProviderInfoText')">
					<div v-if="!contractorServiceProvider" class="assgined-service-empty">{{ $t('text.noServiceProviderAssigned') }}</div>
					<table v-else class="assgined-service-table">
						<thead>
							<tr>
								<th>{{ $t('text.name') }}</th>
								<th>{{ $t('text.productCategories') }}</th>
								<th>{{ $t('text.salesChannels') }}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{{ contractorServiceProvider.title[serviceLocale] }}</td>
								<td>
									<div class="assigned-service-cat-chip">
										<div v-for="(productCategory, index) of getProductCategories(contractorServiceProvider?.productCategories)" :key="index">{{ productCategory }}</div>
									</div>
								</td>
								<td>{{ getSalesChannels(contractorServiceProvider?.salesChannels) }}</td>
							</tr>
							<tr v-for="(linkedServiceProvider, index) of contractorServiceProvider.linkedServiceProviders" :key="index">
								<td>
									<div class="assigned-service-title">
										<v-icon size="24px">mdi-link</v-icon>
										<div>
											<span>{{ $t('text.linkedTo') }}</span>
											<span>{{ linkedServiceProvider.title[serviceLocale] }}</span>
										</div>
									</div>
								</td>
								<td>
									<div class="assigned-service-cat-chip">
										<div v-for="(productCategory, index) of getProductCategories(linkedServiceProvider.productCategories)" :key="index">{{ productCategory }}</div>
									</div>
								</td>
								<td>{{ getSalesChannels(linkedServiceProvider.salesChannels) }}</td>
							</tr>
						</tbody>
					</table>
				</FieldSet>
			</Disclosure>
		</div>
		<SendReminderDialog ref="sendReminderDialog" :modelValue="ignoreRecentlySent" :contractors="[contractor]" @update:modelValue="ignoreRecentlySent = $event" @sendReminder="sendReminder()" />
		<TerminateContractsDialog ref="terminateContractsDialog" :contract="contractForTerminate" @terminateContracts="terminateContracts($event)" />
	</Application>
</template>

<script>
import Common from '../../../mixins/Common.vue'
import Application from '../Application.vue'
import SideBar from '../../../components/common/SideBar.vue'
import ChildErrorReceiver from '../packageDesigner/ChildErrorReceiver.vue'
import ContractExecutive from '../../../../../api2/src/modules/contract/ContractExecutive'
import Disclosure from '../../../components/common/Disclosure.vue'
import FieldSet from '../packageDesigner/FieldSet.vue'
import ContractsList from '../../../components/contract/ContractsList.vue'
import ContractorForm from '../../../components/contract/ContractorForm.vue'
import moment from 'moment'
import SendReminderDialog from '../../../components/contract/SendReminderDialog.vue'
import TerminateContractsDialog from '../../../components/contract/TerminateContractsDialog.vue'
import Toast from '../../../mixins/Toast.vue'

export default {
	components: { Application, SideBar, Disclosure, FieldSet, ContractsList, ContractorForm, SendReminderDialog, TerminateContractsDialog },
	mixins: [ Common, ChildErrorReceiver, Toast ],
	props: {
		id: { type: String, required: true },
	},
	data: () => ({
		windowWidth: window.innerWidth,
		contractor: null,
		spLoading: false,
		contractorServiceProvider: null,
		clientNames: {},
		terminatedContracts: [],
		contracts: [],
		doesNotNeedContract: false,
		ignoreRecentlySent: false,
		contractForTerminate: null,
	}),
	computed: {
		isMobile() {
			return this.windowWidth < 768
		},
		sidebarActions() {
			const actions = []
			actions.push({ icon: 'mdi-check', style: 'blue', dark: true, title: this.$t('text.saveChanges'), function: 'saveContractor', params: 'save', datacy: "save", disabled: this.childErrorMessage && !this.doesNotNeedContract },
				{ icon: 'mdi-bell', style: 'outline', dark: false, title: this.$t('text.sendReminder'), function: 'sendReminder', params: 'sendReminder' })
			return actions
		},
		sections() {
			return [{
				id: 'contractorData',
				state: 'valid',
			},
			{
				id: 'contracts',
				state: 'valid',
			},
			{
				id: 'assignedServiceProvider',
				state: 'valid',
			}]
		}
	},
	mounted() {
		window.addEventListener('resize', this.onResize)
		this.getContractor()
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.onResize)
	},
	watch: {
		doesNotNeedContract() {
			this.contractor.needsContract = !this.doesNotNeedContract
		}
	},
	methods: {
		openTerminateContractDialog(contract) {
			this.contractForTerminate = contract
			this.$refs.terminateContractsDialog.open()
		},
		async terminateContracts({endDate, shouldReceiveEmail, contractIds}) {
			this.loading = true
			try {
				let contractExecutive = new ContractExecutive(this)
				const { terminatedCount, totalCount } = await contractExecutive.bulkSetTerminationDateToContract(endDate, shouldReceiveEmail, contractIds)
				this.getContractor()
				this.showSuccessToast(this.$t('text.terminateContractsSuccess', { terminatedCount, totalCount }), true)
			}
			catch (error) {
				this.showErrorToast(this.$t('text.terminateContractsError'), true)
			}
			finally {
				this.loading = false
			}
		},
		handleAction(event) {
			if (event.function === 'saveContractor') {
				this.updateContractor()
			}
			if (event.function === 'sendReminder') {
				this.$refs.sendReminderDialog.open()
			}
			if (event.function === 'setActive') {
				window.setTimeout(() => {
				const el = this.$refs['SECTION_' + event.params].$el
				const y = el.getBoundingClientRect().top + window.pageYOffset - 76
				window.scrollTo({ top: y, behavior: 'smooth' })
			}, 200)
			}
		},
		getProductCategories(productCategories) {
			if (!productCategories) return []
			return productCategories.map(x => x[this.serviceLocale])
		},
		getSalesChannels(salesChannels) {
			if (!salesChannels) return []
			return salesChannels.length <= 2 ? salesChannels.join(', ') : `${salesChannels.slice(0, 2).join(', ')}, +${salesChannels.length - 2}`
		},
		async updateContractor() {
			this.loading = true
			try {
				let contractExecutive = new ContractExecutive(this)
				
				await contractExecutive.bulkUpdateContractors(null, [this.contractor])
				this.showSuccessToast(this.$t('text.updateContractorSuccess'), true)
			}
			catch (error) {
				this.showErrorToast(this.$t('text.updateContractorError'), true)
			}
			this.loading = false
		},
		async getContractor() {
			this.loading = true
			try {
				let contractExecutive = new ContractExecutive(this)
				const result = await contractExecutive.getContractsAndContractorById(this.id)
				this.contractor = result.contractors[0]
				this.doesNotNeedContract = !this.contractor.needsContract
				this.clientNames = result.clientNames
				const allContracts = Object.values(result.contractsByContractor).flat()
				this.terminatedContracts = allContracts.filter(contract => contract.status === 'TERMINATED')
				this.contracts = allContracts.filter(contract => !contract.endDate || moment(contract.endDate).isSameOrAfter(moment()))
				if (this.contractor.serviceProviderId && !this.contractorServiceProvider) {
					this.getContractorServiceProvider(this.contractor.serviceProviderId)
				}
			}
			catch (error) {
				this.showErrorToast(this.$t('text.getContractorError'), true)
			}
			finally {
				this.loading = false
			} 
		},
		async getContractorServiceProvider(serviceProviderId) {
			this.spLoading = true
			try {
				let contractExecutive = new ContractExecutive(this)
				const result = await contractExecutive.getContractorServiceProvider(this.$store.state.selectedClient.sys.id, serviceProviderId)
				this.contractorServiceProvider = result
			}
			catch (error) {
				this.showErrorToast(this.$t('text.getContractorServiceProviderError'), true)
			}
			finally {
				this.spLoading = false
			} 
		},
		async sendReminder() {
			this.loading = true
			try {
				let contractExecutive = new ContractExecutive(this)
				const { sentCount, totalCount } = await contractExecutive.sendReminder(this.$store.state.selectedClient.sys.id, [this.contractor.id], this.ignoreRecentlySent)
				
				this.resetSendReminderDialog()
				this.showSuccessToast(this.$t('text.sendReminderSuccess', { sentCount, totalCount }), true)
			}
			catch (error) {
				this.showErrorToast(this.$t('text.sendReminderError'), true)
			}
			finally {
				this.loading = false
			}
		},
		resetSendReminderDialog() {
			this.ignoreRecentlySent = false
			this.$refs.sendReminderDialog.close()
		},
		goback() {
			this.$store.state.contractsViewActiveLink = 'contractors'
			this.$router.push({
				path: '/contractManagement'
			})
		},
		onResize() {
			this.windowWidth = window.innerWidth
		},
	},
}
</script>

<style lang="scss">
.ContractorDetailView {
	.contractor-sidebar {
		&.mobileSidebar {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 100;
		}

		.sidebar-title {
			font-size: 15px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: -0.037px;
		}
		.v-expansion-panel-title {
			font-size: 15px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: -0.037px;
			padding: 16px;
			padding-top: 56px;
			pointer-events: none;
			min-height: auto;
			display: block;
			&__icon {
				display: none;
			}
		}
		.v-expansion-panel-text__wrapper {
			padding: 0 !important;

			#navlist {
				li {
					padding-left: 28px;
					padding-right: 28px;
					list-style-type: none;
					width: 100%;
					align-items: middle;
					font-size: 15px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: -0.037px;
					color: black;
					height: 60px;
					align-content: center;
					cursor: pointer;
					border: none;

					&.active {
						background-color: #00aeef;
						color: white !important;
						font-weight: 600;
					}
				}
			}
		}
	}

	.FieldSet {
		.infoText,
		>.helpText {
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
			font-style: normal;
			letter-spacing: 0.001px;
			color: black;
		}

		>.FieldSet-label {
			font-size: 17px;
			font-weight: 500;
			line-height: 22px;
			letter-spacing: -0.071px;
			color: black;
		}

		.Field label.title {
			font-size: 15px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: -0.037px;
			color: black;
		}
	}

	.contractor-data-disclosure {
		margin-bottom: 22px;
	}

	.contracts-disclosure {
		margin-bottom: 22px;
		.needs-contract-info {
			display: flex;
			padding: 16px 0;
			.v-selection-control {
				min-height: auto;
			}
			> div {
					display: flex;
					flex-direction: column;
					justify-content: center;
				> span:first-child {
					font-size: 15px;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: -0.037px;
				}
				> span:last-child {
					font-size: 12px;
					font-style: normal;
					font-weight: 600;
					line-height: 16px;
					letter-spacing: 0.001px;
					color: #8F8F8F;
				}
				.contractorSwitch.v-switch {
					.v-switch__track {
						border: 2px solid var(--status-color-info, #8F8F8F) !important;
						background: var(--primary-color-transparent, rgba(255, 255, 255, 0.00)) !important;
						opacity: 1 !important;
					}
					.v-switch__thumb {
						background-color: var(--status-color-info, #8F8F8F) !important;
						width: 24px !important;
						height: 24px !important;
						box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
					}
					&.selected {
						.v-switch__thumb {
							background-color: white !important;
						}
						.v-switch__track {
							background-color: #00aeef !important;
							border-color: #00aeef !important;
						}
					}
				}
			}
		}
	}

	.contractor-data-disclosure,
	.contracts-disclosure,
	.assigned-service-disclosure {
		 > .v-expansion-panel {
			padding: 0;
			> .v-expansion-panel-title {
				height: auto;
				min-height: 84px;
				padding: 16px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.10);
				padding-left: 64px;
				padding-right: 64px;
			}
			> .v-expansion-panel-text > .v-expansion-panel-text__wrapper {
				padding: 32px 64px;

			}
		}
	}

	.assigned-service-disclosure {
		.assgined-service-empty {
			padding-top: 12px;
			padding-left: 12px;
			height: 48px;
			display: flex;
			align-items: center;
			font-size: 15px;
			font-weight: 400;
			line-height: 20px;
			letter-spacing: -0.037px;
			color: #DBDBDB;
		}
		.assgined-service-table {
			width: 100%;
			padding-top: 12px;
			border-collapse: collapse; 
			> thead {
				text-align: left;
				height: 48px;
				> tr > th {
					font-size: 15px;
					font-weight: 500;
					line-height: 20px;
					letter-spacing: -0.037px;
					&:first-child {
						padding-left: 12px;
					}
				}
			}
			> tbody {
				text-align: left;
				> tr {
					height: 60px;
					border-top: 1px solid #e5e5e5;
					> td {
						font-size: 15px;
						font-weight: 400;
						line-height: 20px;
						letter-spacing: -0.037px;
						&:first-child {
							padding-left: 12px;
						}
						.assigned-service-cat-chip {
							border-radius: 4px;
							height: 24px;
							padding: 0px 8px;
							display: flex;
    						width: fit-content;
							justify-content: center;
							align-items: center;
							margin-right: 10px;
							background-color: #DBDBDB;
						}
						.assigned-service-title {
							display: flex;
							align-items: center;
							> div {
								padding-left: 12px;
								display: flex;
								flex-direction: column;
								> span:first-child {
									font-size: 12px;
									font-weight: 400;
									line-height: 16px;
									letter-spacing: 0.001px;
									color: #8F8F8F;
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>