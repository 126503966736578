// these imports are removed via vite.config.json at the client
import './core/core.permissions.ts'
import './ai/ai.permissions.ts'
import './contract/contract.permissions.ts'

export enum RT {
	USER = 'user',
	ROLE = 'role',
	CLIENT = 'client',
	CLIENTASSIGNMENT = 'clientassignment',
	MARKETPLACE = 'marketplace',
	PROVIDER = 'provider',
	PRODUCT = 'product',
	OPTION = 'option',
	MEDIA = 'media',
	CONTRACT = 'contract',
	CONTRACTOR = 'contractor',
	CONTRACTSEQUENCE = 'contractsequence',
	CONTRACTTEMPLATE = 'contracttemplate',
	// ...
}