<template>
	<FilterCombo class="ContractorFilterCombo" :modelValue="modelValue" :fields="fields" />
</template>

<script lang="ts">
import FilterCombo, { newFilterForFieldOrName } from './FilterCombo.vue'
import ContractExecutive from '../../../../api2/src/modules/contract/ContractExecutive'

export default {
	components: { FilterCombo },
	props: {
		modelValue: Object,
		disabledFilters: { type: Array, default: () => [] },
	},
	data: () => ({
		model: null as any,
		contractSequences: [] as any,
		salesChannels: [] as any,
	}),
	computed: {
		fields() {
			return [
				{ id: 'contractTemplate', contentType: '_all', description: this.$t('text.contractorFilterContractTemplateNameDesc'), type: 'Symbol', scope: 'fields', in: this.contractTemplatesMap, modes: ['eq'], default: 'all' },
				{ id: 'noEmail', contentType: '_all', description: this.$t('text.contractorFilterEmailMissingDesc'), type: 'Boolean', scope: 'fields' },
				{ id: 'noContracts', contentType: '_all', description: this.$t('text.contractorFilterContractsMissingDesc'), type: 'Boolean', scope: 'fields' },
				{ id: 'salesChannels', contentType: '_all', description: this.$t('text.contractorFilterContractorSalesChannelsDesc'), type: 'Symbol', scope: 'fields', in: this.contractorSalesChannelsMap, modes: ['eq'], default: 'all' },
				{ id: 'contractState', contentType: '_all', description: this.$t('text.contractorFilterContractStateDesc'), type: 'Symbol', scope: 'fields', in: this.contractStatesMap, default: 'all' },
				{ id: 'noContractNeeded', contentType: '_all', description: this.$t('text.contractorFilterNoContractNeededDesc'), type: 'Boolean', scope: 'fields' },
			].filter(f => !this.disabledFilters.includes(f.id))
		},
		contractTemplatesMap() {
			const result = { all: this.$t('text.contractTemplatesAll')}
			this.contractSequences.forEach(cs => {
				if (cs.contractTemplates?.length) {
					// for phase 1 we assume 1 template per sequence
					const ct = cs.contractTemplates[0]
					result[ct.id] = ct.name
				}
			})
			return result
		},
		contractorSalesChannelsMap() {
			const result = { all: this.$t('text.salesChannelsAll')}
			this.salesChannels.forEach(cs => {
				result[cs.peakId] = cs.title
			})
			return result
		},
		contractStatesMap() {
			return { 
				all: this.$t('text.contractStateAll'),
				signed: this.$t('text.contractStateSigned'),
				unsigned: this.$t('text.contractStateUnsigned'),
				overdue: this.$t('text.contractStateOverdue'),
				terminated: this.$t('text.contractStateTerminated'),
			}
		},
	},
	methods: {
		async loadContractSequences() {
			try {
				console.log('loadContractSequences')
				let contractExecutive = new ContractExecutive(this)
				this.contractSequences = await contractExecutive.getContractSequences(this.$store.state.selectedClient.sys.id)
			}
			catch (error) {
				console.log(error)
			}
		},
		async loadContractorSalesChannels() {
			try {
				console.log('loadContractorSalesChannels')
				let contractExecutive = new ContractExecutive(this)
				this.salesChannels = await contractExecutive.getContractorSalesChannels(this.$store.state.selectedClient.sys.id)
			}
			catch (error) {
				console.log(error)
			}
		},
		useOrSetInitialFilters() {
			// Only set initial filters if no filters exist yet (in the store)
			if (!this.modelValue?.filters || this.modelValue.filters.length === 0) {
				console.log('Setting initial default filters')
				this.model = {
					...this.modelValue,
					filters: [
						newFilterForFieldOrName(this.fields, 'contractTemplate'),
						newFilterForFieldOrName(this.fields, 'contractState'),
						{
							...newFilterForFieldOrName(this.fields, 'noContractNeeded'),
							value: false
						}
					]
				}
				this.$emit('update:modelValue', this.model)
			}
			else {
				console.log('Using existing filters from store', this.modelValue.filters)
			}
		},
	},
	created() {
		this.model = this.modelValue
	},
	async mounted() {
		console.log('CFC mounted')
		await this.loadContractSequences()
		await this.loadContractorSalesChannels()

		this.useOrSetInitialFilters()
	}
}
</script>

<style scoped>
</style>