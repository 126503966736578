<template>
	<Dialog ref="sendReminderDialog"
		data-cy="sendReminderDialog"
		:confirmLabel="$t('text.confirmSendReminder')"
		:cancelLabel="$t('text.cancel')"
		:confirm-handler="() => $emit('sendReminder')"
		:close-handler="() => model = false"
		:title="$t('text.contractorSendReminderDialogTitle')"
		height="fit-content"
		width="540px"
		class="SendReminderDialog send-reminder-dialog"
	>
		<template #content>
			<div class="send-reminder-dialog__wrapper">
				<div class="send-reminder-dialog__icon"><v-icon :color="'#00AEEF'" :size="'96px'">mdi-bell</v-icon></div>
				<div>
					<v-label class="font-weight-bold">{{ $t('text.contractorSendReminderDialogTitle') }}</v-label>
					<p>{{ $t('text.contractorSendReminderDialogConfirmText', { contractorCount: contractors.length }) }}</p>
					<div class="send-reminder-dialog__content d-flex justify-center">
						<v-checkbox density="compact" hide-details v-model="model" />
						<p class="text-wrap ml-3 text-sm" style="width: fit-content;">{{ $t('text.contractorSendReminderDialogCheckbox') }}</p>
					</div>
				</div>
			</div>
		</template>
	</Dialog>
</template>
<script lang="ts">
import Common from '../../mixins/Common.vue'
import Dialog from '../../components/common/Dialog.vue'
export default {
	name: 'SendReminderDialog',
	components: { Dialog },
	mixins: [ Common ],
	props: {
		modelValue: { type: Boolean },
		contractors: { type: Array, default: () => [] },
	},
	data: () => ({
		model: false
	}),
	created () {
		this.model = this.modelValue
	},
	watch: {
		model () {
			this.$emit('update:modelValue', this.model)
		}
	},
	methods: {
		open() {
			this.$refs.sendReminderDialog.show = true
		},
		close() {
			this.$refs.sendReminderDialog.show = false
		}
	}
}
</script>
<style lang="scss">
.send-reminder-dialog {
	&__wrapper {
		display: flex;
		align-items: center;
	}

	&__icon {
		margin-right: 48px;
	}

	&__content {
		margin-top: 13px;
	}
}
</style>