<template>
	<div class="contract-card-tiny" :style="{ 'background-color': backgroundColor, 'color': textColor }">
		<div class="contract-card-tiny-top">
			<div class="contract-card-tiny-top-left">
				<v-icon class="mr-2" size="20px">mdi-gavel</v-icon>
				<div class="contract-card-tiny-top-name">
					<span v-if="contract.contractTemplate">{{ contract.contractTemplate.name }}</span>
					<span>{{ clientName }}</span>
				</div>
			</div>
			<div v-if="!isTerminated" :style="{ 'background-color': backgroundColorChip }" class="contract-card-tiny-top-chip">
				<v-icon size="16px">mdi-draw</v-icon>
				<span>{{ statusText }}</span>
			</div>
		</div>
		<template v-if="!isPhysical">
			<span class="contract-card-tiny-info" v-html="infoText"></span>
		</template>
		<div v-if="isTerminated" class="contract-card-tiny-terminated">
			<span v-if="contract.endDate && new Date(contract.endDate) < new Date()">
				<div v-html="$t('text.contractTerminatedInfo', { contractName: contract.contractTemplate?.name, clientName: `<strong>${clientName}</strong>` })"></div>
			</span>
			<span v-else>
				<div v-html="$t('text.contractTerminatedFutureInfo', { contractName: contract.contractTemplate?.name, clientName: `<strong>${clientName}</strong>`, endDate: `<strong>${new Date(contract.endDate).toLocaleDateString()}</strong>` })"></div>
			</span>
		</div>
		<span v-if="!isPhysical" class="contract-card-tiny-issued">{{ issuedOnText }}</span>
	</div>
</template>

<script lang="ts">
import { Contract } from '../../../../api2/src/modules/contract/contract.entities'
import ContractStatusMixin from '../../mixins/ContractStatusMixin.vue'

export default {
	mixins: [ContractStatusMixin],
	props: {
		contract: {
			type: Object as () => Contract,
			required: true
		},
		clientName: {
			type: String,
			required: true
		}
	}
}
</script>

<style scoped lang="scss">
.contract-card-tiny {
	padding: 12px;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	height: 100%;

	&-top {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		&-left {
			display: flex;
			align-items: flex-start;
		}

		&-name {
			display: flex;
			flex-direction: column;
			font-size: 15px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: -0.037px;

			> span:last-child {
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
				letter-spacing: 0.001px;
			}
		}

		&-chip {
			display: flex;
			height: 20px;
			padding: 0 6px;
			justify-content: center;
			align-items: center;
			gap: 4px;
			border-radius: 4px;
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
			color: white;
		}
	}

	&-info {
		font-size: 13px;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: -0.037px;
	}

	&-issued {
		font-size: 11px;
		font-weight: 400;
		line-height: 14px;
		letter-spacing: 0.001px;
		color: rgba(0, 0, 0, 0.5);
		margin-top: auto;
		margin-left: auto;
	}

	&-terminated {
		display: flex;
		flex-direction: column;
		font-size: 13px;
		line-height: 18px;
		
		> span:last-child {
			font-weight: 400;
		}
	}
}
</style> 