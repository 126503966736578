<template>
	<div class="SwitchField" :class="{ error, isOnTheRightSide }" style="display: flex; gap: 16px; align-items: center;">
		<v-switch v-model="model" :label="switchLabel" @focus="onFocus" @blur="onBlur"
			class="mys-switch" inset
			:class="{
				selected: !!model,
			}"
			hide-details
			:data-cy="dataCy"
			:disabled="disabled"
		/>
		<span class="clear" v-if="field.required != true && modelValue !== undefined && modelValue !== null" @click="model = null">Clear</span>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

export default {
	mixins: [ field ],
	props: {
		modelValue: Boolean,
		dataCy: String,
		disabled: Boolean,
		isOnTheRightSide: Boolean,
	},
	data: () => ({
		id: 'RG-' + Math.random(),
		model: null,
	}),
	computed: {
		switchLabel() {
			return this.model ? this.trueLabel : this.falseLabel
		},
		trueLabel() {
			return this.field.control?.settings?.trueLabel ?? ''
		},
		falseLabel() {
			return this.field.control?.settings?.falseLabel ?? ''
		},
	},
	watch: {
		// TODO: on initial load this does not fire!
		value(n) {
			this.model = n
			this.onErrors([
				this.validateRequired(),
			])
		},
	},
	mounted() {
		// this.onErrors([
		// 	this.validateRequired(),
		// ])
	},
}
</script>

<style scoped>
.mys-switch { 
	margin: 0 !important;
}

/* Use :deep() to target Vuetify's internal elements */
:deep(.v-switch .v-switch__track) { 
	/* border-radius: 100px !important; */
	border: 2px solid var(--status-color-info, #8F8F8F) !important;
	background: var(--primary-color-transparent, rgba(255, 255, 255, 0.00)) !important;
	opacity: 1 !important;
	/* height: 24px !important;
	width: 44px !important; */
}

:deep(.v-switch .v-switch__thumb) {
	background-color: var(--status-color-info, #8F8F8F) !important;
	width: 24px !important;
	height: 24px !important;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
}

:deep(.v-switch.selected .v-switch__thumb) {
	background-color: white !important;
}

:deep(.v-switch.selected .v-switch__track) { 
	background-color: #00aeef !important;
	border-color: #00aeef !important;
}

:deep(.v-switch.selected .v-switch__thumb) {
	/* transform: translateX(24px) !important; */
}

.clear { 
	color: rgb(0, 89, 200); 
	padding: 0; 
	cursor: pointer;
}

.isOnTheRightSide {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1;
}
</style>