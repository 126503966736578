<template>
	<div>
		<Disclosure :title="$t('text.publicInfoTitle')" :error="sectionMessage.error" :message="sectionMessage.message" :lock="!userMayEditFields" data-cy="publicInfo" ref="SECTION_publicInfo">
			<div class="field left-border">
				<v-label>{{$t('text.faq')}}</v-label>
				<v-select variant="outlined" density="compact" return-object
					hide-details
					:items="faqGroups"
					:item-title="item => item && item.fields && item.fields.title ? item.fields.title[selectedLocale] : ''"
					v-model="model.fields.faqGroup.de"
				/>
				<p class="helpText" v-html="$t('text.faqBusinessProfileDesc')"/>
			</div>

			<Field typeName="BusinessProfile" fieldName="longDescription" v-model="model.fields.longDescription" dataCy="longDescription" />
			<Field typeName="BusinessProfile" fieldName="shortDescription" v-model="model.fields.shortDescription" dataCy="shortDescription" />
			<Field typeName="BusinessProfile" fieldName="importantInformation" v-model="model.fields.importantInformation" dataCy="importantInfo" />

			<div v-if="$store.state.selectedServiceProvider" class="field left-border">
				<v-label>{{ $t('text.images') }}</v-label>
				<ImagesField v-model="model.fields.imageGallery.de" @toggle-loading="toggleLoading" />
			</div>
		</Disclosure>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import Field from '@/components/fields/Field.vue'
import ImagesField from '@/components/common/ImagesField.vue'
import isEqual from 'lodash/isEqual'

export default {
	name: 'PublicInfo',
	components: { Disclosure, Field, ImagesField },
	mixins: [ Common ],
	props: {
		serviceProvider: { type: Object, required: true },
		updateModel: Boolean
	},
	data: () => ({
		model: {},
		initData: {},
		sectionMessage: {
			error: false,
			message: ''
		},
		faqGroups: [],
	}),
	watch: {
		updateModel() {
			this.model = this.valueToModel(this.serviceProvider)

			if (this.$store.state.selectedServiceProvider?.sys?.id) {
				this.getFAQs()
			}
			this.setInitData()
		}
	},
	methods: {
		toggleLoading(value) {
			this.$emit('isLoading', value)
		},
		valueToModel(v) {
			return JSON.parse(JSON.stringify(v ?? {}))
		},
		setInitData() {
			const initModel = JSON.parse(JSON.stringify(this.model))
			this.initData = {
				faqGroup: initModel.fields.faqGroup,
				longDescription: initModel.fields.longDescription,
				shortDescription: initModel.fields.shortDescription,
				importantInformation: initModel.fields.importantInformation,
				imageGallery:initModel.fields.imageGallery
			}
		},
		sendData() {
			let data = {
				faqGroup: this.model.fields.faqGroup,
				longDescription: this.model.fields.longDescription,
				shortDescription: this.model.fields.shortDescription,
				importantInformation: this.model.fields.importantInformation,
				imageGallery: this.model.fields.imageGallery
			}

			data.changed = !isEqual(data, this.initData)
			return data
		},
		async getFAQs() {
			try {
				//let res = await this.$httpGet(`/faqs?serviceProviderId=${this.$store.state.selectedServiceProvider.sys.id}`)

				this.faqGroups = this.$store.state.selectedServiceProvider.fields.faqGroups?.de ?? []
				if (this.faqGroups) {this.faqGroups.sort(this.compare)}

				//add an empty object to the top to make possible a deselect for v-select as v-select does not have a built-in way to select a blank option
				this.faqGroups.unshift({})
			}
			catch (error) {
				this.showError(error)
			}
		},
		validateAllFields() {
			this.resetSectionError(this.sectionMessage)
			if (!this.model.fields.longDescription?.de ||
				!this.model.fields.shortDescription?.de) {
				this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
				return false
			}
			return true
		},
	},
	created() {
		this.model = this.valueToModel(this.serviceProvider)

		if (this.$store.state.selectedServiceProvider?.sys?.id) {
			this.getFAQs()
		}

		this.setInitData()
	},
}
</script>
	
<style scoped>
.tiptap-vuetify-editor__content { height: 300px !important; }
.tiptap-vuetify-editor .ProseMirror { height: 300px !important; }
.enabled-text-style >>> .tiptap-vuetify-editor__content{ background: #ffffff !important; height: 300px; }
.serviceLocale { position: absolute; z-index: 10; margin-top: 32px; margin-left: -27px; zoom: 0.8; }
</style>
	
	