<template>
	<div>
		<div class="loading-container" v-if="loadingItemLoading">
			<div class="loading-container__text" @click.stop>
				{{ $t('text.automaticallyLoading') }}
			</div>
		</div>
		<component
			:is="buttonType"
			class="auto-generate-btn"
			:class="{ 'loading': loadingItemLoading, disabled }"
			@click.stop="handleClick"
			data-cy="autoGenerateBtn"
			v-bind="subProps"
		>
			<IconAi class="auto-generate-btn__icon" :color="loadingItemLoading || disabled ? '#8F8F8F' : undefined" />
			{{ $t('text.autoGenerate') }}
		</component>
	</div>
</template>

<script lang="ts">
import Toast from '../../mixins/Toast.vue'
import IconAi from './icons/IconAi.vue'
import { AiLoadingItem } from '../../mixins/AiContext.vue'
import ProFeatureTag from './ProFeatureTag.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'AIAutoGenerateButton',
	components: { IconAi, ProFeatureTag },
	mixins: [ Toast, Common ],
	inject: {
		aiContext: {
			default: undefined
		}
	},
	props: {
		disabled: {
			type: Boolean,
			default: false
		},
		aiAction: String,
		contentData: Object,
	},
	computed: {
		loadingItem(): AiLoadingItem | undefined {
			if (!this.aiContext) return
			return (this.aiContext as any).aiContext_getLoadingItem(this.aiAction)
		},
		loadingItemLoading() {
			return this.loadingItem?.status === 'LOADING'
		},
		buttonType() {
			if (
				this.aiAction === 'productCategories' && this.featureEnabled('AI-product-categories') ||
				this.aiAction === 'customerSegmentTags' && this.featureEnabled('AI-customer-segment-tags')
			) {
				return 'div'
			}
			return 'ProFeatureTag'
		},
		subProps() {
			const props = {}
			if (this.buttonType === 'ProFeatureTag' && !this.disabled) {
				props['disabled'] = true
			}
			if (this.buttonType === 'ProFeatureTag') {
				props['hideHelpIcon'] = true
			}
			return props
		}
	},
	methods: {
		async getCustomerSegmentTagsWithAi() {
			if (!this.aiContext) return

			await (this.aiContext as any).aiContext_getCustomerSegmentTags(
				this.contentData,
				(result, error = null) => {
					if (error) {
						console.log('getCustomerSegmentTagsWithAi', error)
						if (error.message?.includes('rate_limit_exceeded')) {
							this.showErrorToast('errorOpenAiRateLimitExceeded') 
						}
						else if (error.message === 'No description provided') {
							this.showErrorToast('errorNoDescriptionProvided')
						}
						else if (error.code == '503') {
							this.showErrorToast('errorOpenAiServiceUnavailable')
						}
						else if (error.message?.includes('timeout of 30000ms exceeded')) {
							this.showErrorToast('errorOpenAiTimeoutExceeded')
						}
						else {
							this.showErrorToast('errorAutoGeneratingCustomerSegmentTags')
						}
						return
					}
					if (!result) {
						console.log('getCustomerSegmentTagsWithAi', result)
						this.showErrorToast('errorAutoGeneratingCustomerSegmentTags')
						return
					}
					this.$emit('aiResult', result)
					this.showSuccessToast()
				}
			)
		},
		async getProductCategoriesWithAi() {
			if (!this.aiContext) return

			await (this.aiContext as any).aiContext_getProductCategories(
				this.contentData,
				(result, error = null) => {
					if (error) {
						console.log('getProductCategoriesWithAi', error)
						if (error.message?.includes('rate_limit_exceeded')) {
							this.showErrorToast('errorOpenAiRateLimitExceeded')
						}
						else if (error.message === 'No description provided') {
							this.showErrorToast('errorNoDescriptionProvided')
						}
						else if (error.code == '503') {
							this.showErrorToast('errorOpenAiServiceUnavailable')
						}
						else if (error.message?.includes('timeout of 30000ms exceeded')) {
							this.showErrorToast('errorOpenAiTimeoutExceeded')
						}
						else {
							this.showErrorToast('errorAutoGeneratingCategories')
						}
						return
					}
					if (!result) {
						console.log('getProductCategoriesWithAi', result)
						this.showErrorToast('errorAutoGeneratingCategories')
						return
					}
					this.$emit('aiResult', result)
					this.showSuccessToast()
				}
			)
		},
		handleClick() {
			if (this.loadingItemLoading || this.disabled) {
				return
			}
			if (this.aiAction === 'customerSegmentTags') {
				this.getCustomerSegmentTagsWithAi()
			}
			else if (this.aiAction === 'productCategories') {
				this.getProductCategoriesWithAi()
			}
		}
	}
}
</script>

<style scoped lang="scss">
%disabled {
	color: #8F8F8F;
	opacity: 0.6;
	cursor: not-allowed;
	pointer-events: all;
}

.loading-container {
	position: absolute;
	top: 0;
	left: 0;
	background: #f4f4f4;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	cursor: default;
	pointer-events: all;
	width: 100%;
	height: 100%;
	padding: 8px 12px;
	&__text {
		font-size: 0.9375rem;
		line-height: 1.33;
	}
}
.auto-generate-btn {
	display: flex;
	color: #00AEEF;
	font-size: 0.9375rem;
	margin-left: auto;
	border-radius: 0;

	> div {
		display: flex;
	}
	&__icon {
		margin-right: 8px;
	}
	&.loading {
		z-index: 1;
		@extend %disabled;
	}
	
	&.disabled {
		@extend %disabled;
	}
}
</style>
<style lang="scss">
.pro-feature-tag.auto-generate-btn > div {
	display: flex;
}
</style>