<template>
	<div class="ProductContentsTable">
		<div :class="{ 'contentMissing': hasInclusiveOrOptionalLinkedEntries && contentMissing }">
			<v-progress-linear indeterminate v-show="!contentStatusMap || loading" color="orange"></v-progress-linear>
			<SectionTable
				:items="addedValueItems"
				:hideFooter="true"
				:showExpand="true"
				:columns="[
					// TODO: width does not seem to work right - i think the table treats this as 0 width
					// { id: 'product', XXwidth: '230px', type:'detailed' },
					{ id: 'product', type: 'slot' },
					{ id: '', type: 'expand', canEdit: true },
				]"
				no-data-text="noAddedValuesAdded"
				@option-edit="item => toggleEditGlobalDialog(true, item)"
				@expand-row="getProductDetails"
			>
				<template #column-product="{ item }">
					<div>
						<div class="subtitle" >
							{{$t(`text.${item.product.type}`)}} | {{ item.product.sku }} | {{ item.product.spName }}
						</div>
						<div class="row-text">{{ item.product.main }}</div>
					</div>
				</template>
				<template #content-warning="{ item }">
					<div v-if="contentStatusMap && !contentStatusMap?.[item.product.sku]?.contentValid" style="padding-left: 5px">
						<v-sheet class="filled-warning" border="warning lg" rounded><b>{{ $t('text.contentMissing') }}</b></v-sheet>
					</div>
				</template>
				<template #expanded-row="{ item }">
					<div class="expanded-content">
						<div class="expanded-content-first-column">
							<p>{{$t('text.name')}}</p>
							<span class="expanded-content-text">{{ productDetails?.[item.product.sku]?.name?.[selectedLocale] }}</span>
							<p>{{$t('text.shortDescription')}}</p>
							<!-- TODO: should we render the short description as html content? I saw that some products have html content stored there such as <p> tags and href links. If we do this then it will not be rendered with the same style as the short descriptions without html content -->
							<span class="expanded-content-text" v-html="productDetails?.[item.product.sku]?.shortDescription?.[selectedLocale]"></span>
						</div>
						<div class="expanded-content-second-column">
							<p>{{$t('text.mediaGallery')}}</p>
							<div class="expanded-content-images">
								<v-img class="expanded-content-image" v-for="(media, index) in productDetails?.[item.product.sku]?.images?.slice(0, 4)" :key="index" :src="mediaUrl(media?.url)" width="90" height="90" cover @error="handleImageError(index)"></v-img>
								<span class="more-images-counter" v-if="productDetails?.[item.product.sku]?.images?.length > 4">+{{ productDetails?.[item.product.sku]?.images.length - 4 }}</span>
							</div>
						</div>
					</div>
				</template>
			</SectionTable>
		</div>
		<p v-if="addedValueItems?.length && contentMissing" class="helpText" style="color: orange">{{ $t('text.contentMissing') }}</p>

		<BaseProductContentDialog v-if="globalContent" ref="editContentDialog"
			:id="globalContent.product.content_id"
			:contentMissing="!contentStatusMap?.[globalContent.product.sku]?.contentValid"
			:sku="globalContent.product.sku"
			:modelValue="modelValue"
			@close="globalContent = null"
			@finished="validateMissingContent"
		/>

		<!-- Confirm Editing Global Content -->
		<Dialog ref="warningDialog"
			:confirmLabel="$t('text.confirm')"
			:cancelLabel="$t('text.cancel')"
			:confirm-handler="onWarningConfirmed"
			:cancel-handler="onWarningCancelled"
			:showClose="false"
			:title="$t('text.edit')"
			:height="'360px'"
			:width="'540px'"
		>
			<template #content>
				<v-row justify="center" align="center" style="padding:10px">
					<v-row justify="center" align="center" style="padding: 10px">
						<v-col class="warning-icon-col">
							<img class="warningDialogImage" src="@/assets/icons/icon-warning.svg" />
						</v-col>
						<v-col class="text-col">
							<div class="column">
								<span><b>{{$t('text.confirmEditGlobalContentTitle')}}</b></span>
								<span class="dialogText">{{$t('text.confirmEditGlobalContentText')}}</span>
							</div>
						</v-col>
					</v-row>
				</v-row>
			</template>
		</Dialog>
	</div>
</template>

<script lang="ts">
import ProductContentExecutive, { Content } from '../../../../../shared/ProductContentExecutive'
import Dialog from '../../../components/common/Dialog.vue'
import BaseProductContentDialog from './BaseProductContentDialog.vue'
import SectionTable from './SectionTable.vue'
import Common from '../../../mixins/Common.vue'
import { AV_TYPES } from '@/constants'

type AddedValueItem = {
	id: string
	product: {
		main: string
		type: string
		spName: string
		sku: string
		content_id: string
		product_id: string
	}
	addl: any
	content: Content
}

export default {
	components: { SectionTable, BaseProductContentDialog, Dialog },
	mixins: [Common],
	props: {
		modelValue: Object, // Package
		linkedEntries: Object,
		products: Array,
	},
	data: () => ({
		contentMissing: false,
		globalContent: null, // AddedValueItem
		expanded: [],
		contentStatusMap: null,
		productDetails: null,
	}),
	computed: {
		addedValueProducts() {
			return this.modelValue?.fields?.addedValues?.de ?? []
		},
		addedValueItems(): AddedValueItem[] {
			return this.addedValueProducts.map(({ sys: { id } }, index) => {
				const item = this.linkedEntries[id]
				const linkedProduct = this.products.find(p => p.product_sku === item.fields.sku.de[0])
				// The AVs of type 'fixed' do NOT need to have a product in CH so we do not show them in the table
				if (item.fields.type?.de === AV_TYPES.FIXED) return null
				return {
					id: item.sys.id,
					product: {
						main: item.fields.name[this.serviceLocale] ?? item.fields.name.de ?? '-',
						type: item.fields.type?.de ?? '-',
						spName: item.fields.serviceProviderName?.de?.filter(val => !!val)?.[0] ?? '-',
						sku: item.fields.sku?.de?.[0] ?? '-',
						content_id: linkedProduct?.content_id || ('MYS_PEAK_PRODUCT-' + linkedProduct?.product_id),
						product_id: linkedProduct?.product_id,
					},
				}
			}).filter(item => item !== null)
		},
    hasInclusiveOrOptionalLinkedEntries() {
      return Object.values(this.linkedEntries).some(e => [AV_TYPES.INCLUSIVE, AV_TYPES.OPTIONAL].includes(e.fields?.type?.de))
    }
	},
	watch: {
		modelValue: {
			deep: true,
			handler(n) { this.$emit('update:modelValue', n); this.validateMissingContent() },
		},
	},
	methods: {
		// This server check for the content is still needed when the user adds new AVs since the products array does not contain the newest AV created before reloading the page. Therefore, we only check for the contentId for the AVs that do not have a corresponding product
		async checkMissingContent(skus) {
			try {
				const executive = new ProductContentExecutive({ clientId: this.$store.state.selectedClient.sys.id }, this)
				this.contentStatusMap = await executive.validateAddedValueProductsForSkus(skus)
				// Only set contentMissing to true if there are inclusive or optional linked entries missing content
				this.contentMissing = this.hasInclusiveOrOptionalLinkedEntries && 
					this.addedValueItems.some(item => {
						const statusForSku = this.contentStatusMap[item.product.sku]
						return statusForSku && !statusForSku.contentValid
					})
			}
			catch (error) {
				console.error('Failed to check missing content:', error)
			}
		},
		async editProductContent() {
			console.log('editProductContent', this.modelValue)
			// const { id } = item
			// const entry = this.linkedEntries[id]
			// this.$emit('edit-product-content', entry)
		},
		validateMissingContent() {
			if (!this.modelValue) return
			const skus = this.modelValue.fields.addedValues.de.map(({ sys: { id } }) => {
				const entry = this.linkedEntries[id]
				return entry.fields.sku.de[0]
			})
			this.checkMissingContent(skus)
		},
		async onWarningConfirmed() {
			this.toggleEditGlobalDialog(false, null)
			// TODO: open dialog to edit content
			this.$refs.editContentDialog.open()
			// await this.editProductContent()
			return true
		},
		onWarningCancelled() {
			this.toggleEditGlobalDialog(false, null, true)
		},
		toggleEditGlobalDialog(val, item: AddedValueItem, reset = false) {
			this.$refs.warningDialog.show = val
			// TODO: I did not like this solution but I could not find a better one. The item is not passed to the dialog so we need to store it in a variable to be able to access it in the deleteAgeGroup method
			// Note: we make a deep copy of the item to avoid changing the original item when using the DialogV2 component for editing the content
			if (item) this.globalContent = JSON.parse(JSON.stringify(item))
			if (reset) this.globalContent = null
		},
		handleImageError(index) {
			console.log('Image error', index)
			// show a default image is broken preview
		},
		async getProductDetails(item, expanded) {
			this.loading = true
			if (!expanded) {
				this.loading = false
				return
			}
			// fetch from CH
			const executive = new ProductContentExecutive({ clientId: this.$store.state.selectedClient.sys.id }, this)
			const content = await executive.getProductContent(item.fields.product.content_id)
			console.log('...fetched product content', content)
			if (!content) {
				console.error('No content found for product', item.fields.product.sku)
				return
			}
			// we create a map of product details since multiple products can be expanded at the same time
			this.productDetails = {
				...this.productDetails,
				[item.fields.product.sku]: content
			}
			this.loading = false
		},
		mediaUrl(url) {
			if (!url.startsWith('https:')) return 'https:' + url
		},
	},
	async mounted() {
		await this.validateMissingContent()
	},
}
</script>

<style scoped lang="scss">
.contentMissing { border: 2px solid orange; }
.subtitle { 
	font-size: 13px; color: grey;
	display: inline-block;
	padding-right: 3px;
}
.expandable-section {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 20px;
}

.filled-warning { 
	padding: 5px 5px;
	background-color: #FFB400;
	color: white;
}
.expanded-content {
	display: flex;
	gap: 20px;
	padding-bottom: 20px;
}

.expanded-content-first-column {
	display: flex;
	flex-direction: column;
	gap: 5px;
	width: 70%;
}

.expanded-content-second-column {
	display: flex;
	flex-direction: column;
	gap: 5px;
	width: 30%;
}

.row-text {
	font-size: 15px;
	font-weight: bold;
}

.expanded-content-text {
	font-size: 14px;
	color: #000;
}

.expanded-content-images {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.expanded-content-image {
	border-radius: 10px;
	max-width: 90px;
	max-height: 90px;
	/* border: 1px solid #000; */
}

.more-images-counter {
	font-size: 18px;
	color: #000;
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.overlayed {
	pointer-events: none;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.5);
		z-index: 99;
	}
}

.column {
	display: flex;
	flex-direction: column;
}
</style>