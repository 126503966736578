<template>
	<div class="RadioField" :class="{ error }">
		<div v-for="val of allowedValues" :key="val" class="option">
			<input type="radio" v-model="model" :name="id" :id="id + '_' + val" :value="val" @focus="onFocus" @blur="onBlur" :data-cy="dataCy + '_' + val" :disabled="disabled" />
			<label :for="id + '_' + val">
				{{ $t('text.' + field.id + '-' + val) }}
				<div v-if="$te(`text.${field.id}-${val}Info`)" class="label-info">{{ $t(`text.${field.id}-${val}Info`) }}</div>
			</label>
			<span class="clear" v-if="val === model && !validations.required" @click="model = null" :disabled="disabled">
				Clear
			</span>
		</div>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

export default {
	name: 'RadioField',
	mixins: [ field ],
	props: {
		modelValue: [ Number, String ],
		dataCy: String,
		disabled: Boolean,
	},
	data: () => ({
		id: 'RG-' + Math.random(),
		model: null,
	}),
	computed: {
		allowedValues() {
			return this.field.validations?.[0]?.in ?? []
		},
	},
	watch: {
		modelValue() {
			this.validate()
		},
	},
	methods: {
		validate() {
			this.onErrors([
				this.validateRequired(),
				// TODO: unique
				this.field.type == 'Number' ? this.validateMax() : undefined,
				this.field.type == 'Number' ? this.validateMin() : undefined,
				this.validateIn(),
			])
		},
	},
	mounted() {
		this.model = this.modelValue
		this.validate()
	},
}
</script>

<style scoped>
.RadioField { margin-top: 10px; }
input { cursor: pointer; }
label { font-size: 14px; padding-left: 12px; margin-right: 15px; display: flex; flex-direction: column; cursor: pointer; }
.label-info { font-size: 12px; color: gray; width: fit-content; }
.clear { color: rgb(0, 89, 200); padding-left: 15px; cursor: pointer; }
.option { padding-bottom: 12px; margin-bottom: 12px; border-bottom: 1px solid #eee; max-height: 57px; display: flex; }
.option:last-child { border-bottom: none; }
*[disabled] { color: silver; pointer-events: none; }
</style>
