<template>
	<Application :style="{ 'padding-left': isSideBarFixed ? '315px' : '15px' }">
		<SideBar class="dcm-sidebar" :navigation="sections" :preselectedActiveLink="activeLink"
			@action="handleAction($event)" />

		<template v-if="isContractorsView">
		</template>
		<ContractTemplatesView v-if="isContractsView" />
		<ContractorsView v-if="isContractorsView" ref="contractorsView" />
	</Application>
</template>

<script>
import Common from '@/mixins/Common.vue'
import SideBar from "@/components/common/SideBar.vue"
import Application from '../Application.vue'
import ContractTemplatesView from './ContractTemplatesView.vue'
import ContractorsView from './ContractorsView.vue'

export default {
	name: 'ContractManagementView',
	components: { SideBar, Application, ContractTemplatesView, ContractorsView },
	mixins: [Common],
	data() {
		return {
			sections: [],
			activeLink: ''
		}
	},

	computed: {
		userHasDcmAccess() {
			return this.$store.state.loggedInUser?.fields?.applications?.de?.map(app => app.fields?.link?.de === this.$route.path)
		},
		isContractsView() {
			return this.activeLink === 'contracts'
		},
		isContractorsView() {
			return this.activeLink === 'contractors'
		}
	},
	watch: {
	},
	methods: {
		setSections() {
			this.sections.push({ id: 'contractors', state: 'valid' })
			if (this.userHasDcmAccess) {
				this.sections.push({ id: 'contracts', state: 'valid' })
			}
		},
		handleAction(event) {
			// clear filters for contractors view to start with a clean state
			if (this.activeLink === 'contractors' && event.params !== 'contractors') {
				this.clearContractorsViewFilters()
			}
			
			this[event.function](event.params)
		},
		setActive(key) {
			this.activeLink = key
		},
		clearContractorsViewFilters() {
			// Get a reference to the ContractorsView component instance
			const contractorsViewComponent = this.$refs.contractorsView
			if (contractorsViewComponent) {
				console.log('clearing contractor filters from the store')
				// Clear in store
				this.$store.commit('setContractorsViewFilters', {
					filters: [],
					search: ''
				})
				// Also reset locally in the component
				contractorsViewComponent.resetFilters()
			}
		}
	},
	mounted() {
		this.setSections()
		if (this.$store.state.contractsViewActiveLink) {
			this.setActive(this.$store.state.contractsViewActiveLink)
		}
		else {
			this.setActive('contractors')
		}
	},
	beforeRouteLeave(to, from, next) {
		console.log('ContractManagementView - Route leaving to:', to.path)
		
		// Clear filters when navigating to dashboard
		if (to.path === '/dashboard' || to.path === '/' || to.path.includes('dashboard')) {
			console.log('Navigating to dashboard from ContractManagementView - clearing filters')
			// Clear in store
			this.$store.commit('setContractorsViewFilters', {
				filters: [],
				search: ''
			})
			
			// Also reset locally in the ContractorsView component if it's active
			if (this.isContractorsView && this.$refs.contractorsView) {
				this.$refs.contractorsView.resetFilters()
			}
		}
		next()
	}
}
</script>