<template>
	<v-dialog v-model="isOpen" :scrollable="true" transition="dialog-bottom-transition" :persistent="!closeOnOuterClick">
		<div v-if="isOpen">
			<div class="dialog-div" :style="{ height, width }">
				<loading :active="loading" :is-full-page="true" color="#4caf50" data-cy="loading" />
				<div class="dialog-header" style="display: flex; align-items: center; padding: 22px 32px; border-bottom: 1px solid #ddd;">
					<div style="flex-grow: 1; display: flex; flex-direction: column;" class="dialog-header__title">
						<slot name="title">
							<v-toolbar-title>
								<span>{{ title }}</span>
							</v-toolbar-title>
						</slot>
					</div>

					<div style="flex-grow: 1;" class="d-none d-md-block">
						<StepperV2 v-if="steps?.length" :steps="steps" :modelValue="currentStep" @update:step="updateStep" />
					</div>

					<v-btn v-if="!hideClose" variant="text" size="40" @click="close()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>

				<slot name="header-utility"></slot>
				
				<v-layout row wrap class="scrollable dialog-content">
					<v-col class="content pa-0 overflow-x-auto">

						<div v-if="$slots.sidebar" class="sidebar">
							<slot name="sidebar"></slot>
						</div>
						<div :class="{ 'content-with-sidebar': $slots.sidebar, 'content-wrapper': true }">
							<slot name="content"></slot>
						</div>
					</v-col>
				</v-layout>

				<v-toolbar class="dialog-footer">
					<div
						style="position: absolute; inset: 0; display: flex; gap: 10px; align-items: center; padding: 10px 32px; border-top: 1px solid #ddd;">
						<slot name="buttons-left"></slot>
						<v-spacer/>
						<slot name="buttons"></slot>
						<v-btn class="defaultButton gradientButton" theme="dark" elevation="0" data-cy="cancel"
							@click="handleCancel">
							{{ cancelLabel ?? $t('text.cancel') }}
						</v-btn>
						<v-btn v-if="deleteLabel" class="redButton" theme="dark" elevation="0" data-cy="delete"
							@click="handleRemove">
							<v-icon size="24px" color="white">mdi-delete</v-icon>
							{{ deleteLabel }}
						</v-btn>
						<v-btn :class="isDelete ? 'redButton' : 'greenButton'" theme="dark" elevation="0" data-cy="confirm" style="max-width: 220px;"
							@click="handleConfirm" :disabled="disableConfirmButton">
							{{ confirmLabel ?? $t('text.confirm') }}
						</v-btn>
					</div>
				</v-toolbar>
			</div>
		</div>
	</v-dialog>
</template>

<script>
import Loading from 'vue-loading-overlay'
import StepperV2 from '@/components/stepper/StepperV2.vue'

export default {
	name: 'DialogV2',
	components: { StepperV2, Loading },
	props: {
		title: String,
		confirmLabel: String,
		cancelLabel: String,
		deleteLabel: String,
		onConfirm: Function,
		onCancel: Function,
		onDelete: Function,
		hideBackdrop: Boolean,
		closeOnOuterClick: {
			Boolean,
			default: true,
		},
		hideClose: Boolean,
		disableConfirmButton: Boolean,
		width: { type: String, default: '1100px' },
		height: String,
		isDelete: Boolean,
		showSidebar: Boolean,
		steps: Array,
		currentStep: Number,
		closeOnConfirm: { type: Boolean, default: true },
		closeOnCancel: { type: Boolean, default: true },
		loading: { type: Boolean, default: false },
	},
	data() {
		return {
			isOpen: false,
		}
	},
	watch: {
		isOpen(n) {
			if (!n) this.$emit('close')
		}
	},
	methods: {
		open() {
			this.isOpen = true
		},
		close() {
			this.isOpen = false
		},
		async handleConfirm() {
			await this.onConfirm?.()

			if (!this.closeOnConfirm) return

			this.close()
		},
		handleCancel() {
			this.onCancel?.()

			if (!this.closeOnCancel) return

			this.close()
		},
		handleRemove() {
			this.onDelete?.()
			this.close()
		},
		updateStep(step) {
			this.$emit('update:step', step)
		},
	},
}
</script>

<style scoped lang="scss">
.dialog-div {
	position: fixed;
	display: flex;
	flex-direction: column;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 1100px;
	height: min(700px, calc(100vh - 20px));
	max-width: calc(100vw - 20px);

	background-color: #ffffff;
	border-radius: 5px !important;
	box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
	z-index: 999999 !important;
	overflow: hidden;

	.dialog-header {
		&__title .v-toolbar-title {
			font-weight: 600;
		}
	}

	.dialog-footer {
		.gradientButton {
			color: #000;
			max-width: 190px;
		}

		.greenButton {
			max-width: 190px;
		}

		.redButton {
			max-width: 190px;
		}
	}
}

.scrollable {
	overflow-y: auto;
	overflow-x: hidden;
}

.sidebar {
	position: fixed;
	top: 64px;
	bottom: 64px;
	width: 240px;
	background-color: #f4f4f440;
	border-right: 1px solid rgba(0, 0, 0, 0.1);
	overflow: auto;
}

.content-with-sidebar {
	margin-left: 240px;
}

.content-wrapper {
	padding: 32px;
}

.step-bar {
	border: none;
}
// overwrites the default vuetify dialog overlay so it's NOT transparent
.v-overlay {
	background: rgba(0, 0, 0, 0.5);
}
</style>
