<template>
	<div class="DateField" :class="{ error }">
		<div style="display: flex; gap: 15px;">
			<input type="date" class="input" v-model="date" @focus="onFocus" @blur="onBlur" style="max-width: 200px;" :min="min" :max="max" :data-cy="dataCy" />
			<input type="text" class="input" v-if="hasTime" v-model="time" @focus="onFocus" @blur="onBlur" style="width: 100px;" :data-cy="dataCy" />
			<select class="input" v-if="hasTimezone" v-model="timezone" @focus="onFocus" @blur="onBlur" style="width: auto;" :data-cy="dataCy">
				<!-- TODO: for each TZ -->
				<option value="+00:00">UTC+00:00</option>
				<option value="+01:00">UTC+01:00</option>
				<option value="+02:00">UTC+02:00</option>
				<option value="+03:00">UTC+03:00</option>
			</select>
			<span class="clear" v-if="modelValue !== undefined && modelValue !== null && !validations?.required" @click="$emit('update:modelValue', null)">
				Clear
			</span>
		</div>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

export default {
	name: 'DateField',
	mixins: [ field ],
	props: {
		modelValue: [ Number, String ],
		dataCy: String,
	},
	data: () => ({
		date: '',
		time: '',
		timezone: '',
		model: null,
		autoModel: false,
	}),
	watch: {
		modelValue(n) {
			this.readValue(n)
		},
		date(n) {
			this.validateDateInput(n)
			this.$emit('update:modelValue', this.buildValue(n, this.time, this.timezone))
		},
		time(n) {
			this.$emit('update:modelValue', this.buildValue(this.date, n, this.timezone))
		},
		timezone(n) {
			this.$emit('update:modelValue', this.buildValue(this.date, this.time, n))
		},
		min() {
			this.validate()
		},
		max() {
			this.validate()
		},
	},
	computed: {
		hasTime() {
			return this.field.control?.settings?.format != 'dateonly' ?? true
		},
		hasTimezone() {
			return (this.field.control?.settings?.format != 'dateonly' && this.field.control?.settings?.format != 'time') ?? true
		},
	},
	methods: {
		readValue(n) {
			// TODO: find a better solution - moment.tz?
			//       use a vuetify date picker instead?
			// we receive a zulu time like 2024-04-22T22:00:00.000Z
			// we need to convert it to the browser's timezone
			if (n && n.match(/Z$/)) {
				let date = new Date(n)
				// browser timezone offset
				let offset = new Date().getTimezoneOffset()
				date.setMinutes(date.getMinutes() - offset)
				n = date.toISOString().substring(0, 19)
			}
			// Convert UTC date-time to browser's timezone
//			n = n ? moment(n).tz('Europe/Vienna').format('YYYY-MM-DDTHH:mm:ss.SSS') : ''
			// TODO: validate min, max, etc.
			this.date = n ? n.substring(0, 10) : ''
			this.time = n ? n.substring(11, 16) : ''
			this.timezone = n ? n.substring(16, 32) : ''
			this.validate()
		},
		buildValue(date, time, timezone) {
			let value = date
			if (this.hasTime) value += 'T' + time
			if (this.hasTimezone) value += timezone
			return value
		},
		validateDateInput(dateValue) {
			if (!dateValue) return
			
			// Check min date constraint
			if (this.min && dateValue < this.min) {
				this.date = this.min
			}
			
			// Check max date constraint
			if (this.max && dateValue > this.max) {
				this.date = this.max
			}
		},
		validate() {
			this.onErrors([
				this.validateRequired(),
				this.validateRangeMax(),
				this.validateRangeMin(),
			])
		},
		validateRangeMax() {
			if (!this.max) return
			
			const dateValue = this.date
			if (dateValue && dateValue > this.max) {
				return { id: 'rangeMax', params: { max: this.max } }
			}
		},
		validateRangeMin() {
			if (!this.min) return
			
			const dateValue = this.date
			if (dateValue && dateValue < this.min) {
				return { id: 'rangeMin', params: { min: this.min } }
			}
		},
	},
	mounted() {
		this.readValue(this.modelValue)
	},
}
</script>

<style scoped>
.clear { color: rgb(0, 89, 200); cursor: pointer; padding-top: 10px; }
</style>
