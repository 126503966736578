<template>
	<Dialog ref="noContractNeededDialog"
		data-cy="noContractNeededDialog"
		:deleteLabel="$t('text.confirm')"
		:cancelLabel="$t('text.cancel')"
		:delete-handler="() => $emit('confirm')"
		:title="$t('text.noContractNeeded')"
		height="fit-content"
		width="540px"
		:show-delete-icon="false"
		class="NoContractNeededDialog no-contract-needed-dialog"
	>
		<template #content>
			<div class="no-contract-needed-dialog__wrapper">
				<div class="no-contract-needed-dialog__icon"><v-icon :color="'#FFB400'" :size="'96px'">mdi-alert</v-icon></div>
				<div class="no-contract-needed-dialog__text">
					<v-label class="font-weight-bold">{{ $t('text.markAsNoContractNeeded') }}</v-label>
					<div class="no-contract-needed-dialog__content d-flex justify-center">
						<p v-html="$t('text.markAsNoContractNeededDialogText', { count: contractors.length })" />
					</div>
				</div>
			</div>
		</template>
	</Dialog>
</template>
<script lang="ts">
import Common from '../../mixins/Common.vue'
import Dialog from '../common/Dialog.vue'
export default {
	name: 'NoContractNeededDialog',
	components: { Dialog },
	mixins: [ Common ],
	props: {
		contractors: { type: Array, default: () => [] },
	},
	methods: {
		open() {
			this.$refs.noContractNeededDialog.show = true
		}
	}
}
</script>
<style lang="scss">
.no-contract-needed-dialog {
	&__wrapper {
		display: flex;
		align-items: center;
		padding: 12px 52px
	}

	&__icon {
		margin-right: 48px;
	}

	&__content {
		margin-top: 13px;
		font-size: 17px;
		line-height: 22px;
		letter-spacing: -0.071px;
		color: #8F8F8F;
	}
	&__text {
		> label {
			font-size: 20px;
			font-weight: 600;
			line-height: 25px;
			letter-spacing: -0.196px;
		}
	}
}
</style>