import { createRouter, createWebHistory } from 'vue-router'
import store from '../stores/store' // Adjust path according to your Vuex store location

import Dashboard from '@/views/dashboard/Dashboard.vue'
import Login from '@/views/main/Login.vue'
import ForgotPassword from '@/views/main/ForgotPassword.vue'
import Registration from '@/views/main/Registration.vue'

import ServiceProvidersView from '@/views/applications/serviceProviders/ServiceProvidersView.vue'
import BusinessProfile from '@/views/applications/serviceProviders/BusinessProfile.vue'

import SettingsView from '@/views/applications/settings/SettingsView.vue'
import ProductCategoryView from '@/views/applications/settings/ProductCategoryView.vue'
import ProductCategoryDetailView from '@/views/applications/settings/ProductCategoryDetailView.vue'

import ServicesView from '@/views/applications/serviceDesigner/ServicesView.vue'
import ServiceDesigner from '@/views/applications/serviceDesigner/ServiceDesigner.vue'
import PackagesView from '@/views/applications/packageDesigner/PackagesView.vue'
import PackageDesigner from '@/views/applications/packageDesigner/PackageDesigner.vue'

import FAQListView from '@/views/applications/faqs/FAQListView.vue'
import FAQDetailView from '@/views/applications/faqs/FAQDetailView.vue'

import OrdersView from '@/views/applications/orders/OrdersView.vue'
import OrderDetailView from '@/views/applications/orders/OrderDetailView.vue'

import MediaGallery from '@/views/applications/mediaGallery/MediaGallery.vue'
import TrainingLibraryView from '@/views/applications/trainingLibrary/TrainingLibraryView.vue'
import VideoPlayer from '@/views/applications/trainingLibrary/VideoPlayer.vue'

import MarketplaceView from '@/views/applications/marketplace/MarketplaceView.vue'
import TagManagerView from '@/views/applications/tagManager/TagManagerView.vue'

import MyOrganisationView from '@/views/applications/myOrganisation/MyOrganisationView.vue'

import DeepLink from '@/components/deepLink/DeepLink.vue'
import AppHost from '@/views/applications/appHost/AppHost.vue'
import AppHostStandalone from '@/views/applications/appHost/AppHostStandalone.vue'

import ValidityCheckerView from '@/views/applications/validityChecker/ValidityCheckerView.vue'
import BusinessHoursStandalone from '@/components/businesshours/BusinessHoursStandalone.vue'

import Playground from '@/views/Playground.vue'

import ContractManagementView from "@/views/applications/digitalContractManagement/ContractManagementView.vue";
import ContractTemplateDesigner from "@/views/applications/digitalContractManagement/ContractTemplateDesigner.vue";
import ContractSigningView from "@/views/applications/digitalContractManagement/ContractSigningView.vue";
import ContractorDetailView from "@/views/applications/digitalContractManagement/ContractorDetailView.vue";

// we use meta.root to indicate which application this actually is (see selectedComponent handling in App)
const routes = [
	{ path: "/", component: Login, meta: { requiresAuth: false } },
	{ path: "/auth", component: Login, meta: { requiresAuth: false } },
	{ path: "/forgotPassword", component: ForgotPassword, meta: { requiresAuth: false } },
	{ path: "/registration", component: Registration, meta: { requiresAuth: false } },
	{ path: "/dashboard", component: Dashboard },
	{ path: "/videoPlayer", component: VideoPlayer },
	{ path: "/d/:path(.*)", component: DeepLink, props: true },
	{ path: "/app/:appName",  component: AppHost, props: true },
	{ path: "/app/:appName/:path(.*)", component: AppHost, props: true },
	{ path: "/sa-app/:appName", component: AppHostStandalone, props: route => ({ token: route.query.token }), meta: { requiresAuth: false } },
	{ path: "/sa-app/:appName/:path(.*)", component: AppHostStandalone, props: route => ({ token: route.query.token }), meta: { requiresAuth: false } },
	{ path: "/settings", component: SettingsView, props: route => ({ viewType: route.query.viewType }), meta: { root: '/settingsView' } },
	{ path: "/settingsView", component: SettingsView, props: route => ({ viewType: route.query.viewType }) },
	{ path: "/serviceProviders", component: ServiceProvidersView },
	{ path: "/serviceProviders/:id", component: BusinessProfile, meta: { root: '/serviceProviders' } },
	{ path: "/businessProfile", component: BusinessProfile, meta: { root: '/businessProfile' } },
	{ path: "/services", component: ServicesView },
	{ path: "/services/:id", component: ServiceDesigner, meta: { root: '/services' } },
	{ path: "/service-designer", component: ServiceDesigner, meta: { root: '/services' } },
	{ path: "/packages", component: PackagesView },
	{ path: "/packages/:id", component: PackageDesigner, meta: { root: '/packages' }, props: true },
	{ path: "/faq-list", component: FAQListView },
	{ path: "/faq-detail", component: FAQDetailView },
	{ path: "/orders", component: OrdersView },
	{ path: "/orderDetail", component: OrderDetailView, meta: { root: '/orders' } },
	{ path: "/mediaGallery", component: MediaGallery },
	{ path: "/trainingLibrary", component: TrainingLibraryView },
	{ path: "/marketplace", component: MarketplaceView },
	{ path: "/tagManager", component: TagManagerView },
	{ path: "/myOrg", component: MyOrganisationView },
	{ path: "/validityChecker", component: ValidityCheckerView },
	{ path: "/productCategory", component: ProductCategoryView, meta: { root: '/settingsView' } },
	{ path: "/productCategoryDetail", component: ProductCategoryDetailView, props: route => ({ categoryId: route.query.categoryId }), meta: { root: '/settingsView' } },
	{ path: "/businessHours", component: BusinessHoursStandalone, props: route => ({ token: route.query.token }), meta: { requiresAuth: false } },
	{ path: "/playground", component: Playground },
	{ path: "/contractManagement", component: ContractManagementView, props: true, },
	{ path: "/contractManagement/:id", component: ContractorDetailView, meta: { root: '/contractManagement' }, props: true },
	{ path: "/contractTemplate", name: 'contractTemplate', component: ContractTemplateDesigner, meta: { root: '/contractManagement' }, props: route => ({ id: route.query.id }) },
	{ path: "/contractSigning", component: ContractSigningView, props: route => ({ token: route.query.token }), meta: { requiresAuth: false } },
]

const router = createRouter({
	history: createWebHistory(),
	routes: routes
})

// Add navigation guard which checks if user has permission for the application - this is used to prevent access to applications by manually navigating to the app URL
router.beforeEach(async (to, from, next) => {
	const user = store.state.loggedInUser
	const isImpersonating = store.state.isImpersonation
	const impersonatedServiceProvider = store.state.selectedServiceProvider
	const selectedClient = store.state.selectedClient

	// if no user is logged in, App.vue:create will handle the login
	if (!user?.sys) {
		next()
		return
	}

	// Skip check for routes that don't require auth
	if (to.meta.requiresAuth === false) {
		next()
		return
	}

	// Skip check for dashboard and deep links
	if (to.path === '/dashboard' || to.path.startsWith('/d/')) {
		next()
		return
	}
	
	// Get the root path for permission checking
	let rootPath = to.meta.root || to.path
	if (handleSpecialRoutes(rootPath, user, next)) {
		return
	}

	// Check permissions for operator
	const loggedInUserHasPermission = user?.fields?.applications?.de?.some(app => {
		// Remove query parameters from rootPath for comparison
		const cleanRootPath = rootPath.split('?')[0]
		return cleanRootPath.startsWith(app.fields?.link?.de) || app.fields?.link?.de?.startsWith(cleanRootPath)
	})

	// Check permissions for impersonated service provider
	const impersonatedServiceProviderUserHasPermission = isImpersonating && 
		impersonatedServiceProvider?.fields?.userAccount?.de?.fields?.applications?.de?.some(app => {
			const cleanRootPath = rootPath.split('?')[0]
			return cleanRootPath.startsWith(app.fields?.link?.de) || app.fields?.link?.de?.startsWith(cleanRootPath)
	})

	// Exception: package designer is not an application, but a service type (designer) - we need to check by an API request to /product-service-types/:clientId
	// Special check for package designer
	let hasServiceTypePermission = false
	if (rootPath === '/packages') {
		try {
			const userServiceTypes = isImpersonating ? 
				impersonatedServiceProvider?.fields?.userAccount?.de?.fields?.serviceTypes?.de ?? [] :
				user?.fields?.serviceTypes?.de ?? []
				const response = await fetch(`/api/packageTravel/access?serviceTypes=${userServiceTypes}`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${store.state.loggedInUser?.kc_token}`,
					'Content-Type': 'application/json'
				}
			})
			const { access } = await response.json()
			hasServiceTypePermission = access
			console.log('hasServiceTypePermission ', hasServiceTypePermission)
		} 
		catch (error) {
			console.error('Failed to fetch service types:', error)
		}
	}

	// Allow access if either the operator or impersonated service provider has permission
	if (loggedInUserHasPermission || impersonatedServiceProviderUserHasPermission || hasServiceTypePermission) {
		next()
	}
	else {
		// Redirect to dashboard if no permission
		next('/dashboard')
	}
})

const handleSpecialRoutes = (rootPath, user, next) => {
	if (rootPath === '/serviceProviders' || rootPath === '/businessProfile') {
		const hasServiceProvidersAccess = user?.fields?.applications?.de?.some(app => 
			app.fields?.link?.de === '/serviceProviders'
		)
		const hasBusinessProfileAccess = user?.fields?.applications?.de?.some(app => 
			app.fields?.link?.de === '/businessProfile'
		)
		
		if (hasServiceProvidersAccess || hasBusinessProfileAccess) {
			next()
			return true
		}
	}
	return false
}

export default router
