// This is pure client side code.

import Executive from './rmi-util'

export function proxyClient(o: Executive, container?: any) {
	if (container?.$store) {
		return new Proxy(o, {
			get: function(target, prop: string, receiver) {
				const val = target[prop]
				// TODO: maybe only if the function is decorated with @Rmi?
				if (typeof val === 'function') {
					return async function(...args) {
						return await handleClientRequest(target, prop, args, container)
					}
				}
				return val
			},
			/*
			ownKeys: function(target) {
				console.log('== ownKeys')
				return Object.keys(target)
			},
			*/
			getOwnPropertyDescriptor: function(target, prop) {
				// we remove visibility of isServer
				if (prop == 'isServer') return { configurable: true, enumerable: false }
				return Reflect.getOwnPropertyDescriptor(target, prop)
			},
		})
	}
	else if (container?.el) {
		console.warn(o.constructor.name, ': given container component does not have $store. Not proxying. container:', container)
	}
	else {
		console.warn(o.constructor.name, ': no container given. For RMI to work, you need to pass the container component.')
	}
	return o
}

async function handleClientRequest(target: Executive, prop, originalArgs, container?: any, retry = 0) {
	async function handleRetry() {
		await new Promise(r => setTimeout(r, 3000))
		return await handleClientRequest(target, prop, originalArgs, container, retry + 1)
	}

	const s = container?.$store?.state
	const user = s?.loggedInUser
	const client = s?.selectedClient
	const app = s?.selectedApplication

	let method = 'post'
	const targetClass = target.constructor.name
	// we remove the functions from the args and use it as a callback
	const streamCallback = originalArgs.find(a => typeof a == 'function')
	const args = originalArgs.map(a => typeof a == 'function' ? 'f()' : a)
	const argString = (args?.map(a => ('' + a)
		.replace('[object Object]', 'o')
		.substring(0, 12)
	))?.join(',')
	const endpoint = target.endpoint ?? '/api/RMI'
	const url = endpoint + '?' + targetClass + '.' + prop + '(' + argString + ')'
	const response = await fetch(url, {
		method,
		headers: {
			'Authorization': 'Bearer ' + user?.kc_token,
			'Accept': 'application/json, text/plain, */*',
			'Content-Type': 'application/json',
			'mys-user-id': user?.sys?.id ?? '',
			'mys-user-type': user?.fields?.type?.de ?? '',
			'mys-client-id': client?.sys?.id ?? '',
			'mys-app-id': app?.sys?.id,
			'mys-tx-id': Date.now() + '',
		},
		body: await target.requestToJson({
			targetClass,
//			targetState: null, //target,
			method: prop,
			args,
		}),
	})

	if (response.headers.get('X-Rmi-Mode') == 'stream') {
	//if (response.headers.get('Transfer-Encoding') == 'chunked') {
		if (response.status == 500 && retry < 10) return handleRetry()
		return await readResponseChuncked(target, response, streamCallback)
	}

	if (response.headers.get('Content-Type') == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
		return response.blob()
	}

	const text = await response.text()
	// TODO: may also want to do this on 503 and 502
	// empty response indicates server currently down -> retry
	if (response.status == 500 && text == '' && retry < 10) return handleRetry()
	const result = await target.jsonToResult(text)
	// TODO: can we make properly typed errors happen somehow?
	if (response.status == 400) throw result
	if (response.status == 500 && result.isUnexpectedError) throw result
	return result
}

async function readResponseChuncked(target: Executive, response: Response, callback: Function): Promise<any> {
	const reader = response.body.getReader()
	let partText = ''
	let part
	let r
	while (true) {
		if (!r) r = await reader.read()
		if (r.done && !r.value?.length) break
		// sum up data until it is a valid json object (in case a part is delivered in multiple chunks)
		partText = partText + new TextDecoder().decode(r.value)
		try {
			// we only use the standard parser to check if it is valid JSON
			const test = JSON.parse(partText)
			// and then use the proper parser
			part = await target.jsonToResult(partText)
			partText = ''

			// we already start reading before calling the callback, but just barely:
			// after some time we check if we are done, if not, we call the callback
			r = undefined
			let wasLast
			await Promise.all([
				(async () => {
					r = await reader.read()
					if (r.done) wasLast = true
				})(),
				(async () => {
					await new Promise(r => setTimeout(r, 20))
					if (wasLast) return
					callback(part)
				})(),
			])
		}
		catch (e) {
			// if we encountered a parse error, we continue to read the next chunk
			if (e instanceof SyntaxError) continue
			throw e
		}
	}
	return part
}
