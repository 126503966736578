<template>
	<div>
		<Disclosure :title="$t('text.publicInfoTitle')" :error="sectionMessage.error" :message="sectionMessage.message"
			:lock="!userMayEditFields" :expanded="true" data-cy="publicInfo" ref="SECTION_publicInfo"
		>
			<!-- Would this be part of field.validations? -->
			<!-- :error-messages="productTitleErrors" -->
			<Field v-if="hasField('publicInfo', 'title', product)" typeName="Service" fieldName="title" v-model="product.fields.title"
				:disabled="productIsExternal"
				@change="() => onChange('title')"
			/>

			<Field v-if="hasField('publicInfo','longDescription', product)" typeName="Service" fieldName="longDescription" v-model="product.fields.longDescription"
				:disabled="!userMayEditFields"
				:errorMessages="longDescriptionErrors"
				@change="() => onChange()"
			/>

			<!-- Need to add/remove required label on condition! Can this be handled by API? -->
			<!-- :required="!productIsExternal" -->
			<Field v-if="hasField('publicInfo','shortDescription', product)" typeName="Service" fieldName="shortDescription" v-model="product.fields.shortDescription"
				:disabled="!userMayEditFields" :errorMessages="shortDescriptionErrors"
				@change="() => onChange()"
			/>

			<Field v-if="hasField('publicInfo','importantInfo', product)" typeName="Service" fieldName="importantInformation" v-model="product.fields.importantInformation"
				:disabled="!userMayEditFields"
				@change="() => onChange()"
			/>
		</Disclosure>
	</div>
</template>

<script lang='ts'>
import ImportantInfo from './ImportantInfo.vue'
import TextArea from '@/components/common/TextArea.vue'
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import Field from '@/components/fields/Field.vue'

type PublicInfo = {
	title: string,
	longDescription: string,
	shortDescription: string,
	importantInformation: string,
}

export default {
	components: { ImportantInfo, TextArea, Disclosure, Field },
	mixins: [ Common ],
	props: {
		product: {
			type: Object as () => { fields: PublicInfo },
			required: true
		},
		productIsExternal: Boolean,
	},
	data: () => ({
		sectionMessage: { error: false, message: '' },
		productTitleErrors: [] as string[],
		longDescriptionErrors: [] as string[],
		shortDescriptionErrors: [] as string[],
		initData: {} as PublicInfo,
	}),
	watch: {
		longDescriptionErrors(n) {
			if (n.length) {
				this.setSectionError(this.sectionMessage, this.$t('text.longDescriptionRequired'))
			}
		},
		shortDescriptionErrors(n) {
			if (!this.productIsExternal && n.length) {
				this.setSectionError(this.sectionMessage, this.$t('text.shortDescriptionRequired'))
			}
		},
		productTitleErrors(n) {
			if (n.length) {
				this.setSectionError(this.sectionMessage, this.$t('text.titleRequiredError'))
			}
		},
	},
	methods: {
		validateAllFields() {
			let allFieldsAreValid = true
			this.resetSectionError(this.sectionMessage)

			const isTitleValid = this.validateTitle(true)
			const isLongDescriptionValid = this.validateLongDescription()
			const isShortDescriptionValid = this.validateShortDescription()

			if (!isTitleValid || !isLongDescriptionValid || !isShortDescriptionValid) {
				allFieldsAreValid = false
				this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
			}

			return allFieldsAreValid
		},
		validateTitle(runValidation = false) {
			let isValid = true
			const atLeastOneLocaleValueIsSet = this.validateTextLocales(this.product.fields.title)
			this.productTitleErrors = []

			if (runValidation && !atLeastOneLocaleValueIsSet) {
				isValid = false
				this.productTitleErrors.push(this.$t('text.nameRequired'))
			}
			else if (!atLeastOneLocaleValueIsSet) {
				isValid = false
				this.setSectionError(this.sectionMessage, this.$t('text.titleRequiredError'))
			}

			if (isValid === true && this.sectionMessage.message === this.$t('text.titleRequiredError')) {
				this.resetSectionError(this.sectionMessage)
			}
			this.$emit('update-product-title', this.product.fields.title)
			return isValid
		},
		validateLongDescription() {
			let isValid = true
			const atLeastOneLocaleValueIsSet = this.validateTextLocales(this.product.fields.longDescription)
			this.longDescriptionErrors = []

			if (!atLeastOneLocaleValueIsSet) {
				isValid = false
				this.longDescriptionErrors.push(this.$t('text.longDescriptionRequired'))
			}

			return isValid
		},
		validateShortDescription() {
			let isValid = true
			const atLeastOneLocaleValueIsSet = this.validateTextLocales(this.product.fields.shortDescription)
			this.shortDescriptionErrors = []

			if (!this.productIsExternal && !atLeastOneLocaleValueIsSet) {
				isValid = false
				this.shortDescriptionErrors.push(this.$t('text.shortDescriptionRequired'))
			}

			return isValid
		},
		onChange(field?: string): void {
			let data = {
				title: this.product?.fields.title,
				longDescription: this.product?.fields.longDescription,
				shortDescription: this.product?.fields.shortDescription,
				importantInformation: this.product?.fields.importantInformation,
			} as PublicInfo

			const changed = JSON.stringify(data) !== JSON.stringify(this.initData)

			this.$emit('changed', changed)
			if (!field) return

			if (field === 'title') {
				const titleChanged = JSON.stringify(this.product?.fields.title) !== JSON.stringify(this.initData.title)
				this.$emit('changeTitle', titleChanged)
			}
		},
		setInitData(): void {
			const initModel = JSON.parse(JSON.stringify(this.product))
			this.initData.title = initModel.fields.title
			this.initData.longDescription = initModel.fields.longDescription
			this.initData.shortDescription = initModel.fields.shortDescription
			this.initData.importantInformation = initModel.fields.importantInformation
		},
	},
	created() {
		this.setInitData()
	},
}
</script>
