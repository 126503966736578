<template>
	<Dialog :title="$t('text.mediaViewer')" ref="dialog" width="1200px">
		<template #content>
			<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
			<loading v-model:active="isLoading" :is-full-page="true" color="#4caf50" data-cy="loading" />

			<div style="display: flex; position: absolute; inset: 0;">
				<!-- Sidebar -->
				<div class="mediaSidebar" style="width: 250px;" v-if="!isUpdate">
					<ul id="mediaNavlist">
						<a href="#">
							<li @click="setActive('gallery')" :class="{'active': activeLink === 'gallery'}"
								class="pl-5 pr-5" data-cy="gallery">
								<v-icon :color="activeLink === 'gallery' ? '#ffffff' : '#000000'"
									class="mr-2">mdi-image-multiple-outline</v-icon>
								<span>{{ $t('text.mediaGallery') }}</span>
							</li>
						</a>

						<a href="#">
							<li @click="setActive('myComputer')" :class="{'active': activeLink === 'myComputer'}"
								class="pl-5 pr-5" data-cy="myComputer">
								<v-icon :color="activeLink === 'myComputer' ? '#ffffff' : '#000000'"
									class="mr-2">mdi-laptop</v-icon>
								<span>{{ $t('text.myComputer') }}</span>
							</li>
							<Translations v-if="activeLink === 'myComputer' || showEditor"
								:title="$t('text.translations')" :translations="translations" :expanded="true"
								@switch-locale="switchLocale" variant="secondary" />
						</a>

						<a href="#">
							<li @click="setActive('viaURL')" :class="{'active': activeLink === 'viaURL'}"
								class="pl-5 pr-5" data-cy="viaURL">
								<v-icon :color="activeLink === 'viaURL' ? '#ffffff' : '#000000'"
									class="mr-2">mdi-link-variant</v-icon>
								<span>{{ $t('text.viaURL') }}</span>
							</li>
							<Translations v-if="activeLink === 'viaURL' && fileLoaded" :title="$t('text.translations')"
								:translations="translations" :expanded="true" @switch-locale="switchLocale"
								variant="secondary" />
						</a>
					</ul>
				</div>

				<!-- Imitate Sidebar on image update, but without any list elements-->
				<div class="mediaSidebarDummy" style="width: 250px;" v-else>
					<ul id="mediaNavlist">
						<Translations :title="$t('text.translations')" :translations="translations" :expanded="true"
							@switch-locale="switchLocale" />
					</ul>
				</div>

				<div style="width: 100%; overflow-y: scroll;">
					<!-- Content -->
					<div style="display: flex; gap: 15px; flex-wrap: wrap; padding: 15px" v-if="isView">
						<MediaCard v-for="media in providerMedia" :key="media.sys.id" :media="media" :canSelect="true"
							:canEdit="true" :showTitle="true" @checkbox-change="setSelectedMedia"
							@show-media-update="showUpdate" style="width: 230px; height: 230px;" />
					</div>

					<!-- Update Media -->
					<div v-if="isUpdate">
						<div style="display: flex; flex-direction: column;" class="pa-5 mr-1">
							<div class="field left-border">
								<v-label>
									{{ $t('text.copyrightOwner') }}
									<span class="error-text">({{ $t('text.required') }})</span>
								</v-label>
								<v-text-field variant="outlined" density="compact" id="copyrightOwner" autocomplete="off" counter="256" maxLength="256" v-model="copyrightOwner" />
							</div>

							<div class="field left-border">
								<v-checkbox id="usageConsent" v-model="usageConsent" :label="$t('text.usageConsent')" :error-messages="usageConsentError" />
								<span class="error-text">({{ $t('text.required') }})</span>
							</div>

							<div class="pa-3">
								<MediaItem ref="updateMediaItemRef" 
									:fixIndexes="false"
									:mediaAsset="selectedMediaAsset"
									:isURLUpload="isURLUpload"
									@remove-media-file="onFileRemoved"
									@new-url="urlToLink = $event"
								/>
							</div>
						</div>
					</div>

					<!-- Upload Image -->
					<div v-if="activeLink == 'myComputer'" style="padding: 15px;">
						<div :class="isPoorQuality ? 'field left-border-warning' : 'field left-border'">
							<v-label>
								{{$t('text.chooseFile')}}
								<span class="error-text">({{$t('text.required')}})</span>
							</v-label>
							<FileUpload
								ref="dropzone"
								:options="dropOptions"
								data-cy="dropzone"
								@thumbnail-created="onThumbnailCreated"
								@file-removed="onFileRemoved" />
							<div v-if="isPoorQuality">
								<p class="warning-text">{{$t('text.poorQualityWarning')}}</p>
							</div>
						</div>

						<div class="field left-border">
							<v-label>
								{{$t('text.copyrightOwner')}}
								<span class="error-text">({{$t('text.required')}})</span>
							</v-label>
							<v-text-field variant="outlined" density="compact" id="copyrightOwner" autocomplete="off" counter="256" maxLength="256" v-model="copyrightOwner" />
						</div>

						<div class="field left-border">
							<v-checkbox id="usageConsent" hide-details
								v-model="usageConsent"
								:label="$t('text.usageConsent')"
								:error-messages="usageConsentError"
								color="blue"
							/>
							<span class="error-text">({{$t('text.required')}})</span>
						</div>

						<div v-if="showEditor">
							<br />
							<MediaItem ref="mediaItem"
								:fixIndexes="false"
								:isURLUpload="isURLUpload"
								:mediaAsset="mediaAsset"
								@remove-media-file="onFileRemoved"
								@new-url="urlToLink = $event">
							</MediaItem>
						</div>

					</div>

					<!-- URL Link -->
					<div v-if="isURLUpload" class="container-grid" style="padding: 15px;">
						<div class="field left-border">
							<v-label>URL <span class="error-text">({{$t('text.required')}})</span></v-label>
							<v-text-field id="urlToLink"
								v-model="urlToLink"
								:disabled="protectURL"
								autocomplete="off"
								variant="outlined"
								density="compact"
								required
							/>
						</div>

						<div class="field left-border">
							<v-label>{{$t('text.copyrightOwner')}} <span
									class="error-text">({{$t('text.required')}})</span></v-label>
							<v-text-field id="copyrightOwner"
								v-model="copyrightOwner"
								variant="outlined"
								density="compact"
								autocomplete="off"
								counter="256"
								maxLength="256"
							/>
						</div>

						<div class="field left-border">
							<v-checkbox id="usageConsent"
								v-model="usageConsent"
								:label="$t('text.usageConsent')"
								:error-messages="usageConsentError"
							/>
							<span class="error-text">({{$t('text.required')}})</span>
						</div>

						<div v-if="fileLoaded">
							<br />
							<MediaItem ref="mediaItem2"
								:fixIndexes="false"
								:mediaAsset="mediaAsset"
								:isURLUpload="isURLUpload"
								@remove-media-file="onFileRemoved"
								@new-url="urlToLink = $event">
							</MediaItem>
						</div>
					</div>
				</div>
			</div>

		</template>
		<template #buttons>
			<v-btn class="gradientButton" theme="dark" elevation="0" v-if="!isUpdate" @click="cancel()" data-cy="media-cancel">
				{{ $t('text.cancel') }}
			</v-btn>

			<v-btn class="greenButton" theme="dark" elevation="0" v-if="showConfirm && !isUpdate" @click="addMedia()" data-cy="media-confirm">
				{{ addLabel }}
			</v-btn>

			<template v-if="!isUpdate">
				<v-btn
					v-if="this.isComputerUpload || (this.isURLUpload && this.fileLoaded)"
					:disabled="!isEnabledUpload"
					class="greenButton"
					elevation="0"
					theme="dark"
					style="height:40px !important"
					@click="uploadMedia()" data-cy="uploadFile"
				>
					<v-icon color="#ffffff">mdi-arrow-up-circle</v-icon>
					{{$t('text.uploadFile')}}
				</v-btn>

				<v-btn
					v-if="isURLUpload && !fileLoaded"
					class="greenButton"
					elevation="0"
					theme="dark"
					style="height:40px !important"
					data-cy="uploadFile"
					@click="loadFile()"
				>
					<v-icon color="#ffffff">mdi-arrow-down-circle</v-icon>
					{{$t('text.loadFile')}}
				</v-btn>
			</template>

			<template v-else>
				<v-btn class="gradientButton" theme="dark" elevation="0" @click="discardMedia()" data-cy="cancel">{{$t('text.cancel') }}</v-btn>
				<v-btn class="greenButton" theme="dark" elevation="0" @click="updateMedia()" data-cy="confirm">{{$t('text.confirm') }}</v-btn>
			</template>
		</template>
	</Dialog>
</template>

<script>
import Loading from 'vue-loading-overlay'
import MediaCard from './MediaCard.vue'
import Alert from '@/components/common/Alert.vue'
import FileUpload from "@/components/media/FileUpload.vue"
import Common from '@/mixins/Common.vue'
import MediaItem from '@/views/applications/mediaGallery/MediaItem.vue'
import Dialog from '@/components/common/Dialog.vue'
import Translations from '@/components/common/Translations.vue'

export default {
	name: 'MediaViewer',
	components: {FileUpload, Loading, MediaCard, Alert, MediaItem, Dialog, Translations },
	mixins: [ Common ],
	props: {
		showBackdrop: { type: Boolean, default: true },
	},
	data() { return {
		activeLink: '',
		confirmLabel: '',
		showConfirm: false,

		isLoading: false,

		serviceProvider: {},
		media: [],
		fileToUpload: {},

		mediaAsset: {
			fields: {
				title: {},
				altText: {},
				description: {},
				copyright: '',
			},
		},

		selectedFiles: [],
		usageConsent: false,
		usageConsentError : '',
		errorTitle: '',
		errorDetail: '',

		providerMedia: [],

		isView: true,
		isComputerUpload: false,
		isURLUpload: false,
		uploadDisabled: true,
		selectedMedia: [],
		urlToLink: '',

		addLabel: this.$t('text.addImages'),

		host: '',

		dropOptions: {
			acceptedFiles: '.jpg, .jpeg',
			addRemoveLinks: true,
			maxFilesize: 10,
			maxFiles: 1,
		},
		showEditor: false,
		isPoorQuality: false,
		fileLoaded: false,
		protectURL: false,
		isUpdate: false,
		selectedMediaAsset: {},
		isEditShortcut: false,

		copyrightOwner: '',
		usageConsent: false,
		
		lowQualityFilesNames: [],
	}},
	computed: {
		translations() {
			return this.getTranslationStatus('media', this.isUpdate ? this.selectedMediaAsset : this.mediaAsset)
		},
		isEnabledUpload() {
			let isValid = true
			if (!this.copyrightOwner.length > 0 || !this.usageConsent) {
				isValid = false
			}

			if (!this.mediaAsset.fields.file && this.urlToLink.length === 0) {
				isValid = false
			}

			// New Media Validation
			if (Object.keys(this.mediaAsset.fields.title).length === 0 || !this.mediaAsset?.fields?.title?.[this.serviceLocale]?.length) {
				isValid = false
			}

			return isValid
		},
	},
	methods: {
		switchLocale(locale) {
			this.serviceLocale = locale
		},
		showUpdate(media) {
			this.isView = false
			this.isUpdate = true
			this.selectedMediaAsset = media
			this.selectedMediaAsset.fields["altText"] = this.selectedMediaAsset.fields.altText ? this.selectedMediaAsset.fields.altText : { de: '' }
			this.selectedMediaAsset.fields["description"] = this.selectedMediaAsset.fields.description ? this.selectedMediaAsset.fields.description : { de: '' }

			this.copyrightOwner = media.fields.copyright?.[this.defaultLocale.code] || ''
			this.usageConsent = media.fields.usageConsent?.[this.defaultLocale.code] || false

			this.copyrightError = ''
			this.copyrightOwnerError = ''

		},
		checkImageQuality(isRemoval) {
			this.isPoorQuality = false
			if (this.mediaAsset.fields.file.width < 300 || this.mediaAsset.fields.file.height < 300) {
				this.mediaAsset.fields.isPoorQuality = { de: true }
				this.isPoorQuality = true
			}
		},
		async loadFile() {
			// check if url is valid
			this.isLoading = true
			this.protectURL = true
			await this.getMeta(`${this.urlToLink}`).then(img => {
				this.mediaAsset = {
					sys: {
						id: this.urlToLink
					},
					fields: {
						title: {},
						altText: {},
						description: {},
						copyright: '',
						file: {
							dataURL: this.urlToLink,
							width: img.naturalWidth,
							height: img.naturalHeight,
							upload: {
								filename: 'Upload from URL',
							}
						},
						isURLUpload: true
					}
				}

				// TODO: check why mediaAsset is not defined in checkImageQuality
				this.checkImageQuality(false)
				this.fileLoaded = true
			})
				.catch(err => {
					this.protectURL = false
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = this.$t('text.invalidURL')
				});
			this.isLoading = false
		},
		onThumbnailCreated(file) {
			this.mediaAsset.fields.file = file
			this.showEditor = true
			this.checkImageQuality(false)
		},

		onFileRemoved(removedFile) {
			// don't remove the added file if it's different to the one
			// already saved - there is a limit of 1 file to be added
			if (removedFile && removedFile !== this.mediaAsset.fields.file) {
				return
			}
			this.mediaAsset = {
				fields: {
					title: {},
					altText: {},
					description: {},
					copyright: ''
				}
			}
			this.showEditor = false
			this.isPoorQuality = false
			this.resetURLRelatedData()
		},

		setActive(key) {
			this.activeLink = key
			this.files = []
			this.usageConsentError = ""

			this.copyrightOwner = ''
			this.usageConsent = false

			this.resetURLRelatedData()

			this.showEditor = false
			this.isPoorQuality = false

			this.isUpdate = false

			this.mediaAsset = {
				fields: {
					title: {},
					altText: {},
					description: {},
					copyright: '',
				}
			}

			if (key === 'myComputer') {
				this.isComputerUpload = true;
				this.isView = false;
				this.isURLUpload = false;
				this.confirmLabel = this.$t('text.uploadFile')
			} else if (key === "viaURL") {
				this.isURLUpload = true;
				this.isView = false;
				this.isComputerUpload = false;
			} else {
				this.isView = true;
				this.isURLUpload = false;
				this.isComputerUpload = false;
			}
		},

		setSelectedMedia(media) {
			if (media.checked) {
				// MYS-3851: only push the media if the id is not already in the array
				this.selectedMedia.forEach((item) => {
					if (item.sys.id === media.sys.id) {
						return
					}
				})
				this.selectedMedia.push(media);
			} else {
				for (let i = 0; i < this.selectedMedia.length; i++) {
					if (this.selectedMedia[i].sys.id === media.sys.id) {
						this.selectedMedia.splice(i, 1);
						break;
					}
				}
			}

			this.addLabel = this.$t('text.addImages').replace("%n%", this.selectedMedia.length)
			this.showConfirm = this.selectedMedia.length > 0;
		},

		clearSelectedMedia() {
			this.selectedMedia = []
			this.showConfirm = false

			this.activeLink = 'gallery'
			this.isView = true
			this.isURLUpload = false
			this.isComputerUpload = false
			this.usageConsent = false
			this.urlToLink = ''
			this.errorTitle = ''
			this.errorDetail = ''

			for (let media of this.providerMedia) {
				delete media.checked
			}

			this.mediaAsset = {
				fields: {
					title: {},
					altText: {},
					description: {},
					copyright: ''
				}
			}
		},

		addMedia() {
			this.$emit("add-media", this.selectedMedia)
		},

		async getMedia(isUpdateShortcut = false) {
			this.isLoading = true

			try {
				const res = await this.$httpGet(`/media/${this.$store.state.selectedServiceProvider.sys.id}`)

				this.providerMedia = res?.media
				this.setActive("gallery")

				this.isComputerUpload = false
				this.isURLUpload = false
				this.isView = true

				this.isLoading = false

				if (isUpdateShortcut) {
					this.showUpdate(this.selectedMediaAsset)
				}

			} catch (error) {
				this.showError(error)
			}
		},

		async uploadMedia() {
			const isValid = this.validate()

			if (isValid) {
				this.isLoading = true;

				this.mediaAsset.fields.title.de = !this.mediaAsset.fields.title.de ? this.mediaAsset.fields.title[this.selectedLocale] : this.mediaAsset.fields.title.de
				this.mediaAsset.fields.altText.de = !this.mediaAsset.fields.altText.de ? this.mediaAsset.fields.altText[this.selectedLocale] : this.mediaAsset.fields.altText.de
				this.mediaAsset.fields.description.de = !this.mediaAsset.fields.description.de ? this.mediaAsset.fields.description[this.selectedLocale] : this.mediaAsset.fields.description.de

				var data = {
					mediaAssets: [this.mediaAsset],
					url: this.urlToLink,
					serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
					serviceProviderName: this.$store.state.selectedServiceProvider.fields.title.de,
					copyrightOwner: this.copyrightOwner,
					usageConsent: this.usageConsent
				}

				try {
					const res = await this.$httpPost('/media', data)

					this.copyrightOwner = ""
					this.usageConsent = false
					this.copyrightError = ""
					this.copyrightOwnerError = ''
					this.resetURLRelatedData()
					this.mediaAsset = {
						fields: {
							title: {},
							altText: {},
							description: {},
							copyright: ''
						}
					}

					this.getMedia()

				} catch (error) {
					this.isLoading = false;

					if (error.response && error.response.status === 401) {
						this.$emit("show-login")
					}

					this.errorTitle = this.$t('text.ERROR');
					this.errorDetail = this.$t('text.uploadError');
				}
			}
		},
		async updateMedia() {
			const isValid = this.validate(true)


			if (isValid) {
				this.selectedMediaAsset.fields.title[this.serviceLocale] = this.$refs.updateMediaItemRef.mediaAsset.fields.title[this.serviceLocale]
				this.selectedMediaAsset.fields.altText[this.serviceLocale] = this.$refs.updateMediaItemRef.mediaAsset.fields.altText[this.serviceLocale]
				this.selectedMediaAsset.fields.description[this.serviceLocale] = this.$refs.updateMediaItemRef.mediaAsset.fields.description[this.serviceLocale]

				this.isLoading = true
				var data = {
					mediaAsset: this.selectedMediaAsset,
					imageData: this.$refs.updateMediaItemRef.imgData,
					serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
					copyrightOwner: this.copyrightOwner,
					usageConsent: this.usageConsent
				}

				const res = await this.$httpPut('/media', data)
				// TODO Do we need a try catch here to hide loader if req fails?
				this.getMedia()
			}
		},
		discardMedia() {
			if (this.isEditShortcut) {
				this.isEditShortcut = false
				this.closeDialog()
			}
			this.selectedMediaAsset = {}
			this.setActive("gallery")
		},
		validate(isUpdate = false) {
			let isValid = true
			if (!isUpdate) {

				if (!this.copyrightOwner.length > 0 || !this.usageConsent) {
					isValid = false
				}

				if (!this.mediaAsset.fields.file && this.urlToLink.length === 0) {
					isValid = false
				}

				//New Media Validation
				if (Object.keys(this.mediaAsset.fields.title).length === 0) {
					isValid = false
				}

				this.copyrightError = this.usageConsent === false ? this.$t('text.copyrightError') : ''
				this.copyrightOwnerError = this.mediaAsset.fields.copyright.length === 0 ? ' ' : ''
			} else {
				//Update Media Validation
				if (this.$refs.updateMediaItemRef.mediaAsset.fields.title[this.serviceLocale].length === 0) {
					isValid = false
				}

				if (this.copyrightOwner.length === 0 || !this.usageConsent) {
					isValid = false
				}

				if (!isValid) {
					this.errorTitle = this.$t('text.ERROR');
					this.errorDetail = this.$t('text.requiredFields');
				}
			}

			return isValid
		},

		confirm() {
			if (this.confirmHandler && typeof this.confirmHandler === 'function') {
				this.confirmHandler();
			}
			this.closeDialog();
		},

		cancel() {
			if (this.cancelHandler && typeof this.cancelHandler === 'function') {
				this.cancelHandler();
			}
			this.closeDialog();
		},

		remove() {
			if (this.deleteHandler && typeof this.deleteHandler === 'function') {
				this.deleteHandler();
			}
			this.closeDialog();
		},

		isOpen() {
			return this.$refs.dialog.show
		},
		async open() {
			if (this.$store.state.selectedServiceProvider?.sys && this.providerMedia?.length === 0 && !this.isEditShortcut) {
				await this.getMedia()
				if (!this.providerMedia?.length) {
					this.setActive('myComputer')
				}
			}
			else {
				this.clearSelectedMedia()
			}

			this.$refs.dialog.show = true
		},
		close() {
			this.$refs.dialog.show = false
		},

		closeDialog() {
			this.showEditor = false;
			this.close()
		},

		backdropClick() {
			if (this.closeOnOuterClick) {
				this.closeDialog();
			}
		},
		fixZindexes(n) {
			// raise/restore the whole parent stack's z-index to avoid overlaying siblings
			for (let el = this.$parent; el; el = el.$parent) {
				if (!el?.$el) continue
				if (n) {
					el.$el.style['z-index-backedup'] = true
					el.$el.style['z-index-backup'] = el.$el.style['z-index']
					el.$el.style['z-index'] = 999
				}
				else {
					if (el.$el.style?.['z-index-backedup'])
						el.$el.style['z-index'] = el.$el.style['z-index-backup']
				}
			}
		},
		async getMeta(url) {
			const img = new Image();
			img.src = url;
			await img.decode();
			return img
		},
		resetURLRelatedData() {
			this.urlToLink = ''
			this.fileLoaded = false
			this.protectURL = false
			this.isPoorQuality = false
		},
	},
	created() {
		this.imgData = this.mediaAsset.fields.media?.[this.serviceLocale]?.fields?.file?.[this.serviceLocale]?.url ?? ''
	},
	async mounted() {
		this.isView = true
		this.setActive('gallery')
	},
	beforeUnmount() {
		this.fixZindexes(false)
	},
}
</script>
<style scoped lang="scss">
	a {
		text-decoration: none;
	}

	.mediaSidebar {
		background-color: #f4f4f4;
		border-right: 1px solid #f4f4f4;
		height: 100% !important;
		padding: 0px;
		background-color: #f4f4f4 !important;
		border-right: 1px solid #dbdbdb !important;
	}

	.mediaSidebarDummy {
		height: 100% !important;
		padding: 0px;
		border-right: 1px solid #dbdbdb !important;
	}

	#mediaNavlist {
		padding: 0;
	}

	#mediaNavlist li {
		list-style-type: none;
		width: 100%;
		height: 64px;
		padding-right: 10px;

		font-size: 17px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: -0.25px;
		font-weight: 500;

		display: flex;
		align-items: center;
		border-bottom: 1px solid #dbdbdb !important;
	}

	#mediaNavlist a {
		color: #000000;
	}

	#mediaNavlist .active {
		border-top: 1px solid #00aeef;
		border-bottom: 1px solid #00aeef;
		background-color: #00aeef;
		color: #ffffff !important;
	}

	.scrollable {
		overflow-y: auto;
		overflow-x: hidden;
	}

	.warning-text {
		padding-top: 5px;
		position: relative;
		z-index: 1;
		color: #ffb400 !important;
		font-size: 12pt !important;
	}

	.field.left-border-warning {
		left: 0;
		top: 15px;
		bottom: 0px;
		width: 100%;
		border-left: 3px solid #ffb400 !important;
	}

	.dialogText {
		width: 268px;
		height: 110px;
		margin: 8px 0 0;
		font-family: Inter;
		font-size: 17px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: -0.42px;
		color: #9da0a5;
	}

	.warningDialogImage {
		width: 96px;
		height: 96px;
		// margin: 24px 48px 23px 0;
		object-fit: contain;
	}

	.warning-icon-col {
		flex: 1;
		text-align: center;
	}

	.text-col {
		flex: 2;
		text-align: center;

		width: 268px;
		height: 110px;
		margin: 8px 0 0;
		font-family: Inter;
		font-size: 17px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: -0.42px;
		color: #9da0a5;

		text-align: left;
	}
</style>
