<template>
	<div class="InlineEntryArrayField"
		style="margin-left: -19px; border-top: 4px solid white;"
	>
		<draggable class="entries"
			v-model="model"
			@start="drag = true"
			@end="drag = false"
			handle=".handle"
			:data-cy="dataCy"
		>
			<div v-for="(entry, e) of model" :key="e" class="entry"
				:class="{ collapsed: collapsed[entry.sys.id] }"
			>
				<v-icon class="handle" v-if="hasOrdering">mdi-drag-vertical</v-icon>
				<v-icon v-if="!collapsed[entry.sys.id]" @click="collapsed[entry.sys.id] = true">mdi-chevron-up</v-icon>
				<v-icon v-if="collapsed[entry.sys.id]" @click="collapsed[entry.sys.id] = false">mdi-chevron-down</v-icon>
				<div style="flex-grow: 1;">
					<InlineEntryEditor v-if="!collapsed[entry.sys.id]"
						:modelValue="entry"
						:locale="locale"
						:locales="locales"
						:defaultType="addedType"
					/>
					<!-- TODO: find displayField for this type and take that -->
					<div v-else style="padding-top: 10px;">{{ entry.fields.question[locale] }}</div>
				</div>
				<v-icon class="delete" @click="model.splice(e, 1)">mdi-delete</v-icon>
			</div>
		</draggable>
		<div class="add">
			<!-- TODO: menu in case we have multiple allowed types -->
			<button @click="add(addedType)">
				<v-icon>mdi-plus</v-icon>
				Add {{ addedType }}
			</button>
		</div>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'
import { VueDraggableNext as draggable } from 'vue-draggable-next'
import InlineEntryEditor from './InlineEntryEditor.vue'

// TODO: on sub-objects we typically do NOT have per-locale objects,
//       but rather the sub objects will be translated!
//       this probably has to be adressed in Field.vue

export default {
	name: 'InlineEntryArrayField',
	mixins: [ field ],
	components: { draggable, InlineEntryEditor },
	props: {
		modelValue: Array,
		type: String,
		locale: String,
		dataCy: String,
	},
	data: () => ({
		model: null,
		drag: false,
		collapsed: {},
	}),
	computed: {
		addedType() {
			if (this.type == 'Object')
				return this.field.items?.linkContentType?.[0]
			return this.validations?.linkContentType?.[0]
		},
		hasOrdering() {
			return !this.field?.control?.settings?.noOrdering
		},
	},
	methods: {
		add(type) {
			const o = {
				sys: { id: 'id_' + Math.floor(Math.random() * 9999),
				contentType: { sys: { id: type } } },
				fields: {},
			}
			if (type == 'info') {
				o.fields.infoTypeId = null
				o.fields.description = { [this.locale]: '' }
			}
			// TODO: find the model definition for type
			// TODO: for each field: init {}
			// TODO: at least conditional on FAQ, ideally delegate
			else {
				o.fields.question = {}
				o.fields.answer = {} 
			}
			if (!this.model) this.model = []
			this.model.push(o)
		},
	},
	mounted() {
		this.model = this.modelValue
	},
}
</script>

<style scoped>
.InlineEntryArrayField { border-radius: 5px; overflow: hidden; }
.entry { display: flex; align-items: start; gap: 10px; background-color: #f4f4f4; border-bottom: 1px solid #ddd; }
.entry .v-icon { margin-top: 10px; margin-bottom: 10px; }
.handle { margin-left: 5px; }
.delete { margin-right: 10px; }
.add { padding: 10px; border: 1px solid #ddd; background: white; border-top: 0; border-radius: 0 0 6px 6px; }
.add button { border: 2.5px dashed #aaa; border-radius: 3px; padding: 5px 10px; width: 100%; }
</style>
<style>
.InlineEntryArrayField .entry:first-child tr:first-child td { border-top: 1px solid #ddd; }
</style>