<template>
	<div class="NumberField" :class="{ error }">
		<button v-if="variant == 'PLUSMINUS'" @click="decrement"
			:disabled="validations?.range?.min !== undefined && model <= validations?.range?.min"
		><v-icon>mdi-minus</v-icon>
		</button>
		<div class="wrap"
			:style="{ width: effectiveWidth }"
		>
			<input type="number" class="input"
				:style="{ 'padding-right': 'calc(10px + ' + (effectiveUnit?.length ?? 0) + 'ch)' }"
				v-model="model"
				@focus="onFocus"
				@blur="onBlur"
				:min="validations?.range?.min"
				:max="validations?.range?.max"
				:data-cy="dataCy"
				:disabled="disabled"
			/>
			<div v-if="effectiveUnit" class="unit">{{ effectiveUnit }}</div>
		</div>
		<button v-if="variant == 'PLUSMINUS'" @click="increment"
			:disabled="validations?.range?.max !== undefined && model >= validations?.range?.max"
		><v-icon>mdi-plus</v-icon></button>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

export default {
	name: 'NumberField',
	mixins: [ field ],
	props: {
		modelValue: [ Number, String ],
		dataCy: String,
		disabled: Boolean,
		variant: String,
		unit: String,
		width: { type: String, default: 'standard' }, // 'standard'
	},
	data: () => ({
		model: null,
		autoModel: false,
	}),
	computed: {
		effectiveUnit() {
			if (this.unit) return this.unit
			const unit = this.field?.control?.settings?.unit
			if (!unit) return null

			// if the model explicitely defines currency, use the client's currency
			if (unit === 'currency') {
				return this.currency
			}

			// for cases where we want to use '%' and other primitive things as units
			// we dont want to create translation strings, so we use the plain string
			const key = 'text.unit-' + unit
			const r = this.$t(key)
			if (r == key) return unit
			return r
		},
		effectiveWidth() {
			if (this.width == 'standard') return 'calc(100px + ' + (this.effectiveUnit?.length ?? 0) + 'ch)'
			return this.width
		},
		currency() {
			return this.$store.state.selectedClient?.fields?.currency?.de || '€'
		},
	},
	watch: {
		modelValue(n) {
			this.model = n
			this.validate()
		},
		model(n) {
			if (n !== '') n = Number(n)
			this.$emit('update:modelValue', n)
		},
	},
	methods: {
		validate() {
			this.onErrors([
				this.validateRequired(),
				this.validateRangeMax(),
				this.validateRangeMin(),
				this.validateIn(),
			])
		},
		increment() {
			this.model++
		},
		decrement() {
			let n = this.model - 1
			const min = this.validations?.range?.min
			if (min !== null && min !== undefined && n < min) n = min
			this.model = n
		},
	},
	mounted() {
		this.model = this.modelValue
		this.validate()
	},
}
</script>

<style scoped>
.NumberField { display: flex; gap: 4px; }
.wrap { position: relative; }
.unit { position: absolute; top: 50%; transform: translateY(-50%); right: 8px; pointer-events: none; color: gray; }
input { text-align: center; width: 100%; }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }
input[type=number] { -moz-appearance: textfield; }
button[disabled] { opacity: 0.25; }
</style>