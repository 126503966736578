<template>
	<div class="TicketTypeField"
		:class="{ dense }"
	>
		<Alert style="margin-top: 4em;" v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert style="margin-top: 4em;" v-model="successTitle" type="success">{{ successDetail }}</Alert>

		<OptionSelectField
			v-model:serviceProviderOptions="serviceProviderOptions"
			:clientOptions="clientOptions"
			v-model="model"
			:canAddNew="canAddNew"
			@focus="$emit('focus', $event)"
			@show-add-option='addOptionStart'
			:error="error"
			:data-cy="dataCy"
			:dense="dense"
		>
			<template #append-item v-if="$slots['append-item']">
				<slot name="append-item" />
			</template>
		</OptionSelectField>
		<I18nStringDialog v-if="editItem"
			:title="$t('text.addNewOption')"
			v-model="editItem"
			@cancel="editItem = null"
			@confirm="addOption"
		/>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import OptionSelectField from './OptionSelectField.vue'
import Dialog from '@/components/common/Dialog.vue'
import Alert from '@/components/common/Alert.vue'
import I18nStringDialog from '../I18nStringDialog.vue'
import Loading from '@/mixins/Loading.vue'
export default {
	mixins: [ Common, Loading ],
	components: { OptionSelectField, Dialog, Loading, Alert, I18nStringDialog },
	props: {
		modelValue: Object, // CfObject<ticketType>
		dataCy: String,
		error: Boolean,
		serviceProviderOptionsOverride: Array, // CfObject<ticketType>
		dense: Boolean,
		canAddNew: { type: Boolean, default: true },
	},
	data: () => ({
		model: null,
		editItem: null,
		serviceProvider: '',
		errorTitle: '',
		errorDetail: '',
		successTitle: '',
		successDetail: '',
		loading: false,
	}),
	computed: {
		clientOptions() {
			if (this.serviceProviderOptionsOverride) return []
			return this.$store.state.selectedClient.fields.defaultAssignments.de.filter(option => option.sys?.contentType?.sys?.id === 'ticketType')
		},
		serviceProviderOptions() {
			if (this.serviceProviderOptionsOverride) return this.serviceProviderOptionsOverride
			return this.$store.state.selectedServiceProvider?.fields?.ticketTypes?.de?.length > 0 ? this.$store.state.selectedServiceProvider.fields.ticketTypes.de : []
		},
	},
	watch: {
		modelValue(n) { this.model = n },
		model(n) { this.$emit('update:modelValue', n) },
	},
	methods: {
		addOptionStart(search) {
			if (this.userIsExternalOperator)
				return window.alert('Only the service provider and the home operator may add options.')
			const m = {}
			this.locales.forEach(l => m[l.code] = search)
			this.editItem = m
		},
		async addOption() {
			await this.loadingOverlay(async () => {
				let data = {
					itemType: 'ticketType',
					name: this.editItem,
					clientId: this.$store.state.selectedClient.sys.id,
					// service provider adds option
					serviceProviderId: this.userIsOperator ? undefined : this.serviceProvider.sys.id,
				}

				try {
					const optionResponse = await this.$httpPost(`/option`, data)

					if (this.userIsOperator) {
						// We add it to the ticketTypes, but if its not in the defaultAssignments, it will not be shown! (MYS-4503)
						const clientResponse = await this.$httpPost('/default-assignment', {
							clientId: this.$store.state.selectedClient.sys.id,
							optionId: optionResponse.option.sys.id,
							defaultAssignment: true
						})
						this.$store.state.selectedClient.fields.ticketTypes.de.push(optionResponse.option)
						if (clientResponse?.client?.fields?.defaultAssignments?.de) {
							// Update the selectedClient’s defaultAssignments with the values from the response
							this.$store.state.selectedClient.fields.defaultAssignments.de = clientResponse.client.fields.defaultAssignments.de
						}
						this.model = optionResponse.option
					}
					else {
						this.serviceProvider = optionResponse.serviceProvider
						this.$store.state.selectedServiceProvider = this.serviceProvider
						this.model = optionResponse.option
					}
				}
				catch(error) {
					console.error(error)
					this.errorTitle = this.$t('text.ERROR')

					if (error?.response?.data?.error === 'You already have this option (client default)') {
						this.errorDetail = this.$t('text.optionAlreadyAssignedError')
					}
					else if (error?.response?.data?.error === 'This service option is already assigned to your profile') {
						this.errorDetail = this.$t('text.alreadyAssignedError')
					}
					else {
						if (error.response) {
							if (error.response.data.error) {
							this.errorDetail = error.response.data.error
							}
						} else {
							this.errorDetail = error
						}
					}
				}
				this.editItem = null
			}, 'addOption')
		},
	},
	mounted() {
		this.model = this.modelValue
		this.serviceProvider = this.$store.state.selectedServiceProvider
	},
}
</script>

<style scoped>
</style>