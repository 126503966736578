<template>
	<div class="contract-field-display pt-4">
		<span>{{ $t(`text.${field}`) }}:</span>
		<span v-if="model?.[field]">{{
			model[field] }}</span>
		<span class="error-field" v-else-if="!['uidNumber', 'taxId', 'businessOwnerDateOfBirth', 'businessOwnerPlaceOfBirth'].includes(field)"><v-icon class="mr-1" size="20px">mdi-alert</v-icon>
			{{
				$t('text.contentMissing')
			}}</span>
		<span v-else-if="field === 'uidNumber'">{{ $t('text.noUidAvailable')}}</span>
		<span v-else>-</span>
	</div>
</template>

<script>
export default {
	name: 'ContractFieldDisplay',
	props: {
		field: String,
		model: Object
	}
}
</script>

<style scoped lang="scss">
.contract-field-display {
	display: flex;

	>span {
		font-size: 15px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.037px;
		text-align: left;
		flex: 0.7;
		&.error-field {
			color: #FFB400;
		}
	}

	>span:first-child {
		flex: 0.3;
		font-weight: 500;
	}

}
</style>