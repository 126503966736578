import { UserManager } from 'oidc-client-ts'

export function getKeycloakConfig() {
	// TODO: since this is client code we dont have process.env here
	//       so i hardcoded some stuff..
	let authority = 'https://keycloak.production.aws.skiline.cc/auth/realms/MyServices'
	const isStaging = location.hostname.includes('.lakeside.')
	if (isStaging) authority = 'https://keycloak.staging.lakeside.skiline.cc/auth/realms/MyServices'
	const isLocal = location.hostname.includes('localhost')
	if (isLocal) authority = 'https://keycloak.staging.lakeside.skiline.cc/auth/realms/MyServices'
	// TODO: local KC does not seem to work - i get CORS errors and a 404
	//if (isLocal) authority = 'http://localhost:8080/auth/realms/master'
	return {
		isStaging,
		authority,
		clientId: 'myservices-ui',
		redirectUri: location.href,
	}
}

export function getUserManager() {
	const config = getKeycloakConfig()
	return new UserManager({
		authority: config.authority,
		client_id: config.clientId,
		redirect_uri: config.redirectUri,
		disablePKCE: document.location.protocol == 'http:', // if not ssl

		//loadUserInfo: true,

		// does not work with popup
		//stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
		response_type: 'code',
		scope: 'openid profile',
		filterProtocolClaims: true,
	})
}

// TODO: remove once we switch MYS to the new FEATURE_LOGIN_KEYCLOAK
export function getUserManagerCorp() {
	return new UserManager({
		authority: 'https://keycloak.production.aws.skiline.cc/auth/realms/MyServices',
		client_id: 'rolloutmachine',
		redirect_uri: location.href, //redirectBase + '/myservices/redirect',
		disablePKCE: document.location.protocol == 'http:', // if not ssl

		//loadUserInfo: true,

		// does not work with popup
		//stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
		response_type: 'code',
		scope: 'openid profile',
		filterProtocolClaims: true,
	})
}
