<template>
	<Dialog ref="terminateContractsDialog"
		data-cy="terminateContractsDialog"
		:confirmLabel="proceed ? '' : $t('text.confirm')"
		:deleteLabel="proceed ? $t('text.terminateContract') : ''"
		:cancelLabel="$t('text.cancel')"
		:confirm-handler="() => confirm()"
		:close-handler="() => reset()"
		:delete-handler="() => terminate()"
		:title="$t('text.terminateContract')"
		height="fit-content"
		:width="proceed ? '540px' : '896px'"
		:is-valid="!!endDate"
		:show-delete-icon="false"
		class="TerminateContractDialog terminate-contract-dialog"
	>
		<template #content>
			<div v-if="!proceed" class="terminate-contract-dialog-form">
				<FieldSet v-if="contractTemplate" :label="$t('text.performToolOnContract')" :info-text="$t('text.performToolOnContractInfoText')">
					<Field typeName="ContractTermination" fieldName="contract" v-model="contractTemplateName" dataCy="contract" :disabled="true" :options="[contractTemplate.name]" />
				</FieldSet>
				<FieldSet :label="$t('text.terminateContract')" :info-text="$t('text.terminateContractDialogInfoText')">
					<Field typeName="ContractTermination" fieldName="terminationDate" v-model="endDate" dataCy="terminationDate" :min="new Date(Date.now() + 86400000).toISOString().split('T')[0]" />
					<div class="terminate-contract-dialog-form-checkbox">
						<v-checkbox density="compact" hide-details v-model="shouldReceiveEmail" />
						<p class="text-wrap ml-3 text-sm" style="width: fit-content;">{{ $t('text.terminateContractCheckboxInfoText') }}</p>
					</div>
				</FieldSet>
			</div>
			<div v-else class="terminate-contract-dialog__wrapper">
				<div class="terminate-contract-dialog__icon"><v-icon :color="'#FFB400'" :size="'96px'">mdi-alert</v-icon></div>
				<div class="terminate-contract-dialog__text">
					<v-label class="font-weight-bold">{{ $t('text.terminateContract') }}</v-label>
					<div class="terminate-contract-dialog__content d-flex justify-center">
						<p v-html="dialogText" />
					</div>
				</div>
			</div>
		</template>
	</Dialog>
</template>
<script>
import Common from '../../mixins/Common.vue'
import Dialog from '../common/Dialog.vue'
import FieldSet from '../../views/applications/packageDesigner/FieldSet.vue'
import Field from '../../components/fields/Field.vue'
import moment from 'moment'
export default {
	name: 'TerminateContractsDialog',
	components: { Dialog, FieldSet, Field },
	mixins: [ Common ],
	props: {
		contract: { type: Object, default: null },
		contractors: { type: Array, default: () => [] },
		contracts: { type: Array, default: () => [] },
		contractTemplate: { type: Object, default: null },
	},
	data: () => ({
		endDate: '',
		proceed: false,
		contractTemplateName: null,
		shouldReceiveEmail: true
	}),
	computed: {
		formattedDate() {
			return moment(this.endDate).format('DD.MM.YYYY')
		},
		dialogText() {
			return !this.contractTemplate ? this.$t('text.terminateContractDialogText', { contractName: this.contract.contractTemplate.name, endDate: this.formattedDate }) : this.$t('text.terminateContractsDialogText', { contractName: this.contractTemplate.name, endDate: this.formattedDate, count: this.contractors.length })
		}
	},
	watch: {
		contractTemplate() {
			this.contractTemplateName = this.contractTemplate?.name
		}
	},
	methods: {
		confirm() {
			this.proceed = true
			return false
		},
		reset() {
			this.proceed = false
			this.shouldReceiveEmail = true
			this.endDate = ''
		},
		terminate() {
			this.$emit('terminateContracts', { endDate: moment(this.endDate), shouldReceiveEmail: this.shouldReceiveEmail, contractIds: this.contracts.length ? this.contracts.map(x => x.id) : [this.contract.id]})
		},
		open() {
			this.$refs.terminateContractsDialog.show = true
		}
	}
}
</script>
<style lang="scss">
.terminate-contract-dialog {
	&__wrapper {
		display: flex;
		align-items: center;
		padding: 12px 52px
	}

	&__icon {
		margin-right: 48px;
	}

	&__content {
		margin-top: 13px;
		font-size: 17px;
		line-height: 22px;
		letter-spacing: -0.071px;
		color: #8F8F8F;
	}
	&__text {
		> label {
			font-size: 20px;
			font-weight: 600;
			line-height: 25px;
			letter-spacing: -0.196px;
		}
	}
	&-form {
		display: flex;
		width: 100%;
		flex-direction: column;
		gap: 32px;
		&-checkbox {
			display: flex;
			align-items: center;
			padding-top: 24px;
		}
		.FieldSet {
			width: 100%;
			.infoText,
			>.helpText {
				font-size: 12px;
				font-weight: 400;
				line-height: 16px;
				font-style: normal;
				letter-spacing: 0.001px;
				color: black;
			}

			>.FieldSet-label {
				font-size: 17px;
				font-weight: 500;
				line-height: 22px;
				letter-spacing: -0.071px;
				color: black;
			}

			.Field label.title {
				font-size: 15px;
				font-weight: 500;
				line-height: 20px;
				letter-spacing: -0.037px;
				color: black;
			}
			.Field {
				margin-top: 12px;
				margin-bottom: 0px;
			}
			.DateField input {
				max-width: 40% !important;
			}
			.DropdownField {
				max-width: 40% !important;
				> select {
					background-color: #F4F4F4;
				}
			}
		}
	}
}
</style>