<script>
export default {
	methods: {
		async getApplications() {
			const allApplications = await this.$httpGet(`/applications`)
			return this.$store.commit('setAllApplications', allApplications)
		},
		async setServiceProviderData(user) {
			return Promise.all([
				this.$store.commit('setSelectedServiceProvider', user.fields.serviceProvider.de),
				this.$store.commit('setSelectedClient', user.fields.clients.de[0]),
			])
		},
		async setOperatorData(user) {
			let clientId = localStorage.selectedClientId
			let client = user.fields.clients.de.find(client => client.sys.id === clientId)
				?? user.fields.clients.de[0]
			clientId = client.sys.id
			// the operator user only contains a partial client object, we load the complete client..
			const r = await this.$httpGet(`/client/${clientId}`)
			client = r.client

			const components = await this.$httpGet(`/components/${client.sys.id}/serviceprovider`)
			components.sort((a, b) => (a.fields.title[this.selectedLocale] > b.fields.title[this.selectedLocale]) ? 1 : -1)

			return Promise.all([
				this.$store.commit('setSelectedClient', client),
				this.$store.commit('setSPApplications', components),
			])
		},
		async setAdminData(user) {
			return this.$store.commit('setSelectedClient', user.fields.clients.de[0])
		},
		// username may be "TOKEN" for keycloak login
		async login(username, password) {
			try {
				let user
				if (username === 'TOKEN') {
					user = await this.$httpGet('/user/me', null, { token: password })
				}
				else {
					user = await this.$httpPost('/login', { username, password })
				}

				if (!user?.kc_token) this.showError(this.$t('text.loginInvalidCredentialsError'))

				// Sort according to personal dashboard
				const components = user.fields.applications.de

				await Promise.all([
					this.$store.commit('setComponents', components),
					this.$store.commit('setLoggedInUser', user),
					this.getApplications(),
				])

				if (!user.fields.clients?.de) {
					console.error("user does not have any clients set")
				}

				if (user.fields.type.de === "serviceprovider") {
					await this.setServiceProviderData(user)
				}
				else if (user.fields.type.de === "operator") {
					await this.setOperatorData(user)
				}
				else if (user.fields.type.de === "admin") {
					await this.setAdminData(user)
				}
				else {
					console.error("received user with unsupported type:", user.fields.type.de)
					return true
				}

				return true
			}
			catch (error) {
				if (error.error) {
					this.showError(error.error)
				}
				else if (error.message) {
					console.error(error.message)
					this.showError(error.message)
					this.authError = error
				}
				else {
					// TODO: this message is likely never correct, as the server should always return error.error,
					//       so any other error will be local or network related.
					this.showError(this.$t('text.loginInvalidCredentialsError'))
				}
			}
		},
	},
}
</script>
