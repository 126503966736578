<template>
	<div class="ContractorsList">
		<Table
			v-model:selected="selectedModel"
			v-model:limit="limit"
			v-model:offset="offsetModel"
			v-model:sortBy="sortBy"
			:headers="headers"
			:multi-sort="false"
			:items="sortedContractors"
			:total="contractors.length"
			:show-select="contractors.length > 0"
			select-strategy="all"
			sort-asc-icon="mdi-menu-up"
			sort-desc-icon="mdi-menu-down"
			:noDataText="$t('text.noResultsFound')"
			class="contractors-table"
		>
			<template v-slot:item.name="{ item }">
				<div>
					<div>
						<v-tooltip content-class="contractor-details-tooltip">
							<template v-slot:activator="{ props }">
								<span v-bind="props">
									{{ `${item.name}` }}
								</span>
							</template>
							<div><b>{{ `${item.name}` }}</b></div>
							<div>{{ `PEAK ID: ${item.peakId || 'N/A'}` }}</div>
							<div>{{ `MYS ID: ${item.serviceProviderId || 'N/A'}` }}</div>
							<div>{{ item.email }}</div>
							<div>{{ item.streetAddress }}</div>
							<div>{{ `${item.zipCode} ${item.city}` }}</div>
						</v-tooltip>
					</div>
					<template v-if="item.lastReminderDate">
						<v-label>{{ getLastReminderText(item.lastReminderDate) }}</v-label>
					</template>
				</div>
			</template>
			<template v-slot:item.contractorStatus="{ item }">
				<v-tooltip :text="$t(`text.contractorStatus${getStatusIcon(item).tooltip}`)" content-class="contractor-status-tooltip" >
					<template v-slot:activator="{ props }">
						<Chip v-bind="props" :leadingIcon="getStatusIcon(item).icon" :backgroundColor="getStatusIcon(item).bgColor" :iconColor="getStatusIcon(item).iconColor" />
					</template>
				</v-tooltip>
			</template>
			<template v-slot:item.salesChannels="{ item }">
				<v-tooltip v-if="item.salesChannels?.length > 2">
					<template #activator="{ props }">
						<span v-bind="props">
							{{ getReducedSalesChannelsString(item) }}
						</span>
					</template>
					{{ getAllSalesChannelsString(item) }}
				</v-tooltip>
				<template v-else>
					{{ getAllSalesChannelsString(item) }}
				</template>
			</template>
			<template v-slot:item.contractStatus="{ item }">
				<div v-if="sequenceFilterSet && item.contracts.length > 0">
					<v-tooltip content-class="contract-status-tooltip">
						<template #activator="{ props }">
							<v-icon start :color="getContractStatus(item).color">mdi-draw-pen</v-icon>
							<span v-bind="props" :style="{ 'color': getContractStatus(item).color }">{{ item.contracts.length > 1 ? $t('text.contractsStatusMultiple') : getContractStatus(item).text }}</span>
						</template>
						<div style="display: flex; flex-direction: column; gap: 10px; max-height: fit-content;">
							<ContractCard v-for="contract in item.contracts" :key="contract.id" :contract="contract"></ContractCard>
						</div>
					</v-tooltip>
				</div>
				<div v-else>-</div>
			</template>	
			<template v-slot:item.edit="{ item }">
				<div class="contractor-edit-row">
					<v-btn variant="outlined" icon="mdi-pencil" rounded="lg"
							@click="$router.push('/contractManagement/' + item.id)" 
					/>
				</div>
			</template>
		</Table>
	</div>
</template>
<script lang="ts">
import Chip from '../common/controls/Chip.vue'
import Table from '../common/tables/Table.vue'
import FieldSet from '@/views/applications/packageDesigner/FieldSet.vue'
import ContractCard from './ContractCard.vue'

export default {
	components: { Chip, Table, FieldSet, ContractCard },
	props: {
		contractors: { type: Array, default: () => [] },
		sequenceFilterSet: Boolean,
		offset: { type: Number, required: true },
		selected: { type: Array, default: () => [] },
	},
	data() {
		return {
			sortBy: [],
			limit: 10
		}
	},
	computed: {
		selectedModel: {
			get() {
				return this.selected
			},
			set(n) {
				this.$emit('update:selected', n)
			},
		},
		headers() {
			return [
				{ title: this.$t('text.name'), key: 'name', value: 'name', sortable: true },
				// { title: this.$t('text.eCommerceCategories'), key: 'productKinds', value: 'productKinds', sortable: false },
				{ title: this.$t('text.salesChannels'), key: 'salesChannels', value: 'salesChannels', sortable: false },
				{ title: this.$t('text.contractorStatus'), key: 'contractorStatus', value: 'contractorStatus', sortable: false },
				{ title: this.$t('text.contractStatus'), key: 'contractStatus', value: 'contractStatus', sortable: false },
				{ title: '', key: 'edit', value: 'edit', sortable: false },
			]
		},
		offsetModel: {
			get() {
				return this.offset
			},
			set(n) {
				this.$emit('update:offset', n)
			}
		},
		sortedContractors() {
			return [...this.contractors].sort((a, b) => {
				// 1. Contractors without contracts
				// First sort by whether they have contracts - the ones without contracts should be at the top
				if (!a.contracts?.length && b.contracts?.length) return -1
				if (a.contracts?.length && !b.contracts?.length) return 1

				// For contractors without contracts, sort by newest first (using peakId as proxy for creation time)
				// TODO: this works for phase 1 as the contractors from PEAK will not be imported on a specific order but rather randomly. However, for normal MYS SPs maybe we want to use the updatedAt field instead?
				if (!a.contracts?.length && !b.contracts?.length) {
					return b.peakId - a.peakId
				}

				// 2. Contractors with contracts
				// For contractors with contracts, sort by last reminder date
				const aDate = a.lastReminderDate ? new Date(a.lastReminderDate) : new Date(0)
				const bDate = b.lastReminderDate ? new Date(b.lastReminderDate) : new Date(0)
				return bDate.getTime() - aDate.getTime()
			})
		}
	},
	methods: {
		getStatusIcon(item) {
			if (item.noEmail) return { icon: 'mdi-email', bgColor: '#FDE0E0', iconColor: '#922929', tooltip: 'MailMissing' }
			else if (!item.needsContract) return { icon: 'mdi-folder-off', bgColor: '#F4F4F4', iconColor: '#5C5C5C', tooltip: 'ContractNotNeeded' }
			else if (item.contractorStatus === 'assigned') return { icon: 'mdi-gavel', bgColor: '#D4F1FC', iconColor: '#004660', tooltip: 'ContractsAssigned' }
			else return { icon: 'mdi-gavel', bgColor: '#FFF2D4', iconColor: '#996C00', tooltip: 'ContractsMissing' }
		},
		getReducedSalesChannelsString(item): string {
			let result = item.salesChannels?.[0] || ''
			if (item.salesChannels?.length > 1) result += ', ' + item.salesChannels[1]
			if (item.salesChannels?.length > 2) result += ' +' + (item.salesChannels.length - 2)
			return result
		},
		getAllSalesChannelsString(item): string | undefined {
			return item.salesChannels?.join(', ')
		},
		getContractStatus(contractor) {
			const status = {
				text: this.$t('text.contractsStatusMultiple'),
				color: '#8F8F8F',
			}
			if (contractor.contracts?.length === 1) {
				const overdue = new Date(contractor.contracts[0].dueDate) < new Date
				const contractStatus = contractor.contracts[0].status.toLowerCase()
				status.text = this.$t(`text.contractStatus${contractStatus.charAt(0).toUpperCase() + contractStatus.slice(1)}`)
				if (contractStatus === 'signed') {
					status.color = '#64C823'
				}
				else if (contractStatus === 'new') {
					status.color = overdue ? '#F34545' : '#FFB400'
				}
				else {
					status.color = '#8F8F8F'
				}
			}
			return status
		},
		getLastReminderText(lastReminderDate) {
			const date = new Date(lastReminderDate)

			const datePart = date.toLocaleDateString(this.$store.state.selectedLocale, {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
			})

			const timePart = date.toLocaleTimeString(this.$store.state.selectedLocale, {
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
				hour12: false,
			})

			return `${this.$t('text.contractorMailSentOn')} ${datePart} ${this.$t('text.contractorHistoryAt')} ${timePart}`
		},
	},
}
</script>
<style lang="scss">
.v-tooltip > .v-overlay__content {
	&.contractor-details-tooltip,
	&.contractor-status-tooltip {
		padding: 3px 8px;
		background-color: #5A5A5AE5;
		pointer-events: auto !important;
		font-size: 13px;
		line-height: 18px;
	}
	&.contract-status-tooltip {
		min-width: 446px !important;
		box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
		background-color: #fff;
		border: 1px solid rgba(0, 0, 0, 0.10);
		border-radius: 0.25rem;
		padding: 16px;
	}
}
.ContractorsList > .contractors-table {
	table > tbody > tr {
		height: 60px;
		.contractor-edit-row {
			height: 40px;
    		width: 40px;
			> button {
			 	display: none;
				height: 40px;
    			width: 40px;
			}
		}
		&:hover {
			background-color: #f9f9f9;
			.contractor-edit-row > button {
				display: block;
			}	
		}
	}
}
</style>