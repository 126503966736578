<script>
import moment from 'moment'
import { ContractStatus } from '../../../api2/src/modules/contract/contract.entities'

export default {
	props: {
		skipTerminationCheck: { type: Boolean },
	},
	computed: {
		contractTemplate() {
			return this.contract.contractTemplate
		},
		needsSignature() {
			// for some reason moving it from isPhysical() into its own computed resolves the UI frozen issue
			return this.contractTemplate?.needsSignature
		},
		isPhysical() {
			return !this.needsSignature
		},
		isTerminated() {
			return this.contract.status === ContractStatus.TERMINATED || (this.contract.endDate && moment().isAfter(moment(this.contract.endDate)))
		},
		isSigned() {
			return !!this.contract.signedDate
		},
		toBeSigned() {
			return !this.isSigned && (!this.contract.dueDate || 
				(this.contract.dueDate && moment().isBefore(moment(this.contract.dueDate))))
		},
		overdue() {
			return !this.isSigned && !this.toBeSigned && 
				this.contract.dueDate && moment().isAfter(moment(this.contract.dueDate))
		},
		backgroundColor() {
			if (!this.skipTerminationCheck && this.isTerminated) {
				return '#F4F4F4'
			}
			if (this.isSigned || !this.needsSignature) {
				return '#E5F6DA'
			}
			if (this.toBeSigned) {
				return '#FFF2D4'
			}
			if (this.overdue) {
				return '#FDE0E0'
			}
			return '#DBDBDB'
		},
		textColor() {
			if (!this.skipTerminationCheck && this.isTerminated) {
				return '#000000'
			}
			if (this.isSigned || !this.needsSignature) {
				return '#28500E'
			}
			if (this.toBeSigned) {
				return '#664800'
			}
			if (this.overdue) {
				return '#611C1C'
			}
			return '#DBDBDB'
		},
		backgroundColorChip() {
			if (!this.skipTerminationCheck && this.isTerminated) {
				return '#8F8F8F'
			}
			if (this.isSigned || !this.needsSignature) {
				return '#64C823'
			}
			if (this.toBeSigned) {
				return '#FFB400'
			}
			if (this.overdue) {
				return '#F34545'
			}
			return '#8F8F8F'
		},
		statusText() {
			if (!this.skipTerminationCheck && this.isTerminated) {
				return this.$t('text.terminated')
			}
			if (this.isSigned || this.isPhysical) {
				return this.$t('text.signed')
			}
			return this.$t('text.unsigned')
		},
		infoText() {
			if (this.isTerminated) {
				return ''
			}
			if (this.isSigned) {
				return `${this.$t('text.signedOn')} <b>${moment(this.contract.signedDate).format('DD.MM.YYYY')} ${this.$t('text.at')} ` +
					`${moment(this.contract.signedDate).format('HH:mm:ss')}</b> by <b>${this.contract.signatureName}, ${this.contract.signaturePosition}</b>`
			}
			if (this.toBeSigned) {
				return `<b>${this.$t('text.signUntil')} ${moment(this.contract.dueDate).format('DD.MM.YYYY')}</b>`
			}
			if (this.overdue) {
				return `<b>${this.$t('text.notSignedInTime')}</b>`
			}
			return ''
		},
		issuedOnText() {
			return `${this.$t('text.issuedOn')} ${moment(this.contract.createdDate).format('DD.MM.YYYY')} ${this.$t('text.at')} ` +
				`${moment(this.contract.createdDate).format('HH:mm:ss')}`
		}
	}
}
</script>