<template>
	<Application name="Login" :loading="loading" v-model:error-title="errorTitle" :error-detail="errorDetail" class="main">
		<template #navbar></template>
		<div class="authError" v-if="authError">
			Authentication Error: {{ authError.message }}
			<v-btn class="greenButton" elevation="0" @click="restartLogin">Login</v-btn>
		</div>
		<v-row id="loginView" align="center" justify="center" v-if="showLogin">
			<v-card id="card" class="elevation-12">
				<h1>{{ $t('text.loginTitle') }}</h1>
				<p class="subTitle" v-html="$t('text.loginSubTitle')" />
				<div v-if="oidc?.error">
					<div class="error">
						<v-icon>mdi-alert</v-icon>
						{{ oidc.error }}
					</div>
				</div>
				<v-row v-else style="margin-top: 20px; margin-bottom: 10px;">
					<v-col class="d-flex flex-column field left-border" cols="12" sm="6">
						<h3>{{ $t('text.login') }}</h3>
						<v-form>
							<v-label>{{ $t('text.email') }}</v-label>
							<v-text-field variant="outlined" density="compact" required
								id="loginUsername"
								v-model="email"
								:error-messages="emailErrors"
								@update:modelValue="v$.email.$touch()"
								@blur="v$.email.$touch()"
								@keyup.enter="handleLogin()"
								data-cy="email"
								name="username"
								autocomplete="username"
							/>
							<v-label>{{ $t('text.password') }}</v-label>
							<div style="display: flex; gap: 10px;">
								<v-text-field :type="type" variant="outlined" density="compact" required
									id="loginPassword"
									v-model="password"
									:error-messages="passwordErrors"
									@update:modelValue="v$.password.$touch()"
									@blur="v$.password.$touch()"
									@keyup.enter="handleLogin()"
									data-cy="password"
									name="password"
									autocomplete="current-password"
								/>
								<v-btn id="loginShowPassword" class="gradientButton ml-1 mt-3" size="small" variant="flat" :disabled="password.length === 0" @click="showPassword()">
									<v-icon v-if="!plainTextPassword">mdi-eye</v-icon>
									<v-icon v-if="plainTextPassword">mdi-eye-off</v-icon>
								</v-btn>
							</div>
						</v-form>
						<a href="/forgotPassword" @click.prevent="$router.push('forgotPassword')">{{ $t('text.forgotPassword') }}</a>
						<v-row v-if="!oidc" align="end" style="margin-left: 0px; margin-top: 10px;">
							<v-btn size="x-large" variant="flat" theme="dark" class="greenButton" @click.prevent="handleLogin()" data-cy="login-button">{{$t('text.btnLogin')}}</v-btn>
						</v-row>
						<v-row v-if="oidc" align="end" style="margin-left: 0px; margin-top: 10px;">
							<div style="display: flex; gap: 20px; align-items: center;">
								<v-btn size="x-large" variant="flat" theme="dark" class="blueButton" @click.prevent="handleLogin()" data-cy="login-button">Login &rarr; <b>{{ oidc.client_id }}</b></v-btn>
								<a href="#" style="border: 0; color: blue;" @click.prevent="keycloakLogin" data-cy="keycloak-login-button">Alturos SSO</a>
							</div>
						</v-row>
					</v-col>
					<v-col class="field left-border" cols="12" sm="6">
						<h3>{{$t('text.register')}}</h3>
						<ul>
							<li>{{$t('text.registerItem1')}}</li>
							<li>{{$t('text.registerItem2')}}</li>
							<li>{{$t('text.registerItem3')}}</li>
							<li>{{$t('text.registerItem4')}}</li>
						</ul>
						<v-row align="end" style="margin-left: 0px; margin-top: 10px;">
							<v-btn data-cy="register-button" id="btnRegister" class="greenButton" size="x-large" variant="flat" theme="dark" @click="registration">{{$t('text.btnRegister')}}</v-btn>
						</v-row>
					</v-col>
				</v-row>
			</v-card>
		</v-row>

		<!-- Maintenance Mode -->
		<v-row align="center" justify="center"  v-if="showMaintenance">
			<v-card id="card" class="elevation-12">
				<v-card-text style="margin:0px;padding:0px;text-align:center;">
					<img src="@/assets/icons/maintenance.svg" width="128"/>
					<p class="maintenance" align="center" v-html="$t('text.maintenance')"/>
				</v-card-text>
			</v-card>
		</v-row>
	</Application>
</template>

<script>
import Loading from 'vue-loading-overlay'
import { useVuelidate } from "@vuelidate/core"
import { required, email } from "@vuelidate/validators"
import Application from '../applications/Application.vue'
import Common from '@/mixins/Common.vue'
import eventBus from '@/utils/eventBus.js'
import { translationUtil } from '@/utils/translation-util.js'
import Authorization from '@/mixins/Authorization.vue'
import OIDC from './OIDC.ts'
import { getUserManager, getUserManagerCorp } from './oidc-keycloak.ts'

export default {
	name: 'Login',
	components: { Loading, Application },
	mixins: [ Common, Authorization ],
	validations: {
		email: { required, email },
		password: { required },
	},
	setup() {
		return {
			v$: useVuelidate()
		}
	},
	data: () => ({
		showLogin: false,
		showMaintenance: false,
		loading: false,
		errorTitle: '',
		errorDetail: '',

		email: '',
		password: '',
		plainTextPassword: false,
		type: 'password',

		oidc: null,
		authError: null,
	}),
	computed: {
		emailErrors() {
			const errors = []

			if (!this.v$.email.$dirty) return errors

			if (!this.v$.email) errors.push(this.$t('text.emailInvalid'))
			if (this.v$.email.required.$invalid) errors.push(this.$t('text.emailRequired'))

			return errors
		},
		passwordErrors() {
			const errors = []
			const { $dirty, required } = this.v$.password

			if (!$dirty) return errors

			if (required.$invalid) errors.push(this.$t('text.passwordRequired'))

			return errors
		},
	},
	methods: {
		async checkMaintenanceMode() {
			try {
				return await this.$httpGet(`/maintenance-mode`)
			}
			catch (error) {
				console.error(error)
			}
		},
		showPassword() {
			if (this.type === 'password') {
				this.type = 'text'
				this.plainTextPassword = true
			} else {
				this.type = 'password'
				this.plainTextPassword = false
			}
		},
		async handleLogin() {
			this.v$.$touch()

			if (this.emailErrors.length !== 0 || this.passwordErrors.length !== 0) {
				return
			}
			return this.doLogin(this.email, this.password)
		},
		async doLogin(email, password) {
			this.loading = true
			const ok = await this.login(email, password)
			this.loading = false

			if (!ok) return

			if (this.oidc) {
				await OIDC.complete(this.oidc, this.$store.state.loggedInUser.kc_token)
				return
			}
			this.$emit('show-dashboard')
		},
		// TODO: remove when we have FEATURE_LOGIN_KEYCLOAK enabled
		async keycloakLogin() {
			if (this.featureFlagEnabled('FEATURE_LOGIN_KEYCLOAK')) {
				getUserManager().signinRedirect()
			}
			else {
				getUserManagerCorp().signinRedirect()
			}
		},
		registration() {
			this.$router.push('/registration' + location.search)
		},
		async tokenValid() {
			try {
				return await this.$httpGet(`/token-valid`)
			}
			catch (error) {}
		},
		async oauth() {
			// support oauth2 for urls like this:
			// http://localhost:8080/auth?response_type=code&client_id=ContentHub&redirect_uri=https://editor.staging.contenthub.dev/myPath&scope=login+profile&state=xcoivjuywkdkhvusuye3kch&clientId=Zillertal

			// TODO: should we show maintenance mode also in case of the oauth?
			this.showLogin = true
			const query = this.$route.query
			this.oidc = await OIDC.init(query)

			// keycloak login callback
			if (location.href.indexOf('code=') != -1) {
				let r
				// in case we use the KC login flow we need to use the callback for that
				if (this.featureFlagEnabled('FEATURE_LOGIN_KEYCLOAK')) {
					r = await getUserManager().signinCallback()
				}
				else {
					r = await getUserManagerCorp().signinCallback()
				}
				await OIDC.complete(this.oidc, r.access_token)
				return
			}

			if (this.$route.query.forceLogin === 'true') {
				console.log('OA: force login')
				return
			}

			const tokenValid = await this.tokenValid()
			if (!tokenValid) {
				console.log('OA: token not valid')
				return
			}

			if (this.oidc.error) {
				console.error('OA: OIDC error', this.oidc.error)
				return
			}

			// TODO: in case the user requested a specific email, we have to check if the session is for that user
			if (query.email) this.email = query.email

			if (this.$store.state.loggedInUser && Object.entries(this.$store.state.loggedInUser).length > 0) {
				await OIDC.complete(this.oidc, this.$store.state.loggedInUser.kc_token)
				return true
			}
		},
		restartLogin() {
			// TODO: this will probably clear the initialUrl which is not what we want
			//location.href = '/'
			// in this case we want to restart even on the callback url as this error will occur likely here
			this.$emit('show-login', { isCallback: false })
		},
	},
	async mounted() {
		// TODO: when the url has a stale state query, currently the ui seems to run into a loop
		// TODO: finish new keycloak login flow
		if (this.featureFlagEnabled('FEATURE_LOGIN_KEYCLOAK')) {
			let user
			try {
				// 2. receive KC login
				const url = window.location.toString()
				// this throws when the url does not contain a response state
				user = await getUserManager().signinCallback(url)

				// TODO: what do we need to do for token refresh?

				// 3. call mys login with token
				const ok = await this.doLogin('TOKEN', user.access_token)

				// TODO: maintenance-mode?
				translationUtil.reload(this.$store)
			}
			catch (e) {
				if (e.message == 'No state in response') {
					// 1. initiate KC login
					this.keycloakLogin()
				}
				else {
					this.authError = { message: e.message }
					console.error('KC login error', e)
				}
			}
			return
		}

		if (this.$route.path === '/auth') {
			this.oauth()
		}
		else if (this.$store.state.loggedInUser && Object.entries(this.$store.state.loggedInUser).length > 0) {
			this.$emit('show-dashboard')
		}
		else {
			const scid = localStorage.selectedClientId
			localStorage.clear()
			localStorage.selectedClientId = scid

			if (this.$route.path === '/') {
				const maintenanceMode = await this.checkMaintenanceMode()

				if (maintenanceMode === true) {
					this.showMaintenance = true
					this.showLogin = false
				}
				else {
					this.showLogin = true
					this.showMaintenance = false
				}
			}
			else {
				this.showLogin = false
			}
		}
		translationUtil.reload(this.$store)
	},
	beforeUnmount () {
		// TODO: we dont have an on - why do we have an off here?
		eventBus.$off('show-dashboard')
	},
}
</script>

<style scoped>
h3 { position: relative; top: -8px; }
ul { color: black; font-size: 12pt; margin-left: 15px; }
ul li { margin-bottom: 10px; }
.maintenance { font-size: 16pt; line-height:2 }
.v-btn { color: white; }
.field { margin-top: 0; margin-bottom: 0; }
.error { background: var(--col-error); color: white; width: 100%; border-radius: 5px; margin-bottom: 32px; margin-top: 32px; padding: 10px; }
.authError { background: white; color: var(--col-error); width: 100%; max-width: 320px; border-radius: 5px; margin: 32px auto; padding: 20px; display: flex; flex-direction: column; gap: 10px; }
</style>