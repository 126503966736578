<template>
	<div class="contract-card" :style="{ 'background-color': backgroundColor, 'color': textColor }">
		<div class="contract-card-top">
			<v-icon class="mr-1" size="18px">mdi-gavel</v-icon>
			<div class="contract-card-top-name">
				<span v-if="contract.contractTemplate" class="contract-name">{{ contract.contractTemplate.name }}</span>
				<span class="marketplace-name">{{ clientName }}</span>
			</div>
			<div :style="{ 'background-color': backgroundColorChip }" class="contract-card-top-chip">
				<v-icon size="16px">mdi-draw</v-icon>
				<span>{{ statusText }}</span>
			</div>
		</div>
		<template v-if="!isPhysical">
			<span class="contract-card-info" v-html="infoText"></span>
			<span class="contract-card-issued">
				<template v-if="!isTerminated">{{ issuedOnText }}</template>
				<template v-else>{{ $t('text.terminatedOn') }} {{ formatDate(contract.endDate) }}</template>
			</span>
		</template>
		<template v-else>
			<span class="contract-card-issued">
				<template v-if="isTerminated">{{ $t('text.terminatedOn') }} {{ formatDate(contract.endDate) }}</template>
			</span>
		</template>
	</div>
</template>
<script lang="ts">
import { Contract } from '../../../../api2/src/modules/contract/contract.entities'
import ContractStatusMixin from '../../mixins/ContractStatusMixin.vue'

export default {
	name: 'ContractCard',
	mixins: [ContractStatusMixin],
	props: {
		contract: {
			type: Object as () => Contract,
			required: true
		},
		clientName: {
			type: String,
			required: false
		}
	}
}
</script>

<style scoped lang="scss">
.contract-card {
	padding: 14px;
	border-radius: 4px;
	display: flex;
	flex-direction: column;

	&-top {
		display: flex;
		align-items: flex-start;
		gap: 8px;

		&-name {
			display: flex;
			flex-direction: column;
			gap: 2px;
			min-width: 0;
			flex: 1;

			.contract-name {
				font-size: 17px;
				font-weight: 500;
				line-height: 22px;
				letter-spacing: -0.071px;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
				word-break: break-word;
			}

			.marketplace-name {
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
				letter-spacing: 0.001px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		&-chip {
			flex-shrink: 0;
			display: flex;
			height: 22px;
			padding: 0px 6px;
			justify-content: center;
			align-items: center;
			gap: 4px;
			border-radius: 4px;
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
			letter-spacing: 0.001px;
			color: white;
			white-space: nowrap;
		}
	}

	&-info {
		padding-top: 12px;
		font-size: 15px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.037px;
	}

	&-issued {
		text-align: right;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: 0.001px;
		color: rgba(0, 0, 0, 0.5)
	}
}
</style>