export const field = {
	props: {
		locale: String,
		type: String,
		field: Object,
		title: String,
		wrapper: Object,
		options: Object,
		locales: Array,
		i18nModel: [ String, Number, Array, Object ],
		disabled: Boolean,
		min: [ Number, String ],
		max: [ Number, String ],
		placeholder: String,
		fieldName: String,
		typeName: String,
	},
	data: () => ({
		model: null,
		error: false,
		autoModel: true,
		modalContent: null,
		modalParent: null,
	}),
	watch: {
		// 2-way-binding
		modelValue(n) {
			if (!this.autoModel) return
			this.model = n
		},
		model(n) {
			if (!this.autoModel) return
			this.$emit('update:modelValue', n)
		},
	},
	computed: {
		// we convert the field validations into a structure that is easier to work with in vue
		validations() {
			const r = {}
			if (this.field.required) {
				r.required = true
			}
			// TODO: is it valid to just chuck in the item validations with the field validations? IMO yes.
			//       maybe not for size validation - that can be on both simultaneously.
			//       i think we should rename the size validation on field to 'items_size' on Array type fields (similar to what we do in entry-decorators).
			for (const validation of [ ...(this.field.validations ?? []), ...(this.field.items?.validations ?? []) ]) {
				for (const k in validation) {
					if (k == 'message') continue
					if (typeof validation[k] != 'object') validation[k] = { value: validation[k] }
					validation[k].message = validation.message
					r[k] = validation[k]

					// TODO: can we write this more elegantly?
					// node validations (RTF) have a special, deeper structure
					if (k == 'nodes') {
						const nodeValidations = {}
						for (const type in r.nodes) { // type = 'embedded-entry-block' | etc.
							if (type == 'message') continue
							nodeValidations[type] = {}
							for (const subval of r.nodes[type]) {
								for (const sk in subval) {
									if (sk == 'message') continue
									nodeValidations[type][sk] = { message: subval.message }
									if (typeof subval[sk] == 'object' && !Array.isArray(subval[sk]))
										Object.assign(nodeValidations[type][sk], subval[sk])
									else
										nodeValidations[type][sk].value = subval[sk]
								}
							}
						}
						r.nodes = nodeValidations
					}
				}
			}
			return r
		},
		serviceLocale: {
			get() {
				return this.$store.state.serviceLocale
			},
			async set(newValue) {
				await this.$store.commit('setServiceLocale', newValue)
			}
		},
	},
	methods: {
		focus() {
			this.$refs.input?.focus?.()
		},
		onFocus(e) {
			this.$emit('focus', e)
			this.$emit('wrapper', [ 'onFocus', e ])
		},
		onBlur(e) {
			this.$emit('blur', e)
			this.$emit('wrapper', [ 'onBlur', e ])
		},
		onErrors(errors) {
			errors = errors.filter(e => !!e)
			this.error = !!errors.length
			this.$emit('errors', errors)
			this.$emit('wrapper', [ 'onErrors', errors ])
		},
		$modal(content = undefined) {
			this.$nextTick(() => {
				if (!content) content = this.$refs.modal
				this.modalContent = content
				const body = document.getElementById('app')
				this.modalParent = content.parentNode
				if (content.parentNode) {
					content.parentNode.removeChild(content)
				}
				body.appendChild(content)
			})
		},
		$unmodal() {
			this.$nextTick(() => {
				try {
					const body = document.getElementById('app')
					const content = this.modalContent
					body.removeChild(content)
					if (this.modalParent?.addChild) {
						this.modalParent.addChild(content)
					}
					this.modalContent = null
				}
				catch (e) {
					console.warn('unmodal failed', e)
				}
			})
		},
		validateRequired() {
			if (this.validations.required && (this.modelValue === undefined || this.modelValue === null || this.modelValue === '')) {
				return { id: this.validations.required.message ?? 'required' }
			}
		},
		validateMax() {
			const val = this.validations.size?.max
			if (val === null || val === undefined) return
			if ((this.modelValue?.length ?? 0) > this.validations.size?.max) {
				const defaultMessage = this.field.type == 'Array' ? 'arrayMax' : 'max'
				return { id: this.validations.size.message ?? defaultMessage, params: { max: this.validations.size?.max } }
			}
		},
		validateMin() {
			const val = this.validations.size?.min
			if (val === null || val === undefined) return
			if (!(this.validations.required && (this.modelValue?.length ?? 0) == 0) &&
				(this.modelValue?.length ?? 0) < val) {
				const defaultMessage = this.field.type == 'Array' ? 'arrayMin' : 'min'
				return { id: this.validations.size.message ?? defaultMessage, params: { min: val } }
			}
		},
		validateRangeMax() {
			const val = this.validations.range?.max
			if (val === null || val === undefined) return
			if (this.modelValue > val) {
				return { id: this.validations.range?.message ?? 'rangeMax', params: { max: val } }
			}
		},
		validateRangeMin() {
			const val = this.validations.range?.min
			if (val === null || val === undefined) return
			if (this.modelValue < val) {
				return { id: this.validations.range?.message ?? 'rangeMin', params: { min: val } }
			}
		},
		validateArrayMax() {
			const val = this.validations.size?.max
			if (val === null || val === undefined) return
			if ((this.modelValue?.length ?? 0) > val) {
				return { id: this.validations.size.message ?? 'arrayMax', params: { max: val } }
			}
		},
		validateArrayMin() {
			const val = this.validations.size?.min
			if (val === null || val === undefined) return
			if ((this.vamodelValuelue?.length ?? 0) < val) {
				return { id: this.validations.size.message ?? 'arrayMin', params: { min: val } }
			}
		},
		validateRegexp() {
			if (this.validations.regexp) {
				if (!new RegExp(this.validations.regexp.pattern, this.validations.regexp.flags ?? undefined).exec(this.modelValue)) {
					return { id: this.validations.regexp.message ?? 'regexp' }
				}
			}
		},
		validateProhibitRegexp() {
			if (this.validations.prohibitRegexp) {
				if (new RegExp(this.validations.prohibitRegexp.pattern, this.validations.prohibitRegexp.flags ?? undefined).exec(this.modelValue)) {
					return { id: this.validations.prohibitRegexp.message ?? 'prohibitRegexp' }
				}
			}
		},
		// TODO: weirdly validations.in has indexes (as an array) AND .message
		//       -> find this bug and fix it (probably it is in the validations() computed
		validateIn(allowedValues) {
			if (!allowedValues)
				allowedValues = this.options ?? this.validations.in
			if (!allowedValues?.length) return
			if (this.modelValue && allowedValues && allowedValues.indexOf(this.modelValue) < 0) {
				return { id: this.validations.in?.message ?? 'in', params: { allowedValues } }
			}
		},
		validateArrayIn(allowedValues) {
			if (!allowedValues) {
				if (this.options && this.options?.[0]?.sys) return
				allowedValues = this.options ?? this.validations.in
			}
			if (!allowedValues?.length) return
			// TODO: this will give many same warnings - instead we should explicitely state which item is faulty and either mark or mention that.
			if (this.modelValue && this.modelValue !== '' && allowedValues) {
				for (const value of this.modelValue) {
					if (allowedValues.indexOf(value) < 0) {
						return { id: this.validations.in?.message ?? 'arrayIn', params: { allowedValues } }
					}
				}
			}
		},
		validateArrayInLinks(allowedValues) {
			if (!allowedValues) {
				if (this.options && !this.options?.[0]?.sys) return
				allowedValues = this.options ?? this.validations.inLinks?.map(link => link.sys.id)
			}
			if (!allowedValues?.length) return
			// TODO: this will give many same warnings - instead we should explicitely state which item is faulty and either mark or mention that.
			if (this.modelValue && allowedValues) {
				for (const value of this.modelValue) {
					if (allowedValues.indexOf(value?.sys?.id) < 0) {
						return { id: this.validations.inLinks?.message ?? 'arrayInLinks', params: { allowedValues } }
					}
				}
			}
		},
		// TODO: these 2 dont work like this - the modelValue actually contains a list of links!
		validateTypeIn() {
			// TODO: we cannot validate in this direction:
			//       we only have a link in hand and we dont know its content type
			//       the link view does resolve the type, but that does not filter back into the model of this..
			//       can we make this validator "passive"?
			//       the entry view would actually validate and it emits validation events

			/*const allowedValues = this.validations.in
			if (this.modelValue !== '' && allowedValues && allowedValues.indexOf(this.modelValue) < 0) {
				return this.validations.in.message ?? 'Value must be one of: ' + allowedValues.join(', ')
			}
			return this.validateIn(this.validations.linkContentType)*/
		},
		validateArrayTypeIn() {
			//return this.validateArrayIn(this.validations.linkContentType)
		},
		validateObjectSize() {
			if (!this.modelValue) return
			if (this.validations.size?.min && Object.keys(this.modelValue).length < this.validations.size?.min) {
				return { id: this.validations.size.message ?? 'objectMin', params: { min: this.validations.size?.min } }
			}
			if (this.validations.size?.max && Object.keys(this.modelValue).length > this.validations.size?.max) {
				return { id: this.validations.size.message ?? 'objectMax', params: { max: this.validations.size?.max } }
			}
		},
	},
	mounted() {
		if (!this.autoModel) return
		this.model = this.modelValue
	},
}
