import { CheckPolicy, Resource } from '../../framework/permission-util'
import { RT } from '../permissions'
import Executive from '../../framework/rmi-util'
import { register } from '../../framework/rmi-server-util'
import { assignContract, ContractTemplateForListView, deleteContractSequence, deleteContractTemplate, getContractor, getContractorsForList, getContractorWithFields, getContractSequences, getContractTemplateDetails, getContractTemplates, getContractTemplateWithFields, importContractors, setContractNeeded, upsertContractTemplate, updateContractor, signContracts, ContractorFilterItem, getContractorSalesChannels, generateViewContractsToken, getContractsAndContractorsByToken, getContractors, bulkUpdateContractors, sendKafkaMessage, subscribeToKafkaTopic, sendReminder, getContractorsWithoutContract, getContractTemplatesForClient, deleteCascadeContractors, importMysSpsAsContractors, createContractorForMysSp, terminateContracts, signContractsWithToken, bulkUpdateContractorsWithToken, getContractsAndContractorsForEmail, getContractorServiceProvider, bulkSetTerminationDateToContract, bulkSetNeedsContract, contractTemplateFactory, contractTemplateHasContracts, getContractsThatNeedAttention, exportContractors, updateContractorPeakId  } from './ContractTechnical'
import { Contract, Contractor, ContractSequence, ContractTemplate } from './contract.entities'
import { GenerateViewContractsTokenResponse, GetContractsAndContractorsForEmailResponse, TerminateContractsResult } from './util/types'

export default class ContractExecutive extends Executive {

	initServer() {
	}

	@CheckPolicy()
	async importContractors(@Resource(RT.CLIENT) clientId: string, contractorType: string): Promise<void> {
		return await importContractors(clientId, contractorType)
	}

	@CheckPolicy()
	async upsertContractTemplate(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTTEMPLATE) template: ContractTemplate): Promise<ContractTemplate> {
		return await upsertContractTemplate(clientId, template)
	}

	@CheckPolicy()
	async getContractTemplates(@Resource(RT.CLIENT) clientId: string): Promise<ContractTemplateForListView[]> {
		return await getContractTemplates(clientId)
	}

	@CheckPolicy()
	async getContractSequences(@Resource(RT.CLIENT) clientId: string): Promise<ContractSequence[]> {
		return await getContractSequences(clientId)
	}

	@CheckPolicy()
	async getContractTemplateDetails(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTTEMPLATE) templateId: string): Promise<ContractTemplate> {
		return await getContractTemplateDetails(templateId)
	}

	@CheckPolicy()
	async getContractTemplateWithFields(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTTEMPLATE) templateId: string, options: { fields: string[], populate: string[] }): Promise<ContractTemplate> {
		return await getContractTemplateWithFields(templateId, options)
	}

	@CheckPolicy()
	async assignContract(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTSEQUENCE) contractTemplateId: string, @Resource(RT.CONTRACTOR) contractorIds: string[]): Promise<{assignedCount: number, totalCount: number}> {
		return await assignContract(clientId, contractTemplateId, contractorIds)
	}

	@CheckPolicy()
	async signContractsWithToken(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTOR) contractorId: string, @Resource(RT.CONTRACT) contractIds: string[], signatureName: string, signaturePosition: string, token: string): Promise<void> {
		return await signContractsWithToken(clientId, contractorId, contractIds, signatureName, signaturePosition, token)
	}

	@CheckPolicy()
	async signContracts(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTOR) contractorId: string, @Resource(RT.CONTRACT) contractIds: string[], signatureName: string, signaturePosition: string): Promise<void> {
		return await signContracts(clientId, contractorId, contractIds, signatureName, signaturePosition)
	}

	@CheckPolicy()
	async setContractNeeded(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTOR) contractorIds: string[], needsContract: boolean): Promise<void> {
		return await setContractNeeded(contractorIds, needsContract)
	}

	@CheckPolicy()
	async getContractors(@Resource(RT.CLIENT) clientId: string): Promise<Contractor[]> {
		return await getContractors(clientId)
	}

	@CheckPolicy()
	async updateContractor(@Resource(RT.CONTRACTOR) contractor: Contractor, fieldsToUpdate: string[]): Promise<void> {
		await updateContractor(contractor, fieldsToUpdate)
	}

	@CheckPolicy()
	async getContractor(@Resource(RT.CONTRACTOR) contractorId: string): Promise<Contractor> {
		return await getContractor(contractorId)
	}

	@CheckPolicy()
	async getContractorWithFields(@Resource(RT.CONTRACTOR) contractorId: string, fields: string[]): Promise<Contractor> {
		return await getContractorWithFields(contractorId, fields)
	}

	@CheckPolicy()
	async getContractorsForList(@Resource(RT.CLIENT) clientId: string, filters: ContractorFilterItem[]): Promise<Contractor[]> {
		return await getContractorsForList(clientId, filters)
	}

	@CheckPolicy()
	async getContractorSalesChannels(@Resource(RT.CLIENT) clientId: string): Promise<any[]> {
		return await getContractorSalesChannels(clientId)
	}

	@CheckPolicy()
	async getContractorServiceProvider(@Resource(RT.CLIENT) clientId: string, serviceProviderId: string): Promise<Contractor> {
		return await getContractorServiceProvider(clientId, serviceProviderId);
	}

	@CheckPolicy()
	async updateContractorPeakId(@Resource(RT.CLIENT) clientId: string, serviceProviderId: string, peakId: string): Promise<boolean> {
		return await updateContractorPeakId(clientId, serviceProviderId, peakId);
	}

	// TODO: do we need the client Id to perform some permission checks? Potentially the user Id as well?
	@CheckPolicy()
	async deleteContractTemplate(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTTEMPLATE) templateId: string): Promise<void> {
		return await deleteContractTemplate(templateId)
	}

	// TODO: do we need the client Id to perform some permission checks? Potentially the user Id as well?
	@CheckPolicy()
	async deleteContractSequence(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTSEQUENCE) sequenceId: string): Promise<void> {
		return await deleteContractSequence(sequenceId)
	}

	@CheckPolicy()
	async generateViewContractsToken(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTOR) email: string, isReadonly: boolean = false): Promise<GenerateViewContractsTokenResponse> {
		return await generateViewContractsToken(email, isReadonly)
	}

	@CheckPolicy()
	async getContractsAndContractorsByToken(@Resource(RT.CLIENT) clientId: string, token: string): Promise<GetContractsAndContractorsForEmailResponse> {
		return await getContractsAndContractorsByToken(token)
	}

	@CheckPolicy()
	async getContractsAndContractorsByEmail(@Resource(RT.CLIENT) clientId: string, email: string): Promise<GetContractsAndContractorsForEmailResponse> {
		return await getContractsAndContractorsForEmail(null, email)
	}

	@CheckPolicy()
	async getContractsAndContractorById(@Resource(RT.CONTRACTOR) contractorId: string): Promise<{
		contractors: Contractor[],
		contractsByContractor: Record<string, Contract[]>,
		clientNames: Record<string, string>
	}> {
		return await getContractsAndContractorsForEmail(contractorId, null);
	}

	@CheckPolicy()
	async bulkUpdateContractorsWithToken(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTOR) contractors: Contractor[], token: string): Promise<void> {
		return await bulkUpdateContractorsWithToken(contractors, token);
	}

	@CheckPolicy()
	async bulkUpdateContractors(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTOR) contractors: Contractor[], token: string): Promise<void> {
		return await bulkUpdateContractors(contractors)
	}

	@CheckPolicy()
	async sendReminder(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTOR) contractorIds: string[], ignoreRecentlySent: boolean): Promise<{sentCount: number, totalCount: number}> {
		return await sendReminder(clientId, contractorIds, ignoreRecentlySent)
	}

	@CheckPolicy()
	async sendKafkaMessage(@Resource(RT.CLIENT) clientId: string, topic: string, data: any): Promise<void> {
		return await sendKafkaMessage(topic, data)
	}

	@CheckPolicy()
	async subscribeToKafkaTopic(@Resource(RT.CLIENT) clientId: string, topic: string): Promise<void> {
		return await subscribeToKafkaTopic(topic)
	}

	@CheckPolicy()
	async getContractorsWithoutContract(@Resource(RT.CLIENT) clientId: string): Promise<Number> {
		return await getContractorsWithoutContract(clientId)
	}

	@CheckPolicy()
	async getContractTemplatesForClient(@Resource(RT.CLIENT) clientId: string): Promise<ContractTemplate[]> {
		return await getContractTemplatesForClient(clientId)
	}

	@CheckPolicy()
	async deleteCascadeContractors(@Resource(RT.CLIENT) clientId: string): Promise<void> {
		return await deleteCascadeContractors(clientId)
	}

	@CheckPolicy()
	async deleteCascadeMysSpContractors(@Resource(RT.CLIENT) clientId: string): Promise<void> {
		return await deleteCascadeContractors(clientId, true)
	}

	@CheckPolicy()
	async importMysSpsAsContractors(@Resource(RT.CLIENT) clientId: string): Promise<{
		successCount: number,
		failureCount: number,
		totalProcessed: number
	}> {
		return await importMysSpsAsContractors(clientId)
	}

	@CheckPolicy()
	async createContractorForMysSp(@Resource(RT.CLIENT) clientId: string, @Resource(RT.PROVIDER) provider: any): Promise<void> {
		return await createContractorForMysSp(provider, clientId)
	}

	@CheckPolicy()
	async bulkSetTerminationDateToContract(endDate: Date, shouldReceiveEmail: boolean, @Resource(RT.CONTRACT) contractIds: string[]): Promise<{ terminatedCount: number, totalCount: number }> {
		return await bulkSetTerminationDateToContract(endDate, shouldReceiveEmail, contractIds);
	}

	@CheckPolicy()
	async bulkSetNeedsContract(@Resource(RT.CONTRACTOR) contractorIds: string[]): Promise<{updatedCount: number, totalCount: number}> {
		return await bulkSetNeedsContract(contractorIds)
	}

	@CheckPolicy()
	async terminateContracts(): Promise<TerminateContractsResult> {
		return await terminateContracts()
	}

	@CheckPolicy()
	async contractTemplateHasContracts(@Resource(RT.CONTRACTTEMPLATE) templateId: string): Promise<boolean> {
		return await contractTemplateHasContracts(templateId)
	}
	
	@CheckPolicy()
	async getContractsThatNeedAttention(@Resource(RT.CONTRACTOR) contractorId: string): Promise<Contract[]> {
		return await getContractsThatNeedAttention(contractorId)
	}

	@CheckPolicy()
	async exportContractors(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTOR) contractorIds: string[]) {
		const buffer = await exportContractors(clientId, contractorIds)
		return buffer
	}

	async contractTemplateFactory(): Promise<ContractTemplate> {
		return await contractTemplateFactory()
	}

}

register(ContractExecutive)
